/*eslint-disable*/
import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardMedia from "@material-ui/core/CardMedia";

import HeaderLinks from "components/Header/HeaderLinks.js";
import StyledDataGrid from "components/StyledDataGrid/StyledDataGrid.js";
import VendorFacilityDialog from "components/VendorFacilityDialog/VendorFacilityDialog.js";
import homePageStyle from "assets/jss/material-kit-pro-react/views/homePageStyle.js";

import { history } from "../../helpers";
import { authService } from "../../services";

const useStyles = makeStyles(homePageStyle);

const spacer = {
  marginTop: "120px",
  marginBottom: "50px",
  color: "#000000",
  textAlign: "center"
}

const darkText = {
  color: "#000000"
}

const boldText = {
  fontWeight: "bold",
  fontSize: "1.1em"
}

export default function Home() {
  const [company, setCompany] = useState("");
  const [vendorRows, setVendorRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleCompanySelect = (e) => {
    setCompany(e);
    const user = authService.getUser();
  };

  const loadData = () => {
    const user = authService.getUser();
    let ses = authService.getSes();
    setCompany(ses.selected_company);

    const jwt = authService.getJWT();
    const headers = {
      "Authorization": `Bearer ${jwt}`,
    }
    fetch(`${process.env.REACT_APP_API_URI}/vendor/vendors?company_id=${ses.selected_company}`, { headers }).then(resp => {
      return resp.json();
    }).then(json => {
      setVendorRows(json);
    });
  };

  React.useEffect(() => {
    authService.accessCheck(loadData, []);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, [ company ]);

  const classes = useStyles();

  const columns = [
    {
      field: "company",
      headerName: "Company",
      flex: 1,
    },
    {
      field: "coverage",
      headerName: "States Serviced",
      flex: 1,
    },
    {
      field: "services",
      headerName: "Services Offered",
      flex: 1,
    },
    {
      field: "notes",
      headerName: "Notes",
      flex: 1,
    },
    {
      field: "add_facility",
      headerName: "Add Facility",
      flex: 0.75,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setSelectedRow(params.row);
              setDialogOpen(true);
            }}
          >
            Add Facility
          </Button>
        )
      }
    },
  ];

  return (
    <div>
      <Header
        brand="ParrTRAX"
        links={<HeaderLinks dropdownHoverColor="success" handleCompanySelect={handleCompanySelect} />}
        fixed
        color="primary"
        className={classes.title}
      />

      <div style={spacer} justify="center"></div>
      <div className={classes.container}>
        <Card>
          <CardBody>
            <CardMedia
              style={{
                height: 0,
                paddingTop: "56.25%",
                margin: "0 auto",
              }}
              image="https://www.parr2.com/p/img/parrtrax_home_map.png"
            />
          </CardBody>
        </Card>
        <VendorFacilityDialog row={selectedRow} open={dialogOpen} onClose={() => setDialogOpen(false)} onSubmit={loadData} />
      </div>

      <div className={classes.textCenter} style={darkText}>
        <h3>Need Help?</h3>
        Call Us <span style={boldText}>931-363-5379</span>
      </div>


      <Footer
        content={
          <div>
            <div className={classes.left}>
              &copy; {1900 + new Date().getYear()} , Parr Inc.
            </div>
            <div className={classes.right}>
              Version: {process.env.REACT_APP_VERSION}
            </div>
          </div>
        }
      />
    </div>
  );
}
