import React from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { InfoBox } from "react-google-maps/lib/components/addons/InfoBox";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { markerPopupRow, markerPopupCell } from "assets/jss/mapStyle.js";
import { mapMarkerIcon } from "../../helpers";

const StatusMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={4.3}
      defaultCenter={{ lat: 39.8282, lng: -98.5795 }}
      defaultOptions={{
        scrollwheel: true,
        zoomControl: true,
      }}
    >
      {props.markers.map((row) => (
        <Marker
          key={row.facility}
          position={{ lat: row.latitude, lng: row.longitude }}
          icon={mapMarkerIcon(row.map_color)}
          onClick={() =>
            props.clickHandler(props.company, parseInt(row.facility))
          }
        >
          {props.infoBoxOpen &&
            `${props.company}-${row.facility}` === props.facility && (
              <InfoBox
                options={{ closeBoxURL: ``, enableEventPropagation: true }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    opacity: 1,
                    borderRadius: "0px 10px 10px 10px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      position: "absolute",
                    }}
                  >
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => props.clickHandler(null, 0)}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  </div>
                  <div style={{ padding: "15px 10px 10px 10px" }}>
                    <table style={{ marginTop: "10px" }}>
                      <tbody>
                        <tr style={markerPopupRow}>
                          <td style={markerPopupCell}>Tank Name</td>
                          <td style={markerPopupCell}>{row.tank_name}</td>
                        </tr>
                        <tr style={markerPopupRow}>
                          <td style={markerPopupCell}>Facility</td>
                          <td
                            style={markerPopupCell}
                          >{`${props.company} ${row.facility}`}</td>
                        </tr>
                        <tr style={markerPopupRow}>
                          <td style={markerPopupCell}>Address</td>
                          <td style={markerPopupCell}>
                            {row.address}
                            <br></br>
                            {row.city}, {row.state} {row.zip}
                          </td>
                        </tr>
                        <tr style={markerPopupRow}>
                          <td style={markerPopupCell}>Current Level</td>
                          <td style={markerPopupCell}>{row.tank_reading} %</td>
                        </tr>
                        <tr style={markerPopupRow}>
                          <td style={markerPopupCell}>Current Volume</td>
                          <td style={markerPopupCell}>{row.oil_level} gal.</td>
                        </tr>
                        <tr style={markerPopupRow}>
                          <td style={markerPopupCell}>Last Measured</td>
                          <td style={markerPopupCell}>{row.date}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </InfoBox>
            )}
        </Marker>
      ))}
    </GoogleMap>
  ))
);

export default StatusMap;
