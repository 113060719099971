import React, { useState } from "react";
import { matchPath } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { primaryColor } from "assets/jss/material-kit-pro-react.js";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Close from "@material-ui/icons/Close";

import style from "assets/jss/material-kit-pro-react/modalStyle.js";

import { history } from "../../helpers";
import { authService } from "../../services";

const spacer = {
  marginTop: "20px",
  marginBottom: "20px",
  color: "#000000",
  textAlign: "center",
};

const boldText = {
  fontWeight: "bold",
  fontSize: "1.1em",
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));
const useModalStyles = makeStyles(style);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

let allPictures = {};

export default function Gallery() {
  const classes = useStyles();
  const modalClasses = useModalStyles();

  const [company, setCompany] = useState("");
  const [cat4, setCat4] = useState("");
  const [loadedPictures, setLoadedPictures] = useState([]);
  const [docTypes, setDocTypes] = useState([]);
  const [expanded, setExpanded] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPicture, setCurrentPicture] = useState("");
  const [backdropOpen, setBackdropOpen] = useState(false);

  const getParams = () => {
    let path = "?cat_4=:cat4";
    if (history.location.search.includes("maint_id")) {
      path = "?cat_4=:cat4&maint_id=:maintId";
    }
    return matchPath(history.location.search, { path }).params;
  };

  const handleModalClose = () => {
    setCurrentPicture("");
    setModalOpen(false);
  };

  const loadPictures = (type, expanded) => {
    const params = getParams();
    setExpanded(type);
    setLoadedPictures([]);
    if (expanded) {
      setBackdropOpen(true);
      const alreadyLoaded = Object.prototype.hasOwnProperty.call(
        allPictures,
        type
      );
      if (alreadyLoaded) {
        setLoadedPictures(allPictures[type]);
        setBackdropOpen(false);
      } else {
        const ses = authService.getSes();

        let queryString = `company=${ses.selected_company}&type=${type}`;
        if (
          Object.prototype.hasOwnProperty.call(params, "cat4") &&
          Object.prototype.hasOwnProperty.call(params, "maintId")
        ) {
          queryString = `${queryString}&cat_4=${params.cat4}&maint_id=${params.maintId}`;
        } else if (Object.prototype.hasOwnProperty.call(params, "cat4")) {
          queryString = `${queryString}&cat_4=${params.cat4}`;
        } else if (Object.prototype.hasOwnProperty.call(params, "maintId")) {
          queryString = `${queryString}&maint_id=${params.maintId}`;
        }
        const jwt = authService.getJWT();
        const headers = {
          Authorization: `Bearer ${jwt}`,
        };
        fetch(`${process.env.REACT_APP_API_URI}/parrtrax/docs?${queryString}`, {
          headers,
        })
          .then((resp) => {
            return resp.json();
          })
          .then((json) => {
            allPictures[type] = json;
            setLoadedPictures(json);
            setBackdropOpen(false);
          });
      }
    } else {
      setExpanded("");
    }
  };

  const showModal = (embedLink) => {
    setCurrentPicture(embedLink);
    setModalOpen(true);
  };

  React.useEffect(() => {
    const loadData = () => {
      const ses = authService.getSes();
      setCompany(ses.selected_company);
      const params = getParams();

      let queryString = `company=${ses.selected_company}`;
      if (
        Object.prototype.hasOwnProperty.call(params, "cat4") &&
        Object.prototype.hasOwnProperty.call(params, "maintId")
      ) {
        queryString = `${queryString}&cat_4=${params.cat4}&maint_id=${params.maintId}`;
        setCat4(params.cat4);
      } else if (Object.prototype.hasOwnProperty.call(params, "cat4")) {
        queryString = `${queryString}&cat_4=${params.cat4}`;
        setCat4(params.cat4);
      } else if (Object.prototype.hasOwnProperty.call(params, "maintId")) {
        queryString = `${queryString}&maint_id=${params.maintId}`;
      }

      const jwt = authService.getJWT();
      const headers = {
        Authorization: `Bearer ${jwt}`,
      };
      fetch(
        `${process.env.REACT_APP_API_URI}/parrtrax/doc_types?${queryString}`,
        { headers }
      )
        .then((resp) => {
          return resp.json();
        })
        .then((json) => {
          setDocTypes(json);
        });
    };

    authService.accessCheck(loadData, []);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ backgroundColor: primaryColor[0] }}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => {
              allPictures = {};
              const state = history.location.state;
              if (
                state &&
                Object.prototype.hasOwnProperty.call(state, "returnTo")
              ) {
                history.push({ pathname: state.returnTo, state });
              } else {
                history.goBack();
              }
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Gallery
          </Typography>
        </Toolbar>
      </AppBar>

      <div style={spacer} justify="center">
        <h2>
          <span style={boldText}>{company}</span> {cat4}
        </h2>
      </div>

      <div
        className={classes.container}
        style={{ width: "95%", margin: "0 auto", marginTop: "20px" }}
      >
        {docTypes.map((docType) => (
          <ExpansionPanel
            key={docType.doc_type}
            onChange={(event, expanded) => {
              authService.accessCheck(loadPictures, [
                docType.doc_type,
                expanded,
              ]);
            }}
            expanded={expanded === docType.doc_type}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>
                {docType.label}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container spacing={3}>
                {loadedPictures.map((picture) => {
                  return (
                    <Grid item xs={12} md={3} lg={2} key={picture.id}>
                      <Card className={classes.root}>
                        <CardActionArea
                          onClick={() => {
                            showModal(picture.embed);
                          }}
                        >
                          <CardMedia
                            style={{ height: "220px", width: "220px" }}
                            image={picture.thumbnail}
                          />
                          <CardContent>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                            >
                              {picture.date}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      </div>
      <Backdrop open={backdropOpen} style={{ zIndex: "999" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        classes={{
          root: modalClasses.modalRoot,
          paper: modalClasses.modal + " " + modalClasses.modalLarge,
        }}
        open={modalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleModalClose}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="large-modal-slide-title"
          disableTypography
          className={modalClasses.modalHeader}
        >
          <Button
            simple="true"
            className={modalClasses.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={handleModalClose}
          >
            {" "}
            <Close className={modalClasses.modalClose} />
          </Button>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={modalClasses.modalBody}
        >
          <iframe
            title={currentPicture}
            src={currentPicture}
            width={"100%"}
            height={"600px"}
            key={currentPicture}
          ></iframe>
        </DialogContent>
      </Dialog>
    </div>
  );
}
