import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import StyledDataGrid from "components/StyledDataGrid/StyledDataGrid.js";
import homePageStyle from "assets/jss/material-kit-pro-react/views/homePageStyle.js";
import { history } from "../../helpers";
import { authService } from "../../services";

function TankLateReport() {
  const useStyles = makeStyles(homePageStyle);
  const classes = useStyles();

  const [expired, setExpired] = useState([]);

  const columns = [
    {
      field: "facility",
      headerName: "Facility",
      flex: 75,
      minWidth: 110,
    },
    {
      field: "city",
      headerName: "City",
      flex: 100,
      minWidth: 150,
    },
    {
      field: "state",
      headerName: "State",
      flex: 75,
      minWidth: 100,
    },
    {
      field: "tank_name",
      headerName: "Tank Name",
      flex: 125,
      minWidth: 200,
    },
    {
      field: "type",
      headerName: "Event",
      flex: 100,
      minWidth: 100,
    },
    {
      field: "tank_reading",
      headerName: "Percent Full",
      flex: 100,
      align: "center",
      valueFormatter: (params) => `${params.value} %`,
      minWidth: 140,
    },
    {
      field: "expiration",
      headerName: "Expiration",
      flex: 125,
      minWidth: 120,
    },
    {
      field: "remaining_days",
      headerName: "Days Remaining",
      flex: 100,
      minWidth: 100,
      valueFormatter: (params) => {
        if (params.value < 0) {
          return `${Math.abs(params.value)} days overdue`;
        } else {
          return `${params.value} days remaining`;
        }
      },
    },
    {
      field: "freq_days",
      headerName: "Frequency/days",
      flex: 125,
      minWidth: 100,
    },
    {
      field: "freq_alert_days",
      headerName: "Alert/days",
      flex: 125,
      minWidth: 100,
    },
  ];

  React.useEffect(() => {
    const user = authService.getUser();
    const ses = authService.getSes();
    const jwt = authService.getJWT();
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    fetch(
      `${process.env.REACT_APP_API_URI}/tank/facility_status?company=${ses.selected_company}`,
      { headers }
    )
      .then((resp) => {
        if (resp.status === 403) {
          alert("Access Denied");
          history.push(user.homepage);
        }
        return resp.json();
      })
      .then((json) => {
        setExpired(json.filter((row) => row.expired));
      });
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ height: 600, width: "100%" }}>
        <StyledDataGrid
          rows={expired}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
          getCellClassName={(params) => {
            if (params.field === "remaining_days") {
              return params.row.remaining_days < 0 ? classes.alertRed : "";
            }
            return "";
          }}
        />
      </div>
    </div>
  );
}

TankLateReport.displayName = "TankLateReport";
export default TankLateReport;
