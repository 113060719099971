export const mapMarkerIcon = (color) => {
  return `https://chart.apis.google.com/chart?chst=d_map_xpin_letter&chld=pin||${color}|0000FF`;
};

export const mapMarkerIconWithStar = (color) => {
  return `https://chart.apis.google.com/chart?chst=d_map_xpin_letter&chld=pin_star||${color}|0000FF`;
};

export const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};
