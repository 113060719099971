import React, { useState } from "react";
import PropTypes from "prop-types";

import { useConfirmDialog } from "react-mui-confirm";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

import StyledDataGrid from "components/StyledDataGrid/StyledDataGrid.js";

import { history } from "../../helpers";
import { authService } from "../../services";

import LoadingBackdrop from "components/CustomLoadingBackdrop/LoadingBackdrop.js";

function AssignedVendorsDialog(props) {
  const confirm = useConfirmDialog();

  const user = authService.getUser();
  const ses = authService.getSes();
  const jwt = authService.getJWT();

  const columns = [
    { field: "company", headerName: "Vendor", flex: 100 },
    { field: "alt_cat_4", headerName: "Facility", flex: 100 },
    { field: "city", headerName: "City", flex: 100 },
    { field: "state", headerName: "State", flex: 100 },
    { field: "services", headerName: "Services", flex: 100 },
    {
      field: "remove",
      headerName: "Unassign Vendor",
      flex: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const unassignButton = (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => unassignVendor(params.row)}
          >
            Unassign Vendor
          </Button>
        );
        return unassignButton;
      },
    },
  ];

  const [assignedVendors, setAssignedVendors] = useState([]);
  const [backdropOpen, setBackdropOpen] = useState(false);

  const unassignVendor = (row) => {
    confirm({
      title: "Are you sure you wish to unassign this vendor?",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      onConfirm: () => {
        setBackdropOpen(true);
        const data = {
          vendor: row.id,
          facility: {
            company_id: ses.selected_company,
            cat_4: row.cat_4,
          },
        };
        const headers = {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        };
        fetch(`${process.env.REACT_APP_API_URI}/vendor/assign_vendor`, {
          headers,
          method: "DELETE",
          body: JSON.stringify(data),
        })
          .then((resp) => {
            if (resp.status === 403) {
              setBackdropOpen(false);
              history.push(user.homepage);
            } else if (resp.status !== 200) {
              setBackdropOpen(false);
              alert("Failed to unassign vendor");
              return {};
            }
            return resp.json();
          })
          .then(() => {
            setBackdropOpen(false);
            getVendors();
          });
      },
    });
  };

  const getVendors = () => {
    if (Object.keys(props.row).length === 0) return;

    setBackdropOpen(true);
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    fetch(
      `${process.env.REACT_APP_API_URI}/vendor/assigned_vendors?cat_4=${props.row.facility}&company=${ses.selected_company}`,
      { headers }
    )
      .then((resp) => {
        if (resp.status === 403) {
          setBackdropOpen(false);
          history.push(user.homepage);
        }
        return resp.json();
      })
      .then((json) => {
        setBackdropOpen(false);
        setAssignedVendors(json);
      });
  };

  React.useEffect(() => {
    getVendors();
  }, [props.row]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
        Assigned Vendors
      </DialogTitle>
      <div style={{ height: 600, width: "100%" }}>
        <StyledDataGrid
          rows={assignedVendors}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
        />
      </div>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Close
        </Button>
      </DialogActions>
      <LoadingBackdrop open={backdropOpen} />
    </Dialog>
  );
}

AssignedVendorsDialog.displayName = "AssignedVendorsDialog";
AssignedVendorsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
};

export default AssignedVendorsDialog;
