import React, { useState } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";

import LoadingBackdrop from "components/CustomLoadingBackdrop/LoadingBackdrop.js";

import homePageStyle from "assets/jss/material-kit-pro-react/views/homePageStyle.js";
const useStyles = makeStyles(homePageStyle);

function AddContactDialog(props) {
  const classes = useStyles();
  const [submits, setSubmits] = useState(0);

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameErrorText, setFirstNameErrorText] = useState("");

  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState(false);
  const [lastNameErrorText, setLastNameErrorText] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [phoneErrorText, setPhoneErrorText] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");

  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [titleErrorText, setTitleErrorText] = useState("");

  const validate = () => {
    setSubmits(submits + 1);

    if (!firstName) {
      setFirstNameError(true);
      setFirstNameErrorText("Required.");
      return;
    } else {
      setFirstNameError(false);
      setFirstNameErrorText("");
    }

    if (!lastName) {
      setLastNameError(true);
      setLastNameErrorText("Required.");
      return;
    } else {
      setLastNameError(false);
      setLastNameErrorText("");
    }

    if (!phone) {
      setPhoneError(true);
      setPhoneErrorText("Required.");
      return;
    } else if (
      !phone.match(
        /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
      )
    ) {
      setPhoneError(true);
      setPhoneErrorText("Invalid phone number");
      return;
    } else {
      setPhoneError(false);
      setPhoneErrorText("");
    }

    if (!email) {
      setEmailError(true);
      setEmailErrorText("Required.");
      return;
    } else if (email.split("@").length !== 2) {
      // lazy
      setEmailError(true);
      setEmailErrorText("Invalid email.");
      return;
    } else {
      setEmailError(false);
      setEmailErrorText("");
    }

    if (!title) {
      setTitleError(true);
      setTitleErrorText("Required.");
      return;
    } else {
      setTitleError(false);
      setTitleErrorText("");
    }

    props.onSubmit(props.row, firstName, lastName, phone, email, title);

    setFirstName("");
    setLastName("");
    setPhone("");
    setEmail("");
    setTitle("");
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Add Contact - Facility {props.row.facility}
      </DialogTitle>
      <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            required
            margin="dense"
            id="first-name"
            name="first-name"
            label="First Name"
            type="text"
            error={firstNameError}
            helperText={firstNameErrorText}
            fullWidth
            value={firstName || ""}
            onChange={(value) => setFirstName(value.target.value)}
          />
          <TextField
            required
            margin="dense"
            id="last-name"
            name="last-name"
            label="Last Name"
            type="text"
            error={lastNameError}
            helperText={lastNameErrorText}
            fullWidth
            value={lastName || ""}
            onChange={(value) => setLastName(value.target.value)}
          />
          <TextField
            required
            margin="dense"
            id="phone"
            name="phone"
            label="Phone"
            type="tel"
            error={phoneError}
            helperText={phoneErrorText}
            fullWidth
            value={phone || ""}
            onChange={(value) => setPhone(value.target.value)}
          />
          <TextField
            required
            margin="dense"
            id="email"
            name="email"
            label="Email"
            type="email"
            error={emailError}
            helperText={emailErrorText}
            fullWidth
            value={email || ""}
            onChange={(value) => setEmail(value.target.value)}
          />
          <TextField
            required
            margin="dense"
            id="title"
            name="title"
            label="Title"
            type="text"
            error={titleError}
            helperText={titleErrorText}
            fullWidth
            value={title || ""}
            onChange={(value) => setTitle(value.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            style={{ width: "100%", marginTop: "10px" }}
            onClick={validate}
          >
            Add Contact
          </Button>
        </form>
      </div>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Close
        </Button>
      </DialogActions>
      <LoadingBackdrop open={props.backdropOpen} />
    </Dialog>
  );
}

AddContactDialog.displayName = "AddContactDialog";
AddContactDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  backdropOpen: PropTypes.bool.isRequired,
};

export default AddContactDialog;
