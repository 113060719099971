import React, { useState } from "react";
import PropTypes from "prop-types";

import { format, sub } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import StyledDataGrid from "components/StyledDataGrid/StyledDataGrid.js";
import homePageStyle from "assets/jss/material-kit-pro-react/views/homePageStyle.js";
import { history, openInNewTab } from "../../helpers";
import { authService } from "../../services";

function PermitsHistoryReport(props) {
  const useStyles = makeStyles(homePageStyle);
  const classes = useStyles();

  const [facility, setFacility] = useState(0);
  const [startDate, setStartDate] = useState(sub(new Date(), { days: 365 }));
  const [endDate, setEndDate] = useState(new Date());
  const [historyRows, setHistoryRows] = useState([]);
  const [subPermitRows, setSubPermitRows] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  React.useEffect(() => {
    if (facility && startDate && endDate) {
      let startStr, endStr;
      try {
        startStr = format(new Date(startDate), "yyyy-MM-dd");
      } catch {}

      try {
        endStr = format(new Date(endDate), "yyyy-MM-dd");
      } catch {}

      const user = authService.getUser();
      const ses = authService.getSes();
      const jwt = authService.getJWT();
      const headers = {
        Authorization: `Bearer ${jwt}`,
      };
      fetch(
        `${process.env.REACT_APP_API_URI}/permit/history?cat_4=${facility}&start=${startStr}&end=${endStr}&company=${ses.selected_company}`,
        { headers }
      )
        .then((resp) => {
          if (resp.status === 403) {
            alert("Access Denied");
            history.push(user.homepage);
          }
          return resp.json();
        })
        .then((json) => {
          setHistoryRows(json);
        });
    }
  }, [facility, startDate, endDate]);

  const subPermitColumns = [
    {
      field: "measure_date",
      headerName: "Date",
      flex: 75,
      minWidth: 150,
    },
    {
      field: "para_long_name",
      headerName: "Parameter",
      flex: 100,
      minWidth: 100,
    },
    {
      field: "measure_value",
      headerName: "Value",
      flex: 75,
      minWidth: 100,
    },
    {
      field: "low_limit",
      headerName: "Low Limit",
      flex: 75,
      minWidth: 100,
    },
    {
      field: "high_limit",
      headerName: "High Limit",
      flex: 75,
      minWidth: 100,
    },
  ];

  const columns = [
    {
      field: "measure_date",
      headerName: "Date",
      flex: 75,
      minWidth: 150,
    },
    {
      field: "cat_4",
      headerName: "Facility",
      flex: 75,
      minWidth: 100,
    },
    {
      field: "para_long_name",
      headerName: "Parameter",
      flex: 100,
      minWidth: 100,
    },
    {
      field: "measure_value",
      headerName: "Value",
      flex: 75,
      minWidth: 100,
    },
    {
      field: "low_limit",
      headerName: "Low Limit",
      flex: 75,
      minWidth: 100,
    },
    {
      field: "high_limit",
      headerName: "High Limit",
      flex: 75,
      minWidth: 100,
    },
    {
      field: "permit_link",
      headerName: "Docs",
      flex: 75,
      minWidth: 75,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (params.row.permit_link !== null) {
          return (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => openInNewTab(params.row.permit_link)}
            >
              Docs
            </Button>
          );
        } else {
          return "";
        }
      },
    },
    {
      field: "sub_parameters",
      headerName: "Info",
      flex: 75,
      minWidth: 75,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (params.row.sub_permits.length > 0) {
          return (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                setSubPermitRows(params.row.sub_permits);
                setDialogOpen(true);
              }}
            >
              Info
            </Button>
          );
        } else {
          return "";
        }
      },
    },
  ];

  return (
    <div style={{ textAlign: "center" }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="space-around">
          <FormControl
            className={classes.formControl}
            style={{ minWidth: 120, margin: 10 }}
          >
            <InputLabel id="facility-label">Facility</InputLabel>
            <Select
              labelId="facility-label"
              id="facility-select"
              onChange={(event) => setFacility(event.target.value)}
              value={facility}
            >
              {props.facilities.map((facility) => (
                <MenuItem key={facility.facility} value={facility.facility}>
                  {facility.facility}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="yyyy-MM-dd"
            margin="normal"
            id="date-picker-inline"
            label="Start"
            maxDate={endDate}
            value={startDate}
            onChange={(date) => setStartDate(date)}
            autoOk={true}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="yyyy-MM-dd"
            margin="normal"
            id="date-picker-inline"
            label="End"
            minDate={startDate}
            value={endDate}
            onChange={(date) => setEndDate(date)}
            autoOk={true}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </Grid>
      </MuiPickersUtilsProvider>
      <div style={{ height: 600, width: "100%" }}>
        <StyledDataGrid
          rows={historyRows}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
          getCellClassName={(params) => {
            if (params.field === "measure_value") {
              return params.row.passed
                ? classes.permitsPass
                : classes.permitsFail;
            }
            return "";
          }}
        />
      </div>
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title"></DialogTitle>
        <DialogContent>
          <div style={{ height: 600, width: "100%" }}>
            <StyledDataGrid
              rows={subPermitRows}
              columns={subPermitColumns}
              pageSize={50}
              rowsPerPageOptions={[50]}
              getCellClassName={(params) => {
                if (params.field === "measure_value") {
                  return params.row.passed
                    ? classes.permitsPass
                    : classes.permitsFail;
                }
                return "";
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogOpen(false);
            }}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

PermitsHistoryReport.displayName = "PermitsHistoryReport";
PermitsHistoryReport.propTypes = {
  facilities: PropTypes.array.isRequired,
};

export default PermitsHistoryReport;
