/*eslint-disable*/
import { format, sub } from "date-fns";
import React, { useState } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import { InfoBox } from "react-google-maps/lib/components/addons/InfoBox";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import { DropzoneArea } from "material-ui-dropzone";
// @material-ui/core components
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// @material-ui/icons
import Create from "@material-ui/icons/Create";
import Schedule from "@material-ui/icons/Schedule";
import NewReleases from "@material-ui/icons/NewReleases";
import Error from "@material-ui/icons/Error";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
// @material-ui/lab components
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAlert from '@material-ui/lab/Alert';

import CustomTabs from "components/CustomTabs/CustomTabs.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

// tables
import HistoryTable from "components/HistoryTable/HistoryTable.js";
import HistoryDialogTable from "components/HistoryDialogTable/HistoryDialogTable.js";
import PermitsHistoryReport from "components/PermitsHistoryReport/PermitsHistoryReport.js";
import PermitsLateReport from "components/PermitsLateReport/PermitsLateReport.js";
import PermitsStatusReport from "components/PermitsStatusReport/PermitsStatusReport.js";
import AddContactDialog from "components/AddContactDialog/AddContactDialog.js";
import AddPermitsEventDialog from "components/AddPermitsEventDialog/AddPermitsEventDialog.js";

import homePageStyle from "assets/jss/material-kit-pro-react/views/homePageStyle.js";

import { history } from "../../helpers";
import { authService } from "../../services";

const useStyles = makeStyles(homePageStyle);

const spacer = {
  marginTop: "120px",
  marginBottom: "50px",
  color: "#000000",
  textAlign: "center"
};

const darkText = {
  color: "#000000"
};

const boldText = {
  fontWeight: "bold",
  fontSize: "1.1em"
};

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export default function Permits() {
  const [company, setCompany] = useState("");
  const [statusRows, setStatusRows] = useState([]);

  const [addEventDialogOpen, setAddEventDialogOpen] = useState(false);
  const [addEventRow, setAddEventRow] = useState({});
  const [addEventBackdropOpen, setAddEventBackdropOpen] = useState(false);

  const [addContactDialogOpen, setAddContactDialogOpen] = useState(false);
  const [addContactRow, setAddContactRow] = useState({});
  const [addContactBackdropOpen, setAddContactBackdropOpen] = useState(false);

  // Add permits form
  const [facilities, setFacilities] = useState([]);
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [submits, setSubmits] = useState(0);
  const [backdropOpen, setBackdropOpen] = useState(false);

  const [selectedFacility, setSelectedFacility] = useState(0);
  const [dateActual, setDateActual] = useState(new Date());
  const [permitExpiration, setPermitExpiration] = useState(new Date());
  const [comments, setComments] = useState("");
  const [completionComments, setCompletionComments] = useState("");
  const [files, setFiles] = useState([]);

  // Add readings form
  const [parameters, setParameters] = useState([]);
  const [subParameters, setSubParameters] = useState([]);
  const [addReadingsFacilities, setAddReadingsFacilities] = useState([])

  const [parameterInputs, setParameterInputs] = useState({});
  const [addReadingsFiles, setAddReadingsFiles] = useState([]);
  const [addReadingsDateActual, setAddReadingsDateActual] = useState(new Date());
  const [addReadingsTime, setAddReadingsTime] = useState(new Date());

  const [expired, setExpired] = useState([]);

  const defaultFacilityProps = {
    options: facilities,
    getOptionLabel: (option) => option == 0 ? "" : option.label,
  };

  const defaultAddReadingsFacilityProps = {
    options: addReadingsFacilities,
    getOptionLabel: (option) => option == 0 ? "" : option.label,
  };

  const defaultParameterProps = {
    options: parameters,
    getOptionLabel: (option) => option == 0 ? "" : option.label,
  };

  const handleAddContactSubmit = (info, first, last, phone, email, title) => {
    const data = {
      company_id: company,
      cat_4: info.facility,
      first_name: first,
      last_name: last,
      phone,
      email,
      title,
      module: 2,
    };

    setAddContactBackdropOpen(true);

    const user = authService.getUser();
    const jwt = authService.getJWT();
    const headers = {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    };
    fetch(`${process.env.REACT_APP_API_URI}/parrtrax/contacts`, {
      headers,
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((resp) => {
        if (resp.status === 403) {
          history.push(user.homepage);
        } else if (resp.status !== 200) {
          alert("Failed to add contact");
          setAddContactBackdropOpen(false);
        }
        return resp.json();
      })
      .then((json) => {
        setAddContactBackdropOpen(false);
        if (!json.error) {
          alert("Successfully added contact.");
        } else {
          alert(`Failed to add contact - ${json.error}`);
        }
      });
  };

  const handleCompanySelect = (e) => {
    setCompany(e);
    const user = authService.getUser();
    const perms = user.company_permissions[e].filter((item) => {
      return item.route === "/permits";
    });
    if(perms.length === 0) {
      history.push(user.homepage);
    }
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessOpen(false);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };

  const handleParameterChange = (event) => {
    setSubParameters([])
    setParameterInputs({});
    const content = event.target.textContent;
    let parameterId = null;
    let subParameters = [];
    let uom = null;
    parameters.forEach(parameter => {
      if(parameter.label == content) {
        parameterId = parameter.parameter;
        subParameters = parameter.sub_parameters;
        uom = parameter.uom;
      }
    });

    if(uom.toLowerCase() !== "pass/fail") {
      subParameters = [{"label": content, uom, "parameter": parameterId}].concat(subParameters);
    }

    subParameters.forEach(p => {
      parameterInputs[`input-${p.parameter}`] = "";
    });

    setSubParameters(subParameters);
  };

  const handleFacilityChange = (event) => {
    const content = event.target.textContent;
    const fac = parseInt(content.split(":")[0]);
    setSelectedFacility(fac);

    const newErrors = errors;
    if(content) {
      newErrors["facility"] = false;

      const ses = authService.getSes();
      const jwt = authService.getJWT();
      const headers = {
        "Authorization": `Bearer ${jwt}`,
      }
      fetch(`${process.env.REACT_APP_API_URI}/permit/parameters?company=${ses.selected_company}&cat_4=${fac}`, { headers }).then(resp => {
        return resp.json();
      }).then(json => {
        setParameters(json);
      });
    } else {
      newErrors["facility"] = true;
    }
    setErrors(newErrors);
  };

  const handleAddReadingsTimeChange = (date) => {
    const newErrors = errors;
    if(String(date) == "Invalid Date" || String(date) === "null") {
      newErrors["addReadingsTime"] = true;
    } else {
      newErrors["addReadingsTime"] = false;
    }
    setAddReadingsTime(date);
    setErrors(newErrors);
  };

  const handleAddReadingsDateActualChange = (date) => {
    const newErrors = errors;
    if(String(date) == "Invalid Date" || String(date) === "null") {
      newErrors["addReadingsDateActual"] = true;
    } else {
      newErrors["addReadingsDateActual"] = false;
    }
    setAddReadingsDateActual(date);
    setErrors(newErrors);
  };

  const handleDateActualChange = (date) => {
    const newErrors = errors;
    if(String(date) == "Invalid Date" || String(date) === "null") {
      newErrors["dateActual"] = true;
    } else {
      newErrors["dateActual"] = false;
    }
    setDateActual(date);
    setErrors(newErrors);
  };

  const handlePermitExpirationChange = (date) => {
    const newErrors = errors;
    if(String(date) == "Invalid Date" || String(date) === "null") {
      newErrors["permitExpiration"] = true;
    } else {
      newErrors["permitExpiration"] = false;
    }
    setPermitExpiration(date);
    setErrors(newErrors);
  };

  const handleParameterInputChange = (event) => {
    const id = event.target.id;
    const value = event.target.value;
    parameterInputs[id.split("-")[1]] = value;
    errors[id] = value === "";
  };

  const handleCommentsChange = (event) => {
    const value = event.target.value;
    setComments(value);
    errors["comments"] = value === "";
  };

  const handleCompletionCommentsChange = (event) => {
    const value = event.target.value;
    setCompletionComments(value);
    errors["completionComments"] = value === "";
  };

  const handleAddReadingsSubmit = () => {
    checkAccess();

    setBackdropOpen(true);

    const formData = new FormData();
    formData.append("company", company);
    formData.append("cat_4", selectedFacility);
    formData.append("date_actual", format(addReadingsDateActual, "yyyy-MM-dd"));
    formData.append("time", format(addReadingsTime, "HH:mm"));
    let i = 0;
    addReadingsFiles.forEach(file => {
      formData.append(`myFile${i}`, file);
      i++;
    });

    for(const id in parameterInputs) {
      formData.append(`parameter-${id}`, parameterInputs[id]);
    }

    const jwt = authService.getJWT();
    const headers = {
      "Authorization": `Bearer ${jwt}`,
    }
    fetch(`${process.env.REACT_APP_API_URI}/permit/parameter_readings`, { headers, method: "POST", body: formData, }).then(resp => {
      setBackdropOpen(false);
      if(resp.status == 200) {
        setSuccessOpen(true);
        clearReadingsForm();
      } else {
        setErrorOpen(true);
      }
    });
  };

  const handleAddPermitSubmit = () => {
    checkAccess();
    let errorFound = false;
    const newErrors = defaultErrors;
    if(selectedFacility === 0) {
      newErrors["facility"] = true;
      errorFound = true;
    }

    if(dateActual === "Invalid Date" || dateActual == "null") {
      newErrors["dateActual"] = true;
      errorFound = true;
    }

    if(permitExpiration === "Invalid Date" || permitExpiration == "null") {
      newErrors["permitExpiration"] = true;
      errorFound = true;
    }

    if(comments === "") {
      newErrors["comments"] = true;
      errorFound = true;
    }

    if(completionComments === "") {
      newErrors["completionComments"] = true;
      errorFound = true;
    }

    if(errorFound) {
      setErrors(newErrors);
      return;
    }

    setBackdropOpen(true);

    const formData = new FormData();
    formData.append("company", company);
    formData.append("facility", selectedFacility);
    formData.append("cat_4", selectedFacility);
    formData.append("date_actual", format(dateActual, "yyyy-MM-dd"));
    formData.append("permit_expiration", format(permitExpiration, "yyyy-MM-dd"));
    formData.append("comments", comments);
    formData.append("comments_complete", completionComments);
    let i = 0;
    files.forEach(file => {
      formData.append(`myFile${i}`, file);
      i++;
    });

    const jwt = authService.getJWT();
    const headers = {
      "Authorization": `Bearer ${jwt}`,
    }
    fetch(`${process.env.REACT_APP_API_URI}/permit/permit`, { headers, method: "POST", body: formData, }).then(resp => {
      setBackdropOpen(false);
      if(resp.status == 200) {
        setSuccessOpen(true);
        clearForm();
      } else {
        setErrorOpen(true);
      }
    });
  };

  const clearForm = () => {
    setSubmits(submits + 1);
    setDateActual(new Date());
    setComments("");
    setCompletionComments("");
  };

  const clearReadingsForm = () => {
    setSubmits(submits + 1);
    setAddReadingsDateActual(new Date());
    setAddReadingsTime(new Date());
    setParameterInputs({})
    setSubParameters([])
  };

  const defaultErrors = {
    facility: false,
    dateActual: false,
    comments: false,
    completionComments: false,
    permitExpiration: false,
  };
  const [errors, setErrors] = useState(defaultErrors);

  const loadData = () => {
    const user = authService.getUser();
    const ses = authService.getSes();
    setCompany(ses.selected_company);

    const jwt = authService.getJWT();
    const headers = {
      "Authorization": `Bearer ${jwt}`,
    }
    fetch(`${process.env.REACT_APP_API_URI}/parrtrax/facilities?company=${ses.selected_company}`, { headers }).then(resp => {
      return resp.json();
    }).then(json => {
      setFacilities(json);
    });

    fetch(`${process.env.REACT_APP_API_URI}/permit/facilities?company=${ses.selected_company}`, { headers }).then(resp => {
      return resp.json();
    }).then(json => {
      setAddReadingsFacilities(json);
    });

    fetch(`${process.env.REACT_APP_API_URI}/permit/parameters?company=${ses.selected_company}`, { headers }).then(resp => {
      return resp.json();
    }).then(json => {
      setExpired(json);
    });

    refreshStatus();
  };

  const refreshStatus = () => {
    let ses = authService.getSes();
    const user = authService.getUser();
    const jwt = authService.getJWT();
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    fetch(
      `${process.env.REACT_APP_API_URI}/permit/facility_status?company=${ses.selected_company}`,
      { headers }
    )
      .then((resp) => {
        if (resp.status === 403) {
          history.push(user.homepage);
        }
        return resp.json();
      })
      .then((json) => {
        setStatusRows(json);
      });
  };

  const checkAccess = () => {
    authService.isTokenValid().then((json) => {
      if ("msg" in json) {
        authService.logout();
        window.location.reload(true);
      }
    });
  };

  React.useEffect(() => {
    authService.accessCheck(loadData, []);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, [
    selectedFacility,
    company,
  ]);

  const classes = useStyles();

  return (
    <div>
      <Header
        brand="ParrTRAX"
        links={<HeaderLinks dropdownHoverColor="success" handleCompanySelect={handleCompanySelect} />}
        fixed
        color="primary"
        className={classes.title}
      />

      <div style={spacer} justify="center"></div>
      <div className={classes.container}>
        <CustomTabs
          headerColor="primary"
          tabs={[
            {
              tabName: "Status",
              tabIcon: NewReleases,
              tabContent: (
                <div className={classes.textCenter}>
                  <h3 style={{ fontWeight: "bold" }}>Permit Status</h3>
                    <PermitsStatusReport
                      rows={statusRows}
                      refresh={refreshStatus}
                      handleAddContactDialogOpen={(row) => {
                        setAddContactRow(row);
                        setAddContactDialogOpen(true);
                      }}
                      handleEventDialogOpen={(row) => {
                        setAddEventRow(row);
                        setAddEventDialogOpen(true);
                      }}
                    />
                </div>
              ),
            },
            {
              tabName: "Add Permit",
              tabIcon: Create,
              tabContent: (
                <div className={classes.textCenter}>
                  <div>
                    <h3 style={{ fontWeight: "bold" }}>
                      Add Permit
                    </h3>
                    <form className={classes.root} noValidate autoComplete="off">
                      <Grid container spacing={3} direction="column" >
                        <Grid container item spacing={0} justify="center">
                          <Grid item lg={6} md={6} s={12} xs={12}>
                            <FormControl style={{ width: "100%" }}>
                              <Autocomplete
                                {...defaultFacilityProps}
                                id="facility"
                                onChange={handleFacilityChange}
                                key={submits}
                                renderInput={(params) => <TextField {...params} label="Facility" margin="normal" helperText="Type Facility ID, City, or State - Required" error={errors["facility"]}/>}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid container item spacing={0} justify="center">
                          <Grid item lg={6} md={6} s={12} xs={12}>
                            <FormControl style={{ width: "100%" }} error={errors["dateActual"]}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  helperText="Required"
                                  disableToolbar
                                  variant="inline"
                                  format="yyyy-MM-dd"
                                  margin="normal"
                                  id="date-picker-inline"
                                  label="Date Completed"
                                  autoOk={true}
                                  value={dateActual}
                                  onChange={handleDateActualChange}
                                  maxDate={new Date()}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid container item spacing={0} justify="center">
                          <Grid item lg={6} md={6} s={12} xs={12}>
                            <FormControl style={{ width: "100%" }} error={errors["permitExpiration"]}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  helperText="Required"
                                  disableToolbar
                                  variant="inline"
                                  format="yyyy-MM-dd"
                                  margin="normal"
                                  id="date-picker-inline"
                                  label="Permit Renewal/Expiration Date"
                                  autoOk={true}
                                  value={permitExpiration}
                                  onChange={handlePermitExpirationChange}
                                  minDate={new Date()}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid container item spacing={0} justify="center">
                          <Grid item lg={6} md={6} s={12} xs={12}>
                            <TextField
                              id="job-comments"
                              label="Job Description"
                              multiline
                              rows={4}
                              variant="outlined"
                              fullWidth
                              error={errors["comments"]}
                              helperText="Required"
                              onChange={handleCommentsChange}
                              value={comments}
                            />
                          </Grid>
                        </Grid>
                        <Grid container item spacing={0} justify="center">
                          <Grid item lg={6} md={6} s={12} xs={12}>
                            <TextField
                              id="completion-comments"
                              label="Job Closing Comments"
                              multiline
                              rows={4}
                              variant="outlined"
                              fullWidth
                              error={errors["completionComments"]}
                              helperText="Required"
                              onChange={handleCompletionCommentsChange}
                              value={completionComments}
                            />
                          </Grid>
                        </Grid>
                        <Grid container item spacing={0} justify="center">
                          <Grid item lg={6} md={6} s={12} xs={12}>
                            <DropzoneArea onChange={files => { setFiles(files); }} key={submits} dropzoneText="Click on icon below to upload documents, pictures, or videos"/>
                          </Grid>
                        </Grid>
                        <Grid container item spacing={0} justify="center">
                          <Grid item lg={6} md={6} s={12} xs={12}>
                            <Button variant="contained" color="primary" style={{ width: "100%" }} onClick={handleAddPermitSubmit}>Add Permit</Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </form>
                    <Snackbar open={successOpen} autoHideDuration={2000} onClose={handleSuccessClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}>
                      <Alert onClose={handleSuccessClose} severity="success">
                        Permit successfully added
                      </Alert>
                    </Snackbar>
                    <Snackbar open={errorOpen} autoHideDuration={2000} onClose={handleErrorClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}>
                      <Alert onClose={handleErrorClose} severity="error">
                        Failed to add permit
                      </Alert>
                    </Snackbar>
                    <Backdrop open={backdropOpen} style={{ zIndex: "999" }}>
                      <CircularProgress color="inherit"/>
                    </Backdrop>
                  </div>
                </div>
              )
            },
            {
              tabName: "Due",
              tabIcon: Error,
              tabContent: (
                <div className={classes.textCenter}>
                  <h3 style={{ fontWeight: "bold" }}>Due</h3>
                  <PermitsLateReport rows={expired.filter((row) => row.expired)} />
                </div>
              ),
            },
          ]}
        />
        <Backdrop open={backdropOpen} style={{ zIndex: "999" }}>
          <CircularProgress color="inherit"/>
        </Backdrop>
      </div>
      <AddContactDialog
        row={addContactRow}
        open={addContactDialogOpen}
        onClose={() => setAddContactDialogOpen(false)}
        onSubmit={handleAddContactSubmit}
        backdropOpen={addContactBackdropOpen}
      />
      <AddPermitsEventDialog
        row={addEventRow}
        open={addEventDialogOpen}
        onClose={() => setAddEventDialogOpen(false)}
        backdropOpen={addEventBackdropOpen}
      />

      <div className={classes.textCenter} style={darkText}>
        <h3>Need Help?</h3>
        Call Us <span style={boldText}>931-363-5379</span>
      </div>


      <Footer
        content={
          <div>
            <div className={classes.left}>
              &copy; {1900 + new Date().getYear()} , Parr Inc.
            </div>
            <div className={classes.right}>
              Version: {process.env.REACT_APP_VERSION}
            </div>
          </div>
        }
      />
    </div>
  );
}
