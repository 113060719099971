import React, { useState } from "react";
import PropTypes from "prop-types";

import { useConfirmDialog } from "react-mui-confirm";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

import StyledDataGrid from "components/StyledDataGrid/StyledDataGrid.js";

import { history } from "../../helpers";
import { authService } from "../../services";

import LoadingBackdrop from "components/CustomLoadingBackdrop/LoadingBackdrop.js";

function VendorViewFacilityDialog(props) {
  const confirm = useConfirmDialog();

  const user = authService.getUser();
  const ses = authService.getSes();
  const jwt = authService.getJWT();

  const columns = [
    { field: "company", headerName: "Vendor", flex: 100 },
    { field: "alt_cat_4", headerName: "Facility", flex: 100 },
    { field: "city", headerName: "City", flex: 100 },
    { field: "state", headerName: "State", flex: 100 },
    { field: "services", headerName: "Services", flex: 100 },
    {
      field: "remove",
      headerName: "Remove Facility",
      flex: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const removeButton = (
          <Tooltip
            title={
              params.row.assigned ? "Unable to remove an assigned facility" : ""
            }
          >
            <span>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => removeFacility(params.row)}
                disabled={params.row.assigned}
              >
                Remove Facility
              </Button>
            </span>
          </Tooltip>
        );
        return removeButton;
      },
    },
  ];

  const [facilities, setFacilities] = useState([]);
  const [backdropOpen, setBackdropOpen] = useState(false);

  const removeFacility = (row) => {
    confirm({
      title: "Are you sure you wish to remove this facility?",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      onConfirm: () => {
        setBackdropOpen(true);
        const headers = {
          Authorization: `Bearer ${jwt}`,
        };
        fetch(
          `${process.env.REACT_APP_API_URI}/vendor/vendor_facility?company_id=${ses.selected_company}&vendor_company_id=${row.company_id}&vendor_cat_4=${row.cat_4}`,
          {
            headers,
            method: "DELETE",
          }
        )
          .then((resp) => {
            if (resp.status === 403) {
              setBackdropOpen(false);
              history.push(user.homepage);
            } else if (resp.status !== 200) {
              setBackdropOpen(false);
              alert("Failed to remove facility");
              return {};
            }
            return resp.json();
          })
          .then(() => {
            setBackdropOpen(false);
            getFacilities();
          });
      },
    });
  };

  const getFacilities = () => {
    if (Object.keys(props.row).length === 0) return;

    setBackdropOpen(true);
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    fetch(
      `${process.env.REACT_APP_API_URI}/vendor/vendor_facilities?company_id=${ses.selected_company}`,
      { headers }
    )
      .then((resp) => {
        if (resp.status === 403) {
          setBackdropOpen(false);
          history.push(user.homepage);
        }
        return resp.json();
      })
      .then((json) => {
        setBackdropOpen(false);
        setFacilities(
          json.filter((i) => props.row.company_id === i.company_id)
        );
      });
  };

  React.useEffect(() => {
    getFacilities();
  }, [props.row]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
        Vendor Facilities
      </DialogTitle>
      <div style={{ height: 600, width: "100%" }}>
        <StyledDataGrid
          rows={facilities}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
        />
      </div>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Close
        </Button>
      </DialogActions>
      <LoadingBackdrop open={backdropOpen} />
    </Dialog>
  );
}

VendorViewFacilityDialog.displayName = "VendorViewFacilityDialog";
VendorViewFacilityDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
};

export default VendorViewFacilityDialog;
