import React, { useState } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";

import Autocomplete from "@material-ui/lab/Autocomplete";

import LoadingBackdrop from "components/CustomLoadingBackdrop/LoadingBackdrop.js";

import homePageStyle from "assets/jss/material-kit-pro-react/views/homePageStyle.js";
import { authService } from "../../services";
import { history } from "../../helpers";
const useStyles = makeStyles(homePageStyle);

function AddSensorDialog(props) {
  const classes = useStyles();
  const [submits, setSubmits] = useState(0);
  const [sensor, setSensor] = useState(0);
  const [sensorHeight, setSensorHeight] = useState(0);
  const [backdropOpen, setBackdropOpen] = useState(false);

  const defaultErrors = {
    sensor: false,
    sensorHeight: false,
  };
  const [errors, setErrors] = useState(defaultErrors);

  const jwt = authService.getJWT();
  const ses = authService.getSes();
  const user = authService.getUser();

  const submit = () => {
    setBackdropOpen(true);
    setErrors(defaultErrors);
    const newErrors = {
      sensor: !sensor || sensor === 0,
      sensorHeight:
        !sensorHeight ||
        sensorHeight === 0 ||
        sensorHeight > props.row.max_sensor_height,
    };
    setErrors(newErrors);

    for (const prop in newErrors) {
      if (newErrors[prop]) {
        setBackdropOpen(false);
        return;
      }
    }

    const data = {
      company: ses.selected_company,
      cat_4: props.row.facility,
      tank_id: props.row.id,
      sensor_height: sensorHeight,
      sensor_id: sensor.id,
      sensor_sn: sensor.serial,
    };

    const headers = {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    };
    fetch(`${process.env.REACT_APP_API_URI}/tank/enevo/attach_sensor`, {
      headers,
      method: "PUT",
      body: JSON.stringify(data),
    })
      .then((resp) => {
        if (resp.status === 403) {
          setBackdropOpen(false);
          history.push(user.homepage);
          resp.json = [];
        }
        return resp.json();
      })
      .then(() => {
        alert("Successfully added sensor");
        setSensorHeight("");
        setSubmits(submits + 1);
        props.onSubmit();
        setBackdropOpen(false);
      });
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">Add Sensor</DialogTitle>
      <div style={{ marginTop: "20px", padding: "20px" }}>
        <form className={classes.root} noValidate autoComplete="off">
          <Grid container spacing={3} direction="column">
            <Grid container item spacing={0} justify="center">
              <Grid item lg={12} md={12} s={12} xs={12}>
                <FormControl style={{ width: "100%" }}>
                  <Autocomplete
                    options={props.sensors}
                    getOptionLabel={(option) =>
                      option === 0
                        ? ""
                        : `${option.model} - SN: ${option.serial}`
                    }
                    id="sensor"
                    onChange={(event, value) => setSensor(value)}
                    key={submits}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Sensor"
                        margin="dense"
                        helperText="Required."
                        error={errors["sensor"]}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item spacing={0} justify="center">
              <Grid item lg={12} md={12} s={12} xs={12}>
                <FormControl style={{ width: "100%" }}>
                  <TextField
                    key={submits}
                    margin="dense"
                    id="sensorHeight"
                    name="sensorHeight"
                    label="Sensor Height"
                    type="number"
                    error={errors["sensorHeight"]}
                    helperText={`Required. Must be <= height of tank (${props.row.max_sensor_height} in.).`}
                    fullWidth
                    value={sensorHeight || ""}
                    onChange={(reading) =>
                      setSensorHeight(reading.target.value)
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            style={{ width: "100%", marginTop: "10px" }}
            onClick={() => {
              submit();
            }}
          >
            Add Sensor
          </Button>
        </form>
      </div>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Close
        </Button>
      </DialogActions>
      <LoadingBackdrop open={backdropOpen} />
    </Dialog>
  );
}

AddSensorDialog.displayName = "AddSensorDialog";
AddSensorDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  sensors: PropTypes.array.isRequired,
};

export default AddSensorDialog;
