/*eslint-disable*/
import { format, sub } from "date-fns";
import React, { useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { DropzoneArea } from "material-ui-dropzone";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Snackbar from '@material-ui/core/Snackbar';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from '@material-ui/core/IconButton';
// @material-ui/lab components
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAlert from '@material-ui/lab/Alert';
// @material-ui/icons
import Create from "@material-ui/icons/Create";
import Schedule from "@material-ui/icons/Schedule";
import Image from "@material-ui/icons/Image";
// core components
import Header from "components/Header/Header.js";
import GridItem from "components/Grid/GridItem.js";
import Footer from "components/Footer/Footer.js";

import CustomTabs from "components/CustomTabs/CustomTabs.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import homePageStyle from "assets/jss/material-kit-pro-react/views/homePageStyle.js";

import { history } from "../../helpers";
import { authService } from "../../services";

const useStyles = makeStyles(homePageStyle);

const spacer = {
  marginTop: "120px",
  marginBottom: "50px",
  color: "#000000",
  textAlign: "center",
}

const darkText = {
  color: "#000000"
}

const boldText = {
  fontWeight: "bold",
  fontSize: "1.1em"
}

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function VendorHomePage() {
  const [company, setCompany] = useState("");
  const [facilities, setFacilities] = useState([]);
  const [maintTypes, setMaintTypes] = useState([{type: "", name: ""}]);
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [submits, setSubmits] = useState(0);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [historyRows, setHistoryRows] = useState([]);

  // Form Inputs
  const [selectedFacility, setSelectedFacility] = useState(0);
  const [dateActual, setDateActual] = useState(new Date());
  const [selectedMaintType, setSelectedMaintType] = useState("");
  const [comments, setComments] = useState("");
  const [completionComments, setCompletionComments] = useState("");
  const [files, setFiles] = useState([]);

  // History Inputs
  const [selectedStartDate, setSelectedStartDate] = useState(sub(new Date(), { days: 180 }));
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [selectedHistoryFacility, setSelectedHistoryFacility] = useState("");
  const [selectedHistoryFacilityFull, setSelectedHistoryFacilityFull] = useState({label: ""});

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  }

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  }

  const handleHistoryFacilityChange = (event) => {
    const content = event.target.textContent;
    if(content) {
      const fac = parseInt(content.split(":")[0]);
      setSelectedHistoryFacility(fac);
      setSelectedHistoryFacilityFull({label: content});
    } else {
      setSelectedHistoryFacility("");
      setSelectedHistoryFacilityFull({label: ""});
    }
  }

  const defaultErrors = {
    facility: false,
    dateActual: false,
    maintType: false,
    comments: false,
    completionComments: false,
  };
  const [errors, setErrors] = useState(defaultErrors);

  const defaultProps = {
    options: facilities,
    getOptionLabel: (option) => option == 0 ? "" : option.label,
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessOpen(false);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };

  const handleFacilityChange = (event) => {
    const content = event.target.textContent;
    const newErrors = errors;
    if(content) {
      const fac = parseInt(content.split(":")[0]);
      setSelectedFacility(fac);
      newErrors["facility"] = false;
    } else {
      newErrors["facility"] = true;
    }
    setErrors(newErrors);
  };

  const handleDateActualChange = (date) => {
    const newErrors = errors;
    if(String(date) == "Invalid Date" || String(date) === "null") {
      newErrors["dateActual"] = true;
    } else {
      newErrors["dateActual"] = false;
    }
    setDateActual(date);
    setErrors(newErrors);
  };

  const handleMaintTypeChange = (event) => {
    const value = event.target.value;

    const newErrors = errors;
    if(value !== "") {
      setSelectedMaintType(value);
      newErrors["maintType"] = false;
    } else {
      newErrors["maintType"] = true;
    }
    setErrors(newErrors);
  };

  const handleCommentsChange = (event) => {
    const value = event.target.value;

    const newErrors = errors;
    if(value !== "") {
      setComments(value);
      newErrors["comments"] = false;
    } else {
      newErrors["comments"] = true;
    }
    setErrors(newErrors);
  };

  const handleCompletionCommentsChange = (event) => {
    const value = event.target.value;

    const newErrors = errors;
    if(value !== "") {
      setCompletionComments(value);
      newErrors["completionComments"] = false;
    } else {
      newErrors["completionComments"] = true;
    }
    setErrors(newErrors);
  };

  const handleCompanySelect = (e) => {
    setSelectedHistoryFacility("");
    setSelectedHistoryFacilityFull({label: ""});
    setCompany(e);
    const user = authService.getUser();
    history.push(user.homepage);
  };

  const handleSubmit = () => {
    checkAccess();
    let errorFound = false;
    const newErrors = defaultErrors;
    if(selectedFacility === 0) {
      newErrors["facility"] = true;
      errorFound = true;
    }

    if(dateActual === "Invalid Date" || dateActual == "null") {
      newErrors["dateActual"] = true;
      errorFound = true;
    }

    if(selectedMaintType === "") {
      newErrors["maintType"] = true;
      errorFound = true;
    }

    if(comments === "") {
      newErrors["comments"] = true;
      errorFound = true;
    }

    if(completionComments === "") {
      newErrors["completionComments"] = true;
      errorFound = true;
    }

    if(errorFound) {
      setErrors(newErrors);
      return;
    }

    setBackdropOpen(true);
    const data = {
      "cat_4": selectedFacility,
      "date_actual": format(dateActual, "yyyy-MM-dd"),
      "maint_type": selectedMaintType,
      comments,
      "comments_complete": completionComments,
    };

    const formData = new FormData();
    formData.append("company", company);
    formData.append("facility", selectedFacility);
    formData.append("cat_4", selectedFacility);
    formData.append("date_actual", format(dateActual, "yyyy-MM-dd"));
    formData.append("maint_type", selectedMaintType);
    formData.append("comments", comments);
    formData.append("comments_complete", completionComments);
    let i = 0;
    files.forEach(file => {
      formData.append(`myFile${i}`, file);
      i++;
    });

    const jwt = authService.getJWT();
    const headers = {
      "Authorization": `Bearer ${jwt}`,
    }
    fetch(`${process.env.REACT_APP_API_URI}/vendor/event`, { headers, method: "POST", body: formData, }).then(resp => {
      setBackdropOpen(false);
      if(resp.status == 200) {
        setSuccessOpen(true);
        clearForm();
        reloadHistory();
      } else {
        setErrorOpen(true);
      }
    });
  };

  const clearForm = () => {
    setSubmits(submits + 1);
    setDateActual(new Date());
    setSelectedMaintType("");
    setComments("");
    setCompletionComments("");
  };

  const loadData = () => {
    const user = authService.getUser();
    const ses = authService.getSes();
    setCompany(ses.selected_company);

    const jwt = authService.getJWT();
    const headers = {
      "Authorization": `Bearer ${jwt}`,
    }
    fetch(`${process.env.REACT_APP_API_URI}/parrtrax/facilities?company=${ses.selected_company}`, { headers }).then(resp => {
      return resp.json();
    }).then(json => {
      setFacilities(json);
    });

    fetch(`${process.env.REACT_APP_API_URI}/vendor/maint_types?company=${ses.selected_company}`, { headers }).then(resp => {
      return resp.json();
    }).then(json => {
      setMaintTypes(json);
    });

    reloadHistory();
  };

  const reloadHistory = () => {
    const ses = authService.getSes();
    const jwt = authService.getJWT();
    const headers = {
      "Authorization": `Bearer ${jwt}`,
    }
    if(selectedHistoryFacility && selectedStartDate && selectedEndDate) {
      let startStr, endStr;
      try {
        startStr = format(new Date(selectedStartDate), "yyyy-MM-dd");
      } catch {}

      try {
        endStr = format(new Date(selectedEndDate), "yyyy-MM-dd");
      } catch {}

      fetch(`${process.env.REACT_APP_API_URI}/vendor/event_history?cat_4=${selectedHistoryFacility}&start=${startStr}&end=${endStr}&company=${ses.selected_company}`, { headers }).then(resp => {
        if(resp.status == 403) {
          alert('Access Denied');
          history.push(user.homepage);
        }
        return resp.json();
      }).then(json => {
        setHistoryRows(json);
      });
    }
  };

  const checkAccess = () => {
    authService.isTokenValid().then((json) => {
      if ("msg" in json) {
        authService.logout();
        window.location.reload(true);
      }
    });
  };

  React.useEffect(() => {
    const state = history.location.state;
    if(state && Object.prototype.hasOwnProperty.call(state, "facility")) {
      setSelectedHistoryFacilityFull(state.facility);
      const fac = parseInt(state.facility.label.split(":")[0]);
      setSelectedHistoryFacility(fac);
    }

    authService.accessCheck(loadData, []);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, [ selectedHistoryFacility, selectedStartDate, selectedEndDate, company, ]);

  const classes = useStyles();

  const getTab = () => {
    const state = history.location.state;
    const tab = state && Object.prototype.hasOwnProperty.call(state, "tab") ? state.tab : 0;
    return tab;
  };

  return (
    <div>
      <Header
        brand="ParrTRAX"
        links={<HeaderLinks dropdownHoverColor="success" handleCompanySelect={handleCompanySelect} />}
        fixed
        color="primary"
        className={classes.title}
      />

      <div style={spacer} justify="center"></div>
      <div className={classes.container}>
        <CustomTabs
          headerColor="primary"
          value={getTab()}
          tabs={[
            {
              tabName: "Add Event",
              tabIcon: Create,
              tabContent: (
                <div className={classes.textCenter}>
                  <div>
                    <h3 style={{ fontWeight: "bold" }}>
                      Add Event
                    </h3>
                    <form className={classes.root} noValidate autoComplete="off">
                      <Grid container spacing={3} direction="column" >
                        <Grid container item spacing={0} justify="center">
                          <Grid item lg={6} md={6} s={12} xs={12}>
                            <FormControl style={{ width: "100%" }}>
                              <Autocomplete
                                {...defaultProps}
                                id="facility"
                                onChange={handleFacilityChange}
                                key={submits}
                                renderInput={(params) => <TextField {...params} label="Facility" margin="normal" helperText="Type Facility ID, City, or State - Required" error={errors["facility"]}/>}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid container item spacing={0} justify="center">
                          <Grid item lg={6} md={6} s={12} xs={12}>
                            <FormControl style={{ width: "100%" }} error={errors["facility"]}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  helperText="Required"
                                  disableToolbar
                                  variant="inline"
                                  format="yyyy-MM-dd"
                                  margin="normal"
                                  id="date-picker-inline"
                                  label="Date Completed"
                                  autoOk={true}
                                  value={dateActual}
                                  onChange={handleDateActualChange}
                                  maxDate={new Date()}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid container item spacing={0} justify="center">
                          <Grid item lg={6} md={6} s={12} xs={12}>
                            <FormControl style={{ width: "100%" }} error={errors["maintType"]}>
                              <InputLabel id="maint-type-label">Maintenance Type</InputLabel>
                              <Select
                                labelId="maint-type-label"
                                id="maint-type-select"
                                style={{ textAlign: "left" }}
                                value={selectedMaintType}
                                onChange={handleMaintTypeChange}
                                key={submits}
                              >
                                {maintTypes.map(maintType => (
                                    <MenuItem key={maintType.type || ""} value={maintType.type || ""}>{maintType.name || ""}</MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>Required</FormHelperText>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid container item spacing={0} justify="center">
                          <Grid item lg={6} md={6} s={12} xs={12}>
                            <TextField
                              id="job-comments"
                              label="Job Description"
                              multiline
                              rows={4}
                              variant="outlined"
                              fullWidth
                              error={errors["comments"]}
                              helperText="Required"
                              onChange={handleCommentsChange}
                              value={comments}
                            />
                          </Grid>
                        </Grid>
                        <Grid container item spacing={0} justify="center">
                          <Grid item lg={6} md={6} s={12} xs={12}>
                            <TextField
                              id="completion-comments"
                              label="Job Closing Comments"
                              multiline
                              rows={4}
                              variant="outlined"
                              fullWidth
                              error={errors["completionComments"]}
                              helperText="Required"
                              onChange={handleCompletionCommentsChange}
                              value={completionComments}
                            />
                          </Grid>
                        </Grid>
                        <Grid container item spacing={0} justify="center">
                          <Grid item lg={6} md={6} s={12} xs={12}>
                            <DropzoneArea maxFileSize={10000000} onChange={files => { setFiles(files); }} key={submits} dropzoneText="Click on icon below to upload documents, pictures, or videos"/>
                          </Grid>
                        </Grid>
                        <Grid container item spacing={0} justify="center">
                          <Grid item lg={6} md={6} s={12} xs={12}>
                            <Button variant="contained" color="primary" style={{ width: "100%" }} onClick={handleSubmit}>Add Event</Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </form>
                    <Snackbar open={successOpen} autoHideDuration={2000} onClose={handleSuccessClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}>
                      <Alert onClose={handleSuccessClose} severity="success">
                        Event successfully added
                      </Alert>
                    </Snackbar>
                    <Snackbar open={errorOpen} autoHideDuration={2000} onClose={handleErrorClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}>
                      <Alert onClose={handleErrorClose} severity="error">
                        Failed to add event
                      </Alert>
                    </Snackbar>
                    <Backdrop open={backdropOpen} style={{ zIndex: "999" }}>
                      <CircularProgress color="inherit"/>
                    </Backdrop>
                  </div>
                </div>
              )
            },
            {
              tabName: "History",
              tabIcon: Schedule,
              tabContent: (
                <div className={classes.textCenter}>
                  <h3 style={{ fontWeight: "bold" }}>
                    Event History
                  </h3>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                      <FormControl className={classes.formControl} style={{ minWidth: 120, marginBottom: 10 }}>
                        <Autocomplete
                          {...defaultProps}
                          onChange={handleHistoryFacilityChange}
                          value={selectedHistoryFacilityFull}
                          renderInput={(params) => <TextField {...params} label="Facility" margin="normal"/>}
                          style={{ width: "100%", minWidth: 200, }}
                        />
                      </FormControl>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        id="date-picker-inline"
                        label="Start"
                        maxDate={selectedEndDate}
                        value={selectedStartDate}
                        onChange={handleStartDateChange}
                        autoOk={true}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        id="date-picker-inline"
                        label="End"
                        minDate={selectedStartDate}
                        value={selectedEndDate}
                        onChange={handleEndDateChange}
                        autoOk={true}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead className={classes.table_header}>
                        <TableRow className={classes.table_header_row}>
                          <TableCell className={classes.table_header_cell}>Date</TableCell>
                          <TableCell className={classes.table_header_cell}>Type</TableCell>
                          <TableCell className={classes.table_header_cell}>Description</TableCell>
                          <TableCell className={classes.table_header_cell}>Closing Comments</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.table_body}>
                        {historyRows.map((row) => (
                          <TableRow key={row.maint_id} className={classes.table_body_row}>
                            <TableCell component="th" scope="row" className={classes.table_body_cell}>{row.date_actual}</TableCell>
                            <TableCell className={classes.table_body_cell}>{row.maint_type}</TableCell>
                            <TableCell className={classes.table_body_cell}>{row.comments}</TableCell>
                            <TableCell className={classes.table_body_cell}>{row.comments_complete}</TableCell>
                            <TableCell className={classes.table_body_cell} style={{ padding: "0", textAlign: "center" }}>
                              <IconButton aria-label="expand row" size="small" onClick={() => { history.push({ pathname: "/gallery", search: `?cat_4=${selectedHistoryFacility}`, state: { returnTo: "/events", tab: 1, facility: selectedHistoryFacilityFull } }); }}>
                                <Image />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )
            }
          ]}
        />
      </div>

      <div className={classes.textCenter} style={darkText}>
        <h3>Need Help?</h3>
        Call Us <span style={boldText}>931-363-5379</span>
      </div>


      <Footer
        content={
          <div>
            <div className={classes.left}>
              &copy; {1900 + new Date().getYear()} , Parr Inc.
            </div>
            <div className={classes.right}>
              Version: {process.env.REACT_APP_VERSION}
            </div>
          </div>
        }
      />
    </div>
  );
}
