import { history } from "../helpers";

const login = (username, password) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password }),
  };

  return fetch(`${process.env.REACT_APP_API_URI}/login`, requestOptions)
    .then((resp) => {
      return resp.json();
    })
    .then((json) => {
      return json;
    });
};

const logout = () => {
  const jwt = getJWT();
  const headers = {
    Authorization: `Bearer ${jwt}`,
  };
  fetch(`${process.env.REACT_APP_API_URI}/logout`, {
    method: "DELETE",
    headers,
  });
  clearLocalStorage();
};

const isTokenValid = () => {
  const jwt = getJWT();
  const headers = {
    Authorization: `Bearer ${jwt}`,
  };
  return fetch(`${process.env.REACT_APP_API_URI}/current_user`, { headers })
    .then((resp) => {
      return resp.json();
    })
    .then((json) => {
      return json;
    });
};

const accessCheck = (callback, args) => {
  isTokenValid().then((json) => {
    if ("msg" in json) {
      logout();
      window.location.reload();
    } else {
      callback(...args);
    }
  });
};

const getJWT = () => {
  let jwt = localStorage.getItem("jwt");
  if (jwt === null) {
    clearLocalStorage();
    jwt = "";
    window.location.reload();
  }
  return jwt;
};

const getUser = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  if (user === null) {
    clearLocalStorage();
    history.push("/login");
    user = {
      companies: [],
      homepage: "home",
      name: "",
      usergroups: [],
      username: "",
      vendor_company_id: "",
    };
  }
  return user;
};

const getSes = () => {
  let session = JSON.parse(localStorage.getItem("ses"));
  if (session === null) {
    clearLocalStorage();
    history.push("/login");
    session = { selected_company: "", companies_menu: false };
  }
  return session;
};

const setLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

const clearLocalStorage = () => {
  localStorage.removeItem("jwt");
  localStorage.removeItem("user");
  localStorage.removeItem("ses");
};

const setSes = (p, v) => {
  let ses = getSes();
  ses[p] = v;
  setLocalStorage("ses", JSON.stringify(ses));
};

const forgotPassword = (email) => {
  const headers = {
    accept: "application/json",
  };
  return fetch(
    `${process.env.REACT_APP_API_URI}/forgot_password?email=${email.email}`,
    { headers }
  )
    .then((resp) => {
      return resp.json();
    })
    .then((json) => {
      return json;
    });
};

const resetPassword = (id, password) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ id, password }),
  };
  return fetch(
    `${process.env.REACT_APP_API_URI}/reset_password`,
    requestOptions
  )
    .then((resp) => {
      return resp.json();
    })
    .then((json) => {
      return json;
    });
};

const userRegistration = (
  username,
  email,
  password,
  phone,
  vendor_company_id
) => {
  const requestOptions = {
    method: "POST",
    headers: { accept: "application/json", "Content-Type": "application/json" },
    body: JSON.stringify({
      username,
      email,
      password,
      phone,
      vendor_company_id,
    }),
  };
  return fetch(`${process.env.REACT_APP_API_URI}/register`, requestOptions)
    .then((resp) => {
      return resp.json();
    })
    .then((json) => {
      return json;
    });
};

export const authService = {
  login,
  logout,
  isTokenValid,
  getUser,
  getJWT,
  getSes,
  setSes,
  forgotPassword,
  resetPassword,
  userRegistration,
  accessCheck,
  clearLocalStorage,
  setLocalStorage,
};
