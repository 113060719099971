import React, { useState } from "react";
import PropTypes from "prop-types";

import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import { DropzoneArea } from "material-ui-dropzone";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Autocomplete from "@material-ui/lab/Autocomplete";
import LoadingBackdrop from "components/CustomLoadingBackdrop/LoadingBackdrop.js";

import { authService } from "../../services";
import homePageStyle from "assets/jss/material-kit-pro-react/views/homePageStyle.js";
const useStyles = makeStyles(homePageStyle);

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

function AddPermitsEventDialog(props) {
  const classes = useStyles();
  const ses = authService.getSes();
  const jwt = authService.getJWT();

  const [submits, setSubmits] = useState(0);
  const [parameters, setParameters] = useState([]);
  const [subParameters, setSubParameters] = useState([]);
  const [addReadingsFacilities, setAddReadingsFacilities] = useState([])
  const [parameterInputs, setParameterInputs] = useState({});
  const [addReadingsFiles, setAddReadingsFiles] = useState([]);
  const [addReadingsDateActual, setAddReadingsDateActual] = useState(new Date());
  const [addReadingsTime, setAddReadingsTime] = useState(new Date());
  const [selectedFacility, setSelectedFacility] = useState(0);
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);

  const defaultAddReadingsFacilityProps = {
    options: addReadingsFacilities,
    getOptionLabel: (option) => option === 0 ? "" : option.label,
  };

  const defaultParameterProps = {
    options: parameters,
    getOptionLabel: (option) => option === 0 ? "" : option.label,
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessOpen(false);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };

  const handleParameterInputChange = (event) => {
    const id = event.target.id;
    const value = event.target.value;
    parameterInputs[id.split("-")[1]] = value;
    errors[id] = value === "";
  };

  const loadData = () => {
    const headers = {
      "Authorization": `Bearer ${jwt}`,
    }
    fetch(`${process.env.REACT_APP_API_URI}/permit/facilities?company=${ses.selected_company}`, { headers }).then(resp => {
      return resp.json();
    }).then(json => {
      setAddReadingsFacilities(json);
    });
  };

  const handleParameterChange = (event) => {
    setSubParameters([])
    setParameterInputs({});
    const content = event.target.textContent;
    let parameterId = null;
    let subParameters = [];
    let uom = null;
    parameters.forEach(parameter => {
      if(parameter.label === content) {
        parameterId = parameter.parameter;
        subParameters = parameter.sub_parameters;
        uom = parameter.uom;
      }
    });

    if(uom.toLowerCase() !== "pass/fail") {
      subParameters = [{"label": content, uom, "parameter": parameterId}].concat(subParameters);
    }

    subParameters.forEach(p => {
      parameterInputs[`input-${p.parameter}`] = "";
    });

    setSubParameters(subParameters);
  };

  const handleAddReadingsTimeChange = (date) => {
    const newErrors = errors;
    if(String(date) === "Invalid Date" || String(date) === "null") {
      newErrors["addReadingsTime"] = true;
    } else {
      newErrors["addReadingsTime"] = false;
    }
    setAddReadingsTime(date);
    setErrors(newErrors);
  };

  const handleAddReadingsDateActualChange = (date) => {
    const newErrors = errors;
    if(String(date) === "Invalid Date" || String(date) === "null") {
      newErrors["addReadingsDateActual"] = true;
    } else {
      newErrors["addReadingsDateActual"] = false;
    }
    setAddReadingsDateActual(date);
    setErrors(newErrors);
  };

  const handleFacilityChange = (event) => {
    const content = event.target.textContent;
    const fac = parseInt(content.split(":")[0]);
    setSelectedFacility(fac);

    const newErrors = errors;
    if(content) {
      newErrors["facility"] = false;

      const ses = authService.getSes();
      const jwt = authService.getJWT();
      const headers = {
        "Authorization": `Bearer ${jwt}`,
      }
      fetch(`${process.env.REACT_APP_API_URI}/permit/parameters?company=${ses.selected_company}&cat_4=${fac}`, { headers }).then(resp => {
        return resp.json();
      }).then(json => {
        setParameters(json);
      });
    } else {
      newErrors["facility"] = true;
    }
    setErrors(newErrors);
  };

  const defaultErrors = {};
  const [errors, setErrors] = useState(defaultErrors);
  const [backdropOpen, setBackdropOpen] = useState(false);

  React.useEffect(() => {
    loadData();
  }, []);

  const submit = () => {
    setBackdropOpen(true);

    const formData = new FormData();
    formData.append("company", ses.selected_company);
    formData.append("cat_4", selectedFacility);
    formData.append("date_actual", format(addReadingsDateActual, "yyyy-MM-dd"));
    formData.append("time", format(addReadingsTime, "HH:mm"));
    let i = 0;
    addReadingsFiles.forEach(file => {
      formData.append(`myFile${i}`, file);
      i++;
    });

    for(const id in parameterInputs) {
      formData.append(`parameter-${id}`, parameterInputs[id]);
    }

    const jwt = authService.getJWT();
    const headers = {
      "Authorization": `Bearer ${jwt}`,
    }
    fetch(`${process.env.REACT_APP_API_URI}/permit/parameter_readings`, { headers, method: "POST", body: formData, }).then(resp => {
      setBackdropOpen(false);
      if(resp.status === 200) {
        setSuccessOpen(true);
        clearReadingsForm();
      } else {
        setErrorOpen(true);
      }
    });
  };

  const clearReadingsForm = () => {
    setSubmits(submits + 1);
    setAddReadingsDateActual(new Date());
    setAddReadingsTime(new Date());
    setParameterInputs({})
    setSubParameters([])
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">Add Event</DialogTitle>
      <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <form className={classes.root} noValidate autoComplete="off">
          <Grid container spacing={3} direction="column">
            <Grid container item spacing={0} justify="center">
              <Grid item lg={12} md={12} s={12} xs={12}>
                <FormControl style={{ width: "100%" }}>
                  <Autocomplete
                    {...defaultAddReadingsFacilityProps}
                    id="facility"
                    onChange={handleFacilityChange}
                    key={submits}
                    renderInput={(params) => <TextField {...params} label="Facility" margin="normal" helperText="Type Facility ID, City, or State - Required" error={errors["facility"]}/>}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item spacing={0} justify="center">
              <Grid item lg={12} md={12} s={12} xs={12}>
                <FormControl style={{ width: "100%" }} error={errors["addReadingsDateActual"]}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      helperText="Required"
                      disableToolbar
                      variant="inline"
                      format="yyyy-MM-dd"
                      margin="normal"
                      id="date-picker-inline"
                      label="Date Completed"
                      autoOk={true}
                      value={addReadingsDateActual}
                      onChange={handleAddReadingsDateActualChange}
                      maxDate={new Date()}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item spacing={0} justify="center">
              <Grid item lg={12} md={12} s={12} xs={12}>
                <FormControl style={{ width: "100%" }} error={errors["addReadingsTime"]}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      helperText="Required"
                      disableToolbar
                      variant="inline"
                      format="HH:mm"
                      margin="normal"
                      id="date-picker-inline"
                      label="Time Completed"
                      autoOk={true}
                      value={addReadingsTime}
                      onChange={handleAddReadingsTimeChange}
                      maxDate={new Date()}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item spacing={0} justify="center">
              <Grid item lg={12} md={12} s={12} xs={12}>
                <FormControl style={{ width: "100%" }}>
                  <Autocomplete
                    {...defaultParameterProps}
                    id="parameter"
                    onChange={handleParameterChange}
                    key={submits}
                    renderInput={(params) => <TextField {...params} label="Parameter" margin="normal" helperText="Required" error={errors["parameter"]}/>}
                  />
                </FormControl>
              </Grid>
            </Grid>
            {subParameters.map((input) => (
              <Grid container item spacing={0} justify="center" key={input.parameter}>
                <Grid item lg={12} md={12} s={12} xs={12}>
                  <TextField
                    id={`input-${input.parameter}`}
                    key={`input-${input.parameter}`}
                    label={`${input.label} - ${input.uom}`}
                    variant="outlined"
                    fullWidth
                    error={errors[`input-${input.parameter}`]}
                    helperText="Required"
                    onChange={handleParameterInputChange}
                  />
                </Grid>
              </Grid>
            ))}
            <Grid container item spacing={0} justify="center">
              <Grid item lg={12} md={12} s={12} xs={12}>
                <DropzoneArea filesLimit={1} onChange={files => { setAddReadingsFiles(files); }} key={submits} dropzoneText="Click on icon below to upload documents, pictures, or videos"/>
              </Grid>
            </Grid>
            <Grid container item spacing={0} justify="center">
              <Grid item lg={12} md={12} s={12} xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: "100%" }}
                  onClick={submit}
                >
                  Add Event
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Close
        </Button>
      </DialogActions>
      <LoadingBackdrop open={backdropOpen} />
      <Snackbar open={successOpen} autoHideDuration={2000} onClose={handleSuccessClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}>
        <Alert onClose={handleSuccessClose} severity="success">
          Readings successfully added
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={2000} onClose={handleErrorClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}>
        <Alert onClose={handleErrorClose} severity="error">
          Failed to add readings
        </Alert>
      </Snackbar>
    </Dialog>
  );
}

AddPermitsEventDialog.displayName = "AddPermitsEventDialog";
AddPermitsEventDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  row: PropTypes.object,
};

export default AddPermitsEventDialog;
