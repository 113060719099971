/*eslint-disable*/
import React, { useState, Component } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Face from "@material-ui/icons/Face";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";

import image from "assets/img/bg13.jpg";
import { history } from "../../helpers";
import { authService } from "../../services";

import parrTraxLogo from "assets/img/parrtrax_logo_white_sm.png";
import { cardTitle, infoColor } from "assets/jss/material-kit-pro-react.js";

const useStyles = makeStyles(loginPageStyle);

export default function LoginPage() {
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [openForgotPasswordDialog, setOpenForgotPasswordDialog] = useState(false);
  const [emailForgotPassword, setEmailForgotPassword] = useState("");
  const [emailForgotPasswordError, setEmailForgotPasswordError] = useState("");

  const [companies, setCompanies] = useState([{company_id: "", name: ""}]);
  const [errorUserRegistration, setErrorUserRegistration] = useState(false);
  const [openUserRegistrationDialog, setOpenUserRegistrationDialog] = useState(false);
  const [userRegistrationSelectedCompany, setUserRegistrationSelectedCompany] = useState("");
  const [userRegistrationUsername, setUserRegistrationUsername] = useState("");
  const [userRegistrationEmail, setUserRegistrationEmail] = useState("");
  const [userRegistrationPassword, setUserRegistrationPassword] = useState("");
  const [userRegistrationPasswordConfirm, setUserRegistrationPasswordConfirm] = useState("");
  const [userRegistrationPhone, setUserRegistrationPhone] = useState("");
  const [userRegistrationSelectedCompanyError, setUserRegistrationSelectedCompanyError] = useState("");
  const [userRegistrationUsernameError, setUserRegistrationUsernameError] = useState("");
  const [userRegistrationEmailError, setUserRegistrationEmailError] = useState("");
  const [userRegistrationPasswordError, setUserRegistrationPasswordError] = useState("");
  const [userRegistrationMsg, setUserRegistrationMsg] = useState("");
  const [backdropOpen, setBackdropOpen] = useState(false);

  const validateForm = () => {
    return username.length > 0 && password.length > 0;
  }

  const initClientSession = () => {
    let ses = {};
    const user = authService.getUser();
    ses['selected_company'] = user.companies[0];
    ses['companies_menu'] = (user.companies.length > 1 ? true : false);
    authService.setLocalStorage("ses", JSON.stringify(ses));
  }

  const handleSubmit = (event) => {
    setUsernameError(false);
    setPasswordError(false);
    setErrorMessage("");
    event.preventDefault();

    authService.login(username, password).then(json => {
      if('error' in json) {
        if(json.error === 3) {
          setUsernameError(true);
          setErrorMessage("Invalid username");
        }
        if(json.error === 4) {
          setPasswordError(true);
          setErrorMessage("Invalid password");
        }
        if(json.error === 5) {
          setErrorMessage("This account is currently waiting for approval");
        }
      } else {
        authService.setLocalStorage("user", JSON.stringify(json.user));
        authService.setLocalStorage("jwt", json.access_token);
        initClientSession();
        history.push(json.user.homepage, {});
      }
    });
  }

  const loadCompanies = () => {
    const headers = {
      accept: "application/json",
    };
    fetch(`${process.env.REACT_APP_API_URI}/vendor/companies`, { headers }).then(resp => {
      return resp.json();
    }).then(json => {
      setCompanies(json);
    });
  };

  const handleForgotPasswordDialogOpen = () => {
    setOpenForgotPasswordDialog(true);
  }

  const handleForgotPasswordDialogClose = () => {
    setOpenForgotPasswordDialog(false);
  }

  const handleForgotPasswordDialogTextChange = (event) => {
    setEmailForgotPassword({email: event.target.value});
  }

  const handleForgotPasswordSubmit = (event) => {
    setEmailForgotPasswordError(false);
    setErrorMessage("");
    setSuccessMessage("");

    authService.forgotPassword(emailForgotPassword).then(json => {
      if('error' in json) {
        setEmailForgotPasswordError(true);
        setErrorMessage(json.error);
      } else {
        setSuccessMessage(json.msg);
      }
    });
    handleForgotPasswordDialogClose();
  }

  const handleUserRegistrationDialogOpen = () => {
    loadCompanies();
    setOpenUserRegistrationDialog(true);
  }

  const handleUserRegistrationDialogClose = () => {
    setOpenUserRegistrationDialog(false);
    setUserRegistrationSelectedCompany("");
    setUserRegistrationUsername("");
    setUserRegistrationEmail("");
    setUserRegistrationPassword("");
    setUserRegistrationPasswordConfirm("");
    setUserRegistrationPhone("");
    setUserRegistrationSelectedCompanyError("");
    setUserRegistrationUsernameError("");
    setUserRegistrationEmailError("");
    setUserRegistrationPasswordError("");
    setUserRegistrationMsg("");
  }

  const handleUserRegistrationSubmit = (event) => {
    var valid = true;
    if(userRegistrationSelectedCompany == "") {
      setUserRegistrationSelectedCompanyError("Please Select Your Company");
      valid = false;
    }
    else {
      setUserRegistrationSelectedCompanyError("");
    }
    if(userRegistrationUsername.trim() == "") {
      setUserRegistrationUsernameError("Invalid Username");
      valid = false;
    }
    else {
      setUserRegistrationUsernameError("");
    }
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if(!pattern.test(userRegistrationEmail)) {
      setUserRegistrationEmailError("Invalid Email");
      valid = false;
    }
    else {
      setUserRegistrationEmailError("");
    }
    if(userRegistrationPassword.length < 8) {
      setUserRegistrationPasswordError("Password too short. Must be at least 8 characters");
      setUserRegistrationPassword("");
      setUserRegistrationPasswordConfirm("");
      valid = false;
    }
    else if(userRegistrationPassword != userRegistrationPasswordConfirm) {
      setUserRegistrationPasswordError("Password mismatch");
      setUserRegistrationPassword("");
      setUserRegistrationPasswordConfirm("");
      valid = false;
    }
    else {
      setUserRegistrationPasswordError("");
    }
    if(valid) {
      setBackdropOpen(true);
      setOpenUserRegistrationDialog(false);
      authService.userRegistration(userRegistrationUsername, userRegistrationEmail, userRegistrationPassword, userRegistrationPhone, userRegistrationSelectedCompany).then(json => {
        setBackdropOpen(false);
        setUserRegistrationMsg(json.msg);
        setOpenUserRegistrationDialog(true);
      });
    }
  }

  const classes = useStyles();
  return (
    <div>
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <div className={classes.parrTraxLogo} justify="center">
                <img src={parrTraxLogo} justify="center" />
              </div>
              <Card>
                <form className={classes.form} onSubmit={handleSubmit}>
                  <CardBody signup>
                    <h2 className={classes.login_title}>Login</h2>
                    <span style={{color: "#007cff", fontWeight: "bold"}}>{successMessage}</span>
                    <span style={{color: errorMessage ? "red" : "white", width: "50%"}}>{errorMessage ? errorMessage : "error"}</span>
                    <CustomInput
                      id="username"
                      error={usernameError}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "Username",
                        type: "text",
                        value: username,
                        onChange: (e) => setUsername(e.target.value),
                        startAdornment: (
                          <InputAdornment position="start">
                            <Face className={classes.inputIconsColor} />
                          </InputAdornment>
                        )
                      }}
                    />
                    <CustomInput
                      id="password"
                      error={passwordError}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        placeholder: "Password",
                        type: "password",
                        value: password,
                        onChange: (e) => setPassword(e.target.value),
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon className={classes.inputIconsColor}>
                              lock_utline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off"
                      }}
                    />
                    <div style={{width: "100%"}}>
                      <a style={{ width: "45%", float: "left", cursor: "pointer"}} onClick={handleUserRegistrationDialogOpen}><span style={{ float: "left", color: infoColor[0] }}>Register</span></a>
                      <a style={{ width: "50%", float: "right", cursor: "pointer"}} onClick={handleForgotPasswordDialogOpen}><span style={{ float: "right", color: infoColor[0] }}>Forgot Password?</span></a>
                    </div>
                  </CardBody>
                  <div className={[classes.textCenter, classes.login_buttons]} style={{textAlign: "center"}}>
                  <Button simple color="info" size="lg" type="submit" disabled={!validateForm()}>
                    Login
                  </Button>

                  {/* Forgot Password Dialog */}
                  <Dialog open={openForgotPasswordDialog} onClose={handleForgotPasswordDialogClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Forgot Password</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Please enter your email address. You will receive an email with a link to reset your password.
                      </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Email Address"
                        type="email"
                        fullWidth
                        onChange={handleForgotPasswordDialogTextChange.bind(this)}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleForgotPasswordDialogClose} color="primary">
                        Cancel
                      </Button>
                      <Button onClick={handleForgotPasswordSubmit} color="primary" disabled={!emailForgotPassword}>
                        Send Reset Email
                      </Button>
                    </DialogActions>
                  </Dialog>

                  {/* User Registration Dialog */}
                  <Dialog open={openUserRegistrationDialog} onClose={handleUserRegistrationDialogClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">User Registration</DialogTitle>
                    { userRegistrationMsg == "" &&
                      <div>
                        <DialogContent>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="company"
                            name="company"
                            select
                            value={userRegistrationSelectedCompany}
                            label="Company"
                            type="select"
                            fullWidth
                            helperText="Please select your company"
                            onFocus={(e) => setUserRegistrationSelectedCompanyError("")}
                            onChange={(e) => setUserRegistrationSelectedCompany(e.target.value)}
                          >
                            {companies.map((option) => (
                              <MenuItem key={option.company_id} value={option.company_id}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                          <span style={{color: "red"}}>{userRegistrationSelectedCompanyError}</span>
                          <TextField
                            margin="dense"
                            id="username"
                            name="username"
                            label="Username"
                            type="text"
                            fullWidth
                            value={userRegistrationUsername}
                            onFocus={(e) => setUserRegistrationUsernameError("")}
                            onChange={(e) => setUserRegistrationUsername(e.target.value)}
                          />
                          <span style={{color: "red"}}>{userRegistrationUsernameError}</span>
                          <TextField
                            margin="dense"
                            id="email"
                            name="email"
                            label="Email Address"
                            type="email"
                            fullWidth
                            value={userRegistrationEmail}
                            onFocus={(e) => setUserRegistrationEmailError("")}
                            onChange={(e) => setUserRegistrationEmail(e.target.value)}
                          />
                          <span style={{color: "red"}}>{userRegistrationEmailError}</span>
                          <TextField
                            margin="dense"
                            id="password"
                            name="password"
                            label="Password"
                            type="password"
                            fullWidth
                            value={userRegistrationPassword}
                            onFocus={(e) => setUserRegistrationPasswordError("")}
                            onChange={(e) => setUserRegistrationPassword(e.target.value)}
                          />
                          <span style={{color: "red"}}>{userRegistrationPasswordError}</span>
                          <TextField
                            margin="dense"
                            id="confirm_password"
                            name="confirm_password"
                            label="Confirm Password"
                            type="password"
                            fullWidth
                            value={userRegistrationPasswordConfirm}
                            onFocus={(e) => setUserRegistrationPasswordError("")}
                            onChange={(e) => setUserRegistrationPasswordConfirm(e.target.value)}
                          />
                          <TextField
                            margin="dense"
                            id="phone"
                            name="phone"
                            label="Phone Number (optional)"
                            type="tel"
                            fullWidth
                            value={userRegistrationPhone}
                            onChange={(e) => setUserRegistrationPhone(e.target.value)}
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleUserRegistrationDialogClose} color="primary">
                            Cancel
                          </Button>
                          <Button onClick={handleUserRegistrationSubmit} color="primary">
                            Submit
                          </Button>
                        </DialogActions>
                      </div>
                    }
                    { userRegistrationMsg != "" &&
                    <div>
                      <DialogContent>
                        {userRegistrationMsg}
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleUserRegistrationDialogClose} color="primary">
                          Close
                        </Button>
                      </DialogActions>
                    </div>
                    }
                  </Dialog>

                  </div>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Backdrop open={backdropOpen} style={{ zIndex: "999" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
