import {
  container,
  title,
  main,
  mainRaised,
  mrAuto,
  whiteColor,
  mlAuto,
} from "assets/jss/material-kit-pro-react.js";

const homePageStyle = {
  main,
  mainRaised,
  mrAuto,
  mlAuto,
  container: {
    ...container,
    zIndex: 1,
  },
  title: {
    ...title,
    "&, & + h4": {
      color: whiteColor,
    },
  },
  textCenter: {
    textAlign: "center",
  },
  textLeft: {
    textAlign: "left",
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  left: {
    float: "left!important",
    display: "block",
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right",
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative",
  },
  table_header: {
    background: "#b7b7b7",
  },
  table_header_row: {
    borderBottom: "1px double #949494",
  },
  table_header_cell: {
    textAlign: "center",
    fontSize: "1em",
    lineHeight: "1.1em",
    padding: "6px",
  },
  table_body: {
    background: "#dadada",
  },
  table_body_row: {
    borderBottom: "1px double #bfc1c5",
  },
  table_body_row_hov: {
    borderBottom: "1px double #bfc1c5",
    "&:hover": {
      background: "#f5f4f2",
      cursor: "pointer",
    },
  },
  table_body_cell: {
    textAlign: "center",
    padding: "2px",
  },
  table_text_small: {
    fontSize: ".9em",
  },
  table_text_smaller: {
    fontSize: ".8em",
  },
  table_text_nowrap: {
    whiteSpace: "nowrap",
  },
  mapLegend: {
    width: "100%",
    textAlign: "left",
  },
  historyDialogSpecs: {
    margin: "0px 0px",
  },
  spacer: {
    marginTop: "120px",
    marginBottom: "50px",
    color: "#000000",
    textAlign: "center",
  },
  darkText: {
    color: "#000000",
  },
  boldText: {
    fontWeight: "bold",
    fontSize: "1.1em",
  },
  level0Alert: {
    backgroundColor: "rgba(255,165,0,0.8)",
  },
  level1Alert: {
    backgroundColor: "rgba(255,0,0,0.5)",
  },
  alertGreen: {
    backgroundColor: "lightgreen",
  },
  alertOrange: {
    backgroundColor: "rgba(255,165,0,0.8)",
  },
  alertRed: {
    backgroundColor: "rgba(255,0,0,0.5)",
  },
  permitsFail: {
    backgroundColor: "#fdb214",
  },
  permitsPass: {
    backgroundColor: "#90ee90",
  },
  hideRightSeparator: {
    "& > .MuiDataGrid-columnSeparator": {
      visibility: "hidden",
    },
  },
};

export default homePageStyle;
