import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import StyledDataGrid from "components/StyledDataGrid/StyledDataGrid.js";

import homePageStyle from "assets/jss/material-kit-pro-react/views/homePageStyle.js";

function TankExceptionReport(props) {
  const useStyles = makeStyles(homePageStyle);
  const classes = useStyles();
  const columns = [
    {
      field: "facility",
      headerName: "Facility",
      flex: 75,
    },
    {
      field: "city",
      headerName: "City",
      flex: 100,
    },
    {
      field: "state",
      headerName: "State",
      flex: 75,
    },
    {
      field: "tank_name",
      headerName: "Tank Name",
      flex: 125,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 75,
    },
    {
      field: "oil_level",
      headerName: "Volume [gal]",
      flex: 100,
      align: "center",
    },
    {
      field: "tank_reading",
      headerName: "Percent Full",
      flex: 100,
      align: "center",
      valueFormatter: (params) => `${params.value} %`,
    },
  ];

  const filterRows = (row) => {
    return row.color !== "lightgreen";
  };

  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ height: 600, width: "100%" }}>
        <StyledDataGrid
          rows={props.rows.filter(filterRows)}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          getCellClassName={(params) => {
            if (params.field === "tank_reading") {
              return Number(params.row.alert_level) === 0
                ? classes.level0Alert
                : classes.level1Alert;
            }
            return "";
          }}
        />
      </div>
    </div>
  );
}

TankExceptionReport.displayName = "TankExceptionReport";
TankExceptionReport.propTypes = {
  company: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
};

export default TankExceptionReport;
