import React, { useState } from "react";
import PropTypes from "prop-types";

// nodejs
import { format } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";

import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";

import Carousel from "react-material-ui-carousel";

// @material-ui/lab
import Autocomplete from "@material-ui/lab/Autocomplete";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import CardMedia from "@material-ui/core/CardMedia";

// @material-ui/icons
import Map from "@material-ui/icons/Map";
import Error from "@material-ui/icons/Error";
import Add from "@material-ui/icons/Add";
import Wifi from "@material-ui/icons/Wifi";
import LocalGasStation from "@material-ui/icons/LocalGasStation";
import StopRounded from "@material-ui/icons/StopRounded";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

// custom components
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import LoadingBackdrop from "components/CustomLoadingBackdrop/LoadingBackdrop.js";
import TankImageDialog from "components/TankImageDialog/TankImageDialog.js";
import AddContactDialog from "components/AddContactDialog/AddContactDialog.js";
import TankCommissionForm from "components/TankCommissionForm/TankCommissionForm.js";
import TankExceptionReport from "components/TankExceptionReport/TankExceptionReport.js";
import TankStatusReport from "components/TankStatusReport/TankStatusReport.js";
import SensorStatusReport from "components/SensorStatusReport/SensorStatusReport.js";

// tables
import HistoryDialogTable from "components/HistoryDialogTable/HistoryDialogTable.js";
import TankLateReport from "components/TankLateReport/TankLateReport.js";

// maps
import StatusMap from "components/StatusMap/StatusMap.js";
import ExceptionMap from "components/ExceptionMap/ExceptionMap.js";
import TerritoryMap from "components/TerritoryMap/TerritoryMap.js";

// styles
import homePageStyle from "assets/jss/material-kit-pro-react/views/homePageStyle.js";

import { history } from "../../helpers";
import { authService } from "../../services";

const useStyles = makeStyles(homePageStyle);

function CarouselItem(props) {
  return (
    <Grid container justify="center">
      <Paper>
        <CardMedia
          style={{ height: "300px", width: "400px" }}
          image={props.item}
        />
      </Paper>
    </Grid>
  );
}
CarouselItem.displayName = "CarouselItemDialog";
CarouselItem.propTypes = {
  item: PropTypes.string.isRequired,
};

export default function Tanks() {
  const [serviceTypes, setServiceTypes] = useState([]);
  const [territories, setTerritories] = useState([]);
  const [vendorCompanies, setVendorCompanies] = useState([]);
  const [statusRows, setStatusRows] = useState([]);
  const [allFacilities, setAllFacilities] = useState([]);
  const [company, setCompany] = useState("");
  const [selectedServiceType, setSelectedServiceType] = useState("");
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [selectedTerritory, setSelectedTerritory] = useState("");
  const [selectedMarker, setSelectedMarker] = useState({});
  const [infoBoxOpen, setInfoBoxOpen] = useState(false);
  const [infoBoxFacility, setInfoBoxFacility] = useState(0);
  const [statusView, setStatusView] = useState("list");
  const [exceptionView, setExceptionView] = useState("list");
  const [exceptionButtonText, setExceptionButtonText] = useState("Map");

  const [tankImages, setTankImages] = useState([]);
  const [tankImageDialogOpen, setTankImageDialogOpen] = useState(false);
  const [tankImageRow, setTankImageRow] = useState({});
  const [tankImageBackdropOpen, setTankImageBackdropOpen] = useState(false);

  const [addContactDialogOpen, setAddContactDialogOpen] = useState(false);
  const [addContactRow, setAddContactRow] = useState({});
  const [addContactBackdropOpen, setAddContactBackdropOpen] = useState(false);

  const [historyDialog, setHistoryDialog] = useState(false);
  const [historyDialogRows, setHistoryDialogRows] = useState([]);
  const [historyDialogFacility, setHistoryDialogFacility] = useState(0);
  const [historyDialogTankID, setHistoryDialogTankID] = useState(0);
  const [historyDialogTankName, setHistoryDialogTankName] = useState(0);
  const [historyDialogTankHeight, setHistoryDialogTankHeight] = useState(0);
  const [historyDialogCapacity, setHistoryDialogCapacity] = useState(0);
  const [historyDialogMeasurement, setHistoryDialogMeasurement] = useState("");
  const [newMeasurementDate, setNewMeasurementDate] = useState(new Date());
  const [newMeasurementOpen, setNewMeasurementOpen] = useState(false);
  const [newMeasurementReading, setNewMeasurementReading] = useState(0);
  const [newMeasurementError, setNewMeasurementError] = useState(false);
  const [newMeasurementErrorText, setNewMeasurementErrorText] = useState("");
  const [newMeasurementMessage, setNewMeasurementMessage] = useState("");
  const [eventTypes, setEventTypes] = useState([]);
  const [selectedEventType, setSelectedEventType] = useState(2);
  const [providers, setProviders] = useState([]);

  const [pickupVolume, setPickupVolume] = useState(0);
  const [pickupErrorText, setPickupErrorText] = useState("");
  const [pickupProvider, setPickupProvider] = useState("");
  const [pickupProviderErrorText, setPickupProviderErrorText] = useState("");

  const [backdropOpen, setBackdropOpen] = useState(false);
  const [dialogBackdropOpen, setDialogBackdropOpen] = useState(false);

  const toggleInfoBox = (company, facility) => {
    setInfoBoxFacility(`${company}-${facility}`);
    setInfoBoxOpen(facility === 0 ? false : true);
  };

  const handleNewMeasurementCancel = () => {
    setNewMeasurementReading();
    setNewMeasurementOpen(false);
  };

  const formatDate = (date) => {
    var m = new Date(date);
    var t = m.toTimeString().split(" ")[0];
    return (
      String(m.getFullYear()) +
      "-" +
      String(("0" + (m.getMonth() + 1)).slice(-2)) +
      "-" +
      String(m.getDate()) +
      " " +
      t
    );
  };

  const handleAddContactSubmit = (info, first, last, phone, email, title) => {
    const data = {
      company_id: company,
      cat_4: info.facility,
      first_name: first,
      last_name: last,
      phone,
      email,
      title,
      module: 2,
    };

    setAddContactBackdropOpen(true);

    const user = authService.getUser();
    const jwt = authService.getJWT();
    const headers = {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    };
    fetch(`${process.env.REACT_APP_API_URI}/parrtrax/contacts`, {
      headers,
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((resp) => {
        if (resp.status === 403) {
          history.push(user.homepage);
        } else if (resp.status !== 200) {
          alert("Failed to add contact");
          setAddContactBackdropOpen(false);
        }
        return resp.json();
      })
      .then((json) => {
        setAddContactBackdropOpen(false);
        if (!json.error) {
          alert("Successfully added contact.");
        } else {
          alert(`Failed to add contact - ${json.error}`);
        }
      });
  };

  const handleAddImageSubmit = (info, files) => {
    if (files.length === 0) {
      alert("At least one picture is required.");
      return;
    }

    setTankImageBackdropOpen(true);

    const formData = new FormData();
    formData.append("company", company);
    formData.append("facility", info.facility);
    formData.append("cat_4", info.facility);
    formData.append("site_id", info.site_id);
    let i = 0;
    files.forEach((file) => {
      formData.append(`myFile${i}`, file);
      i++;
    });

    const user = authService.getUser();
    const jwt = authService.getJWT();
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    fetch(`${process.env.REACT_APP_API_URI}/tank/images`, {
      headers,
      method: "POST",
      body: formData,
    })
      .then((resp) => {
        if (resp.status === 403) {
          history.push(user.homepage);
        } else if (resp.status !== 200) {
          alert("Failed to upload");
          setTankImageBackdropOpen(false);
        }
        return resp.json();
      })
      .then((json) => {
        setTankImageBackdropOpen(false);
        if (!json.error) {
          alert("Successfully uploaded.");
          refreshStatus();
        } else {
          alert(`Failed to upload - ${json.error}`);
        }
      });
  };

  const handleNewMeasurementAddButton = () => {
    if (selectedEventType === 2) {
      if (newMeasurementReading > historyDialogTankHeight) {
        setNewMeasurementError(true);
        setNewMeasurementErrorText(
          `cannot exceed ${historyDialogTankHeight} inches`
        );
        return;
      }
      if (newMeasurementReading <= 0) {
        setNewMeasurementError(true);
        setNewMeasurementErrorText("must be greater than 0");
        return;
      }
      if (
        newMeasurementReading <= historyDialogTankHeight &&
        newMeasurementReading >= 0
      ) {
        setNewMeasurementError(false);
        setNewMeasurementErrorText("");
        setNewMeasurementMessage("Is this correct?");
        setNewMeasurementOpen(true);
        return;
      }
    } else {
      if (Number(pickupVolume) > Number(historyDialogCapacity)) {
        setPickupErrorText(`cannot exceed ${historyDialogCapacity} gal`);
        return;
      }
      if (Number(pickupVolume) === 0) {
        setPickupErrorText("must be greater than 0");
        return;
      }
      if (!pickupProvider) {
        setPickupProviderErrorText("Required");
        return;
      }
      setPickupErrorText("");
      setPickupProviderErrorText("");
      setNewMeasurementMessage("Is this correct?");
      setNewMeasurementOpen(true);
    }
  };

  const handleNewMeasurementReadingSubmit = (
    company,
    cat_4,
    site_id,
    date_meas,
    measurement,
    eventType,
    pickupVolume,
    pickupProvider
  ) => {
    setDialogBackdropOpen(true);
    setNewMeasurementMessage("");

    const formData = new FormData();
    formData.append("company", company);
    formData.append("cat_4", cat_4);
    formData.append("site_id", site_id);
    formData.append("date_meas", formatDate(date_meas));
    formData.append("measurement", measurement);
    formData.append("event_type", eventType);
    formData.append("pickup_volume", pickupVolume);
    formData.append("pickup_provider", pickupProvider);

    const user = authService.getUser();
    const jwt = authService.getJWT();
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    fetch(`${process.env.REACT_APP_API_URI}/tank/measurement`, {
      headers,
      method: "POST",
      body: formData,
    })
      .then((resp) => {
        if (resp.status === 403) {
          history.push(user.homepage);
        } else if (resp.status !== 200) {
          setNewMeasurementMessage("An Error Occurred");
          setDialogBackdropOpen(false);
        }
        return resp.json();
      })
      .then((json) => {
        if (!json.error) {
          loadTankHistory(cat_4, site_id);
          refreshStatus();
          setNewMeasurementOpen(false);
          setNewMeasurementReading(0);
          setNewMeasurementDate(new Date());
          setPickupVolume(0);
          setSelectedEventType(2);
          setDialogBackdropOpen(false);
        } else {
          setNewMeasurementMessage("An Error Occurred");
          setDialogBackdropOpen(false);
        }
      });
  };

  const handleServiceTypeChange = (event) => {
    setBackdropOpen(true);
    setSelectedServiceType(event.target.value);
    const user = authService.getUser();
    const jwt = authService.getJWT();
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    fetch(
      `${process.env.REACT_APP_API_URI}/vendor/companies?type=${event.target.value}`,
      { headers }
    )
      .then((resp) => {
        if (resp.status === 403) {
          history.push(user.homepage);
        }
        return resp.json();
      })
      .then((json) => {
        setVendorCompanies(json);
        setBackdropOpen(false);
      });
  };

  const closestServiceProvider = (company_id, marker) => {
    const cat4 = marker.facility;
    setBackdropOpen(true);
    const user = authService.getUser();
    const jwt = authService.getJWT();
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    fetch(
      `${process.env.REACT_APP_API_URI}/parrtrax/closest_service_provider?company=${company_id}&cat_4=${cat4}&type=liquid`,
      { headers }
    )
      .then((resp) => {
        if (resp.status === 403) {
          history.push(user.homepage);
        }
        return resp.json();
      })
      .then((json) => {
        setSelectedMarker(marker);
        setSelectedTerritory(json);
        toggleInfoBox(0);
        setBackdropOpen(false);
      });
  };

  const refreshStatus = () => {
    let ses = authService.getSes();
    const user = authService.getUser();
    const jwt = authService.getJWT();
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    fetch(
      `${process.env.REACT_APP_API_URI}/tank/facility_status?company=${ses.selected_company}`,
      { headers }
    )
      .then((resp) => {
        if (resp.status === 403) {
          history.push(user.homepage);
        }
        return resp.json();
      })
      .then((json) => {
        setStatusRows(json);
      });
  };

  const handleHistoryDialogClose = () => {
    setHistoryDialog(false);
  };

  const handleCompanySelect = (e) => {
    setCompany(e);
    const user = authService.getUser();

    const perms = user.company_permissions[e].filter((item) => {
      return item.route === "/tanks";
    });
    if (perms.length === 0) {
      history.push(user.homepage);
    }

    setTerritories([]);
    setSelectedTerritory({});
  };

  const loadProviders = (facility) => {
    const user = authService.getUser();

    const jwt = authService.getJWT();
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    fetch(
      `${process.env.REACT_APP_API_URI}/parrtrax/service_providers?company_id=${company}&cat_4=${facility}&service_type=2`,
      { headers }
    )
      .then((resp) => {
        if (resp.status === 403) {
          history.push(user.homepage);
        }
        return resp.json();
      })
      .then((json) => {
        setProviders(json);
      });
  };

  const loadTankHistory = (facility, site_id) => {
    const user = authService.getUser();
    let ses = authService.getSes();
    setCompany(ses.selected_company);
    setNewMeasurementOpen(false);

    const jwt = authService.getJWT();
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const startStr = format(new Date(year, month - 6, day), "yyyy-MM-dd");
    const endStr = format(new Date(year, month, day), "yyyy-MM-dd");

    fetch(
      `${process.env.REACT_APP_API_URI}/tank/history?cat_4=${facility}&start=${startStr}&end=${endStr}&company=${ses.selected_company}`,
      { headers }
    )
      .then((resp) => {
        if (resp.status === 403) {
          history.push(user.homepage);
        }
        return resp.json();
      })
      .then((json) => {
        json.reverse();
        const tableRows = [];
        json.forEach((row) => {
          const data = [
            {
              component: "th",
              scope: "row",
              className: [
                classes.table_body_cell,
                classes.table_text_small,
                classes.table_text_nowrap,
              ],
              value: row.date,
            },
            {
              className: [classes.table_body_cell],
              value: row.oil_level.toLocaleString(),
            },
            {
              className: [classes.table_body_cell],
              value: `${row.tank_reading} %`,
              color: row.color,
            },
          ];
          tableRows.push({ data });
        });
        setHistoryDialogRows(tableRows);
      });

    fetch(
      `${process.env.REACT_APP_API_URI}/tank/tanks?company=${ses.selected_company}&cat_4=${facility}`,
      { headers }
    )
      .then((resp) => {
        if (resp.status === 403) {
          history.push(user.homepage);
        }
        return resp.json();
      })
      .then((json) => {
        if (Array.isArray(json)) {
          var tank = json.find((t) => t.site_id === site_id);
          setHistoryDialogTankHeight(tank.display_height_inch);
        }
      });
  };

  const handleHistoryDialogOpen = (
    facility,
    site_id,
    tank_name,
    capacity,
    measurement,
    images
  ) => {
    setDialogBackdropOpen(true);
    setHistoryDialogRows([]);
    loadTankHistory(facility, site_id);
    loadProviders(facility);
    setHistoryDialogFacility(facility);
    setHistoryDialogTankID(site_id);
    setHistoryDialogTankName(tank_name);
    setHistoryDialogCapacity(capacity);
    setHistoryDialogMeasurement(measurement);
    setTankImages(images);
    setHistoryDialog(true);
    setDialogBackdropOpen(false);
  };

  /* eslint-disable */
  const classes = useStyles();
  React.useEffect(() => {
    const loadData = () => {
      const user = authService.getUser();
      let ses = authService.getSes();
      setCompany(ses.selected_company);

      const jwt = authService.getJWT();
      const headers = {
        Authorization: `Bearer ${jwt}`,
      };
      refreshStatus();
      fetch(
        `${process.env.REACT_APP_API_URI}/tank/event_types?company=${ses.selected_company}`,
        { headers }
      )
        .then((resp) => {
          if (resp.status === 403) {
            history.push(user.homepage);
          }
          return resp.json();
        })
        .then((json) => {
          setEventTypes(json);
        });

      fetch(
        `${process.env.REACT_APP_API_URI}/parrtrax/facilities?company=${ses.selected_company}`,
        { headers }
      )
        .then((resp) => {
          if (resp.status === 403) {
            history.push(user.homepage);
          }
          return resp.json();
        })
        .then((json) => {
          setAllFacilities(json);
        });

      fetch(
        `${process.env.REACT_APP_API_URI}/vendor/service_types?type=liquid`,
        {
          headers,
        }
      )
        .then((resp) => {
          if (resp.status === 403) {
            history.push(user.homepage);
          }
          return resp.json();
        })
        .then((json) => {
          setServiceTypes(json);
        });

      if (selectedVendors.length && selectedServiceType) {
        const vendors = [];
        selectedVendors.forEach((k) => {
          vendors.push(k.company_id);
        });
        fetch(
          `${
            process.env.REACT_APP_API_URI
          }/vendor/territories?companies=${vendors.join(
            ";"
          )}&service_type=${selectedServiceType}`,
          { headers }
        )
          .then((resp) => {
            if (resp.status === 403) {
              history.push(user.homepage);
            }
            return resp.json();
          })
          .then((json) => {
            console.log(json);
            setTerritories(json);
          });
      }

      if (!selectedVendors.length) {
        setTerritories([]);
      }
    };

    authService.accessCheck(loadData, []);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, [
    company,
    selectedVendors,
    selectedServiceType,
  ]);
  /* eslint-enable */

  return (
    <div>
      <Header
        brand="ParrTRAX"
        links={
          <HeaderLinks
            dropdownHoverColor="success"
            handleCompanySelect={handleCompanySelect}
          />
        }
        fixed
        color="primary"
        className={classes.title}
      />

      <div className={classes.spacer} justify="center"></div>
      <div className={classes.container}>
        <CustomTabs
          headerColor="primary"
          tabs={[
            {
              tabName: "Status",
              tabIcon: LocalGasStation,
              tabContent: (
                <div className={classes.textCenter}>
                  {statusView === "list" && (
                    <div id="status_list">
                      <h3 style={{ fontWeight: "bold" }}>
                        Current Tank Volumes
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            float: "right",
                          }}
                          onClick={() => setStatusView("map")}
                        >
                          Map
                        </Button>
                      </h3>
                      <TankStatusReport
                        rows={statusRows}
                        handleHistoryDialogOpen={handleHistoryDialogOpen}
                        handleTankImageDialogOpen={(row) => {
                          setTankImageRow(row);
                          setTankImageDialogOpen(true);
                        }}
                        handleAddContactDialogOpen={(row) => {
                          setAddContactRow(row);
                          setAddContactDialogOpen(true);
                        }}
                        refresh={refreshStatus}
                      />
                    </div>
                  )}
                  {statusView === "map" && (
                    <div id="status_map">
                      <div className={classes.bigMap}>
                        <div className={classes.textCenter}>
                          <h3 style={{ fontWeight: "bold" }}>
                            Facilities Map
                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                float: "right",
                              }}
                              onClick={() => setStatusView("list")}
                            >
                              List
                            </Button>
                          </h3>
                        </div>
                        <StatusMap
                          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API}`}
                          loadingElement={<div style={{ height: "100%" }} />}
                          containerElement={
                            <div
                              style={{
                                height: "500px",
                                borderRadius: "6px",
                                overflow: "hidden",
                              }}
                            />
                          }
                          mapElement={<div style={{ height: `100%` }} />}
                          markers={statusRows}
                          clickHandler={toggleInfoBox}
                          infoBoxOpen={infoBoxOpen}
                          facility={infoBoxFacility}
                          company={company}
                        />
                      </div>
                      <div id="map_legend" className={classes.mapLegend}>
                        <StopRounded
                          style={{ verticalAlign: "bottom", color: "green" }}
                        />
                        Green&nbsp;&nbsp;&nbsp;
                        <StopRounded
                          style={{ verticalAlign: "bottom", color: "orange" }}
                        />
                        Orange&nbsp;&nbsp;&nbsp;
                        <StopRounded
                          style={{ verticalAlign: "bottom", color: "red" }}
                        />
                        Red&nbsp;&nbsp;&nbsp;
                      </div>
                    </div>
                  )}
                </div>
              ),
            },
            {
              tabName: "Vendor Territories",
              tabIcon: Map,
              tabContent: (
                <div className={classes.textCenter}>
                  <h3 style={{ fontWeight: "bold" }}>Vendor Territories</h3>
                  <Grid container justify="space-around">
                    <FormControl
                      className={classes.formControl}
                      style={{ minWidth: "30%", margin: 10 }}
                    >
                      <InputLabel id="service-type-label">
                        Service Type
                      </InputLabel>
                      <Select
                        labelId="service-type-label"
                        id="service-type-select"
                        onChange={handleServiceTypeChange}
                        value={selectedServiceType}
                      >
                        {serviceTypes.map((serviceType) => (
                          <MenuItem key={serviceType.id} value={serviceType.id}>
                            {serviceType.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      className={classes.formControl}
                      style={{ minWidth: "30%", margin: 10 }}
                    >
                      <Autocomplete
                        multiple
                        id="vendors"
                        options={vendorCompanies}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => setSelectedVendors(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Vendor(s)"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <TerritoryMap
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API}`}
                    loadingElement={<div style={{ height: "100%" }} />}
                    territories={territories}
                    clickHandler={toggleInfoBox}
                    infoBoxOpen={infoBoxOpen}
                    facility={infoBoxFacility}
                    company={company}
                    markers={allFacilities}
                    containerElement={
                      <div
                        style={{
                          height: "500px",
                          borderRadius: "6px",
                          overflow: "hidden",
                        }}
                      />
                    }
                    mapElement={<div style={{ height: `100%` }} />}
                  />
                </div>
              ),
            },
            {
              tabName: "Exceptions",
              tabIcon: Error,
              tabContent: (
                <div style={{ textAlign: "center" }}>
                  <h3 style={{ fontWeight: "bold" }}>
                    Exceptions
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        float: "right",
                      }}
                      onClick={() => {
                        if (exceptionView === "list") {
                          setExceptionView("map");
                          setExceptionButtonText("List");
                        } else {
                          setExceptionView("list");
                          setExceptionButtonText("Map");
                        }
                      }}
                    >
                      {exceptionButtonText}
                    </Button>
                  </h3>
                  {exceptionView === "list" && (
                    <TankExceptionReport
                      company={company}
                      rows={statusRows}
                    ></TankExceptionReport>
                  )}
                  {exceptionView === "map" && (
                    <div>
                      <ExceptionMap
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API}`}
                        loadingElement={<div style={{ height: "100%" }} />}
                        containerElement={
                          <div
                            style={{
                              height: "500px",
                              borderRadius: "6px",
                              overflow: "hidden",
                            }}
                          />
                        }
                        mapElement={<div style={{ height: `100%` }} />}
                        markers={statusRows.filter(
                          (row) => row.alerting === true
                        )}
                        clickHandler={toggleInfoBox}
                        infoBoxOpen={infoBoxOpen}
                        facility={infoBoxFacility}
                        company={company}
                        closestServiceProvider={closestServiceProvider}
                        selectedTerritory={selectedTerritory}
                        selectedMarker={selectedMarker}
                      />
                      <div id="map_legend" className={classes.mapLegend}>
                        <StopRounded
                          style={{ verticalAlign: "bottom", color: "orange" }}
                        />
                        Action Next Month&nbsp;&nbsp;&nbsp;
                        <StopRounded
                          style={{ verticalAlign: "bottom", color: "red" }}
                        />
                        Action ASAP&nbsp;&nbsp;&nbsp;
                        <StopRounded
                          style={{ verticalAlign: "bottom", color: "blue" }}
                        />
                        Service Provider&nbsp;&nbsp;&nbsp;
                      </div>
                    </div>
                  )}
                </div>
              ),
            },
            {
              tabName: "Due",
              tabIcon: Error,
              tabContent: (
                <div className={classes.textCenter}>
                  <h3 style={{ fontWeight: "bold" }}>Due</h3>
                  <TankLateReport />
                </div>
              ),
            },
            {
              tabName: "Add Tank",
              tabIcon: Add,
              tabContent: (
                <TankCommissionForm
                  facilities={allFacilities}
                  company={company}
                  refresh={refreshStatus}
                ></TankCommissionForm>
              ),
            },
            {
              tabName: "Sensors",
              tabIcon: Wifi,
              tabContent: (
                <div className={classes.textCenter}>
                  <div id="status_list">
                    <h3 style={{ fontWeight: "bold" }}>Sensors</h3>
                    <SensorStatusReport
                      rows={statusRows}
                      handleHistoryDialogOpen={handleHistoryDialogOpen}
                      refresh={refreshStatus}
                    />
                  </div>
                </div>
              ),
            },
          ]}
        />
        <LoadingBackdrop open={backdropOpen} />
      </div>

      <div className={[classes.textCenter, classes.darkText].join(" ")}>
        <h3>Need Help?</h3>
        Call Us <span className={classes.boldText}>931-363-5379</span>
      </div>
      <TankImageDialog
        row={tankImageRow}
        open={tankImageDialogOpen}
        onClose={() => setTankImageDialogOpen(false)}
        onSubmit={handleAddImageSubmit}
        backdropOpen={tankImageBackdropOpen}
      ></TankImageDialog>
      <AddContactDialog
        row={addContactRow}
        open={addContactDialogOpen}
        onClose={() => setAddContactDialogOpen(false)}
        onSubmit={handleAddContactSubmit}
        backdropOpen={addContactBackdropOpen}
      ></AddContactDialog>
      <Dialog
        open={historyDialog}
        onClose={handleHistoryDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Facility {historyDialogFacility}
        </DialogTitle>
        <DialogContent>
          {tankImages.length > 0 && (
            <Carousel autoPlay={false} navButtonsAlwaysVisible={false}>
              {tankImages.map((image, index) => (
                <CarouselItem key={index} item={image} />
              ))}
            </Carousel>
          )}
          <div className={classes.historyDialogSpecs}>
            Tank Name: {historyDialogTankName}
            <br />
            Liquid Type: NA
            <br />
            Tank Capacity: {historyDialogCapacity}
            <br />
            Tank Height: {historyDialogTankHeight} in.
            <br />
            Measurement Type: {historyDialogMeasurement}
          </div>
          <div
            id="newmeasurementinput"
            style={{
              border: "1px solid #cccccc",
              borderRadius: "5px",
              margin: "4px 0px",
              padding: "4px",
              backgroundColor: "#e4ebf5",
            }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                variant="inline"
                format="yyyy-MM-dd HH:mm:ss"
                margin="normal"
                id="newmeasurement-datetime-picker-inline"
                label="Date/Time"
                maxDate={new Date()}
                value={newMeasurementDate}
                onChange={(date) => setNewMeasurementDate(date)}
                autoOk={true}
                fullWidth
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
            <br />
            <InputLabel id="event-type-label">Event Type</InputLabel>
            <Select
              labelId="event-type-label"
              id="event-type-select"
              onChange={(event) => {
                console.log(event.target.value);
                setSelectedEventType(event.target.value);
              }}
              value={selectedEventType}
              fullWidth
            >
              {eventTypes.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
            <br />
            {selectedEventType === 2 && (
              <TextField
                margin="dense"
                id="new-measurement"
                name="new-measurement"
                label="Liquid Height From Bottom [inches]"
                type="number"
                error={newMeasurementError}
                helperText={newMeasurementErrorText}
                fullWidth
                value={newMeasurementReading || ""}
                onFocus={() => setNewMeasurementOpen(false)}
                onChange={(reading) =>
                  setNewMeasurementReading(reading.target.value)
                }
              />
            )}
            {selectedEventType !== 2 && (
              <div>
                <TextField
                  margin="dense"
                  id="volume-removed"
                  name="volume-removed"
                  label="Volume Removed [gal]"
                  type="number"
                  error={pickupErrorText !== ""}
                  helperText={pickupErrorText}
                  fullWidth
                  value={pickupVolume || ""}
                  onChange={(reading) => setPickupVolume(reading.target.value)}
                />
                <Autocomplete
                  id="providers"
                  options={providers}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, value) => setPickupProvider(value.facility)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={pickupProviderErrorText !== ""}
                      helperText={pickupProviderErrorText}
                      variant="standard"
                      label="Provider"
                    />
                  )}
                />
              </div>
            )}
            <Collapse in={!newMeasurementOpen}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#3e61a0",
                  width: "100%",
                  color: "#ffffff",
                  marginTop: "10px",
                }}
                onClick={handleNewMeasurementAddButton}
              >
                {selectedEventType === 2
                  ? "Add Measurement"
                  : "Add Pickup Event"}
              </Button>
              <br />
            </Collapse>
            <Collapse in={newMeasurementOpen}>
              <h3
                style={{ display: "block", width: "100%", textAlign: "center" }}
              >
                {newMeasurementMessage}
              </h3>
              <Button
                style={{ margin: "2px" }}
                onClick={handleNewMeasurementCancel}
              >
                Cancel
              </Button>
              <Button
                style={{
                  backgroundColor: "#3e61a0",
                  color: "#ffffff",
                  margin: "2px",
                  float: "right",
                }}
                onClick={() =>
                  handleNewMeasurementReadingSubmit(
                    company,
                    historyDialogFacility,
                    historyDialogTankID,
                    newMeasurementDate,
                    newMeasurementReading,
                    selectedEventType,
                    pickupVolume,
                    pickupProvider
                  )
                }
              >
                Confirm
              </Button>
            </Collapse>
            <LoadingBackdrop open={dialogBackdropOpen} />
          </div>
          <HistoryDialogTable
            historyDialogRows={historyDialogRows}
            headers={["Date", "Volume [Gal]", "Percent Full"]}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleHistoryDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Footer
        content={
          <div>
            <div className={classes.left}>
              &copy; {1900 + new Date().getYear()} , Parr Inc.
            </div>
            <div className={classes.right}>
              Version: {process.env.REACT_APP_VERSION}
            </div>
          </div>
        }
      />
    </div>
  );
}
