import React from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  KmlLayer,
} from "react-google-maps";
import { InfoBox } from "react-google-maps/lib/components/addons/InfoBox";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { markerPopupRow, markerPopupCell } from "assets/jss/mapStyle.js";
import { mapMarkerIcon } from "../../helpers";

const TerritoryMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={4.3}
      defaultCenter={{ lat: 39.8282, lng: -98.5795 }}
      defaultOptions={{
        scrollwheel: true,
        zoomControl: true,
      }}
    >
      {props.markers.map((row) => (
        <Marker
          key={row.facility}
          position={{ lat: row.lat, lng: row.lon }}
          icon={mapMarkerIcon("00FF00")}
          onClick={() =>
            props.clickHandler(props.company, parseInt(row.facility))
          }
        >
          {props.infoBoxOpen &&
            `${props.company}-${row.facility}` === props.facility && (
              <InfoBox
                options={{ closeBoxURL: ``, enableEventPropagation: true }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    opacity: 1,
                    borderRadius: "0px 10px 10px 10px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      position: "absolute",
                    }}
                  >
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => props.clickHandler(null, 0)}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  </div>
                  <div style={{ padding: "15px 10px 10px 10px" }}>
                    <table style={{ marginTop: "10px" }}>
                      <tbody>
                        <tr style={markerPopupRow}>
                          <td style={markerPopupCell}>Facility</td>
                          <td
                            style={markerPopupCell}
                          >{`${props.company} ${row.facility}`}</td>
                        </tr>
                        <tr style={markerPopupRow}>
                          <td style={markerPopupCell}>Location</td>
                          <td
                            style={markerPopupCell}
                          >{`${row.city}, ${row.state}`}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </InfoBox>
            )}
        </Marker>
      ))}
      {props.territories.map((row) => (
        <>
          <Marker
            key={`${row.company_id}-${row.cat_4}`}
            position={{ lat: row.lat, lng: row.lon }}
            icon={mapMarkerIcon("0000FF")}
            onClick={() =>
              props.clickHandler(row.company_id, parseInt(row.cat_4))
            }
          >
            {props.infoBoxOpen &&
              `${row.company_id}-${row.cat_4}` === props.facility && (
                <InfoBox
                  options={{ closeBoxURL: ``, enableEventPropagation: true }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      opacity: 1,
                      borderRadius: "0px 10px 10px 10px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        position: "absolute",
                      }}
                    >
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => props.clickHandler(null, 0)}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    </div>
                    <div style={{ padding: "15px 10px 10px 10px" }}>
                      <table style={{ marginTop: "10px" }}>
                        <tbody>
                          <tr style={markerPopupRow}>
                            <td style={markerPopupCell}>Facility</td>
                            <td
                              style={markerPopupCell}
                            >{`${row.company_id} ${row.cat_4}`}</td>
                          </tr>
                          <tr style={markerPopupRow}>
                            <td style={markerPopupCell}>Phone</td>
                            <td style={markerPopupCell}>{row.phone}</td>
                          </tr>
                          <tr style={markerPopupRow}>
                            <td style={markerPopupCell}>Address</td>
                            <td style={markerPopupCell}>
                              {row.address}
                              <br></br>
                              {row.city}, {row.state} {row.zip}
                            </td>
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                          </tr>
                          <tr style={markerPopupRow}>
                            <td
                              style={{ textAlign: "left", cursor: "pointer" }}
                            >
                              <a
                                href={row.website}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Facility Website
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </InfoBox>
              )}
          </Marker>
          <KmlLayer
            key={row.territory}
            url={row.territory}
            options={{ preserveViewport: true, suppressInfoWindows: true }}
          />
        </>
      ))}
    </GoogleMap>
  ))
);

export default TerritoryMap;
