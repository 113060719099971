import React, { useState } from "react";
import PropTypes from "prop-types";

import { useConfirmDialog } from "react-mui-confirm";

import Button from "@material-ui/core/Button";

import StyledDataGrid from "components/StyledDataGrid/StyledDataGrid.js";
import TankHistoryDialog from "components/TankHistoryDialog/TankHistoryDialog.js";
import SensorStatusDialog from "components/SensorStatusDialog/SensorStatusDialog.js";
import AddSensorDialog from "components/AddSensorDialog/AddSensorDialog.js";
import LoadingBackdrop from "components/CustomLoadingBackdrop/LoadingBackdrop.js";

import { authService } from "../../services";
import { history } from "../../helpers";

function SensorStatusReport(props) {
  const confirm = useConfirmDialog();

  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [historyRow, setHistoryRow] = useState({});

  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [statusRow, setStatusRow] = useState({});

  const [sensorDialogOpen, setSensorDialogOpen] = useState(false);
  const [sensorRow, setSensorRow] = useState({});
  const [availableSensors, setAvailableSensors] = useState([]);

  const [backdropOpen, setBackdropOpen] = useState(false);

  const jwt = authService.getJWT();
  const ses = authService.getSes();
  const user = authService.getUser();
  const getAvailableSensors = () => {
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    fetch(
      `${process.env.REACT_APP_API_URI}/tank/enevo/sensors?available=true&company=${ses.selected_company}`,
      { headers }
    )
      .then((resp) => {
        if (resp.status === 403) {
          history.push(user.homepage);
          resp.json = [];
        }
        return resp.json();
      })
      .then((json) => {
        setAvailableSensors(json);
      });
  };

  const detachSensor = (row) => {
    confirm({
      title: "Are you sure you wish to remove the sensor?",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      onConfirm: () => {
        setBackdropOpen(true);
        const data = {
          company: ses.selected_company,
          cat_4: row.facility,
          tank_id: row.id,
        };
        const headers = {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        };
        fetch(`${process.env.REACT_APP_API_URI}/tank/enevo/detach_sensor`, {
          headers,
          method: "DELETE",
          body: JSON.stringify(data),
        })
          .then((resp) => {
            if (resp.status === 403) {
              setBackdropOpen(false);
              history.push(user.homepage);
              resp.json = [];
            }
            return resp.json();
          })
          .then(() => {
            alert("Successfully removed sensor.");
            props.refresh();
            getAvailableSensors();
            setBackdropOpen(false);
          });
      },
    });
  };

  /* eslint-disable */
  React.useEffect(() => {
    getAvailableSensors();
  }, []);
  /* eslint-enable */

  const columns = [
    {
      field: "facility",
      headerName: "Facility",
      flex: 75,
      minWidth: 110,
    },
    {
      field: "city",
      headerName: "City",
      flex: 100,
      minWidth: 150,
    },
    {
      field: "state",
      headerName: "State",
      flex: 75,
      minWidth: 100,
    },
    {
      field: "tank_name",
      headerName: "Tank Name",
      flex: 125,
      minWidth: 200,
    },
    {
      field: "color",
      headerName: "History",
      flex: 75,
      minWidth: 75,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const historyButton = (
          <Button
            variant="contained"
            style={{ backgroundColor: "#bfb4a0" }}
            size="small"
            onClick={() => {
              setHistoryRow(params.row);
              setHistoryDialogOpen(true);
            }}
          >
            History
          </Button>
        );
        return historyButton;
      },
    },
    {
      field: "addSensor",
      headerName: "Add",
      flex: 50,
      minWidth: 50,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const addButton = (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setSensorRow(params.row);
              setSensorDialogOpen(true);
            }}
            disabled={params.row.sensor_vendor === "Enevo"}
          >
            Add
          </Button>
        );
        return addButton;
      },
    },
    {
      field: "removeSensor",
      headerName: "Remove",
      flex: 50,
      minWidth: 50,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      renderCell: (params) => {
        const removeButton = (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => detachSensor(params.row)}
            disabled={params.row.sensor_vendor !== "Enevo"}
          >
            Remove
          </Button>
        );
        return removeButton;
      },
    },
    {
      field: "status",
      headerName: "Sensor",
      flex: 75,
      minWidth: 75,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        let disabled = false;
        let warning = false;
        if (params.row.sensor_vendor !== "Enevo") {
          disabled = true;
        } else if (
          params.row.sensor_signal < 30 ||
          params.row.sensor_battery < 30
        ) {
          warning = true;
        }
        const statusButton = (
          <Button
            variant="contained"
            size="small"
            style={{
              backgroundColor:
                !disabled && !warning
                  ? "lightgreen"
                  : !disabled && warning
                  ? "#ffa500"
                  : "",
            }}
            onClick={() => {
              console.log(params.row.signal);
              setStatusRow(params.row);
              setStatusDialogOpen(true);
            }}
            disabled={params.row.sensor_vendor !== "Enevo"}
          >
            State
          </Button>
        );
        return statusButton;
      },
    },
  ];

  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ height: 600, width: "100%" }}>
        <StyledDataGrid
          rows={props.rows}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
        />
      </div>
      <TankHistoryDialog
        open={historyDialogOpen}
        row={historyRow}
        onClose={() => setHistoryDialogOpen(false)}
      />
      <SensorStatusDialog
        open={statusDialogOpen}
        row={statusRow}
        onClose={() => setStatusDialogOpen(false)}
      />
      <AddSensorDialog
        open={sensorDialogOpen}
        row={sensorRow}
        onClose={() => setSensorDialogOpen(false)}
        onSubmit={() => {
          getAvailableSensors();
          props.refresh();
        }}
        sensors={availableSensors}
      />
      <LoadingBackdrop open={backdropOpen} />
    </div>
  );
}

SensorStatusReport.displayName = "SensorStatusReport";
SensorStatusReport.propTypes = {
  rows: PropTypes.array.isRequired,
  handleHistoryDialogOpen: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default SensorStatusReport;
