import { withStyles } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";

const StyledDataGrid = withStyles({
  root: {
    "& .MuiDataGrid-columnsContainer": {
      maxHeight: "35px !important",
      minHeight: "35px !important",
      lineHeight: "35px !important",
    },
    "& .MuiDataGrid-columnsHeaderTitleContainer": {
      maxHeight: "35px !important",
      minHeight: "35px !important",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      maxHeight: "35px !important",
      minHeight: "35px !important",
      overflow: "unset !important",
    },
    "& .MuiDataGrid-iconButtonContainer": {
      maxHeight: "35px !important",
      minHeight: "35px !important",
    },
    "& .MuiDataGrid-window": {
      top: "35px !important",
    },
    "& .MuiDataGrid-columnSeparator": {
      maxHeight: "35px !important",
      minHeight: "35px !important",
    },
    "& .MuiDataGrid-columnHeaderDraggableContainer": {
      maxHeight: "35px !important",
      minHeight: "35px !important",
    },
    "& .MuiDataGrid-columnHeader": {
      maxHeight: "35px !important",
      minHeight: "35px !important",
      color: "white",
      backgroundColor: "#949494",
    },
    "& .MuiDataGrid-row": {
      minHeight: "35px !important",
      lineHeight: "35px !important",
      backgroundColor: "#ededed",
      maxHeight: "fit-content!important",
    },
    "& .MuiDataGrid-cell": {
      maxHeight: "35px !important",
      minHeight: "35px !important",
      lineHeight: "35px !important",
    },
    "& .MuiDataGrid-filterIcon": {
      color: "white",
    },
    "& .MuiDataGrid-menuIcon": {
      color: "white",
    },
    "& .MuiDataGrid-menuIconButton": {
      color: "white",
    },
    "& .MuiDataGrid-sortIcon": {
      color: "white",
    },
    "& .MuiDataGrid-columnHeaderTitle, & .MuiDataGrid-cell": {
      textOverflow: "unset",
      whiteSpace: "normal",
      lineHeight: "1.2!important",
      maxHeight: "fit-content!important",
      minHeight: "auto!important",
      height: "auto",
      display: "flex",
      alignItems: "center",
      alignSelf: "stretch",

      "& > div": {
        maxHeight: "inherit",
        width: "100%",
        whiteSpace: "initial",
        lineHeight: "1",
      },
    },
    "& .MuiDataGrid-row.Mui-odd": {
      backgroundColor: "white",
    },
  },
})(DataGrid);

export default StyledDataGrid;
