import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Image from "@material-ui/icons/Image";

import { openInNewTab } from "../../helpers";
import homePageStyle from "assets/jss/material-kit-pro-react/views/homePageStyle.js";

const HistoryTable = React.forwardRef((props, ref) => {
  const useStyles = makeStyles(homePageStyle);
  const classes = useStyles();
  return (
    <TableContainer
      component={Paper}
      ref={ref}
      style={{ backgroundColor: "#dadada" }}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead className={classes.table_header}>
          <TableRow className={classes.table_header_row}>
            {props.headers.map((label) => (
              <TableCell key={label} className={classes.table_header_cell}>
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.table_body}>
          {props.historyRows.map((row, index) => (
            <TableRow
              key={index}
              className={classes.table_body_row}
              onClick={() => row.clickHandler(row)}
            >
              {row.data.map((data, index) => (
                <TableCell
                  component={data.component}
                  scope={data.scope}
                  className={data.className.join(" ")}
                  style={{ backgroundColor: data.color }}
                  key={index}
                >
                  {data.value}
                </TableCell>
              ))}
              {row.docLink ? (
                <TableCell
                  className={classes.table_body_cell}
                  style={{ padding: "0", textAlign: "center" }}
                >
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => {
                      openInNewTab(row.docLink);
                    }}
                  >
                    <Image />
                  </IconButton>
                </TableCell>
              ) : (
                <TableCell
                  className={classes.table_body_cell}
                  style={{ padding: "0", textAlign: "center" }}
                ></TableCell>
              )}
              {row.moreInfo && (
                <TableCell className={classes.table_body_cell}>
                  <Button
                    variant="outlined"
                    style={{ backgroundColor: "#bdd0de" }}
                  >
                    View
                  </Button>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});
HistoryTable.displayName = "HistoryTable";
HistoryTable.propTypes = {
  historyRows: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
};

export default HistoryTable;
