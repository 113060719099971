import React from "react";
import PropTypes from "prop-types";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

function SensorStatusDialog(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="xs"
    >
      <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
        Current Status
      </DialogTitle>
      <div>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Provisioned
                </TableCell>
                <TableCell align="right">
                  {props.row.sensor_provisioned}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Last Connection
                </TableCell>
                <TableCell align="right">
                  {props.row.sensor_last_connection}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Battery Level
                </TableCell>
                <TableCell align="right">
                  {props.row.sensor_battery} %
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Signal Level
                </TableCell>
                <TableCell align="right">{props.row.sensor_signal} %</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SensorStatusDialog.displayName = "SensorStatusDialog";
SensorStatusDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
};

export default SensorStatusDialog;
