/*!

=========================================================
* Material Kit PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch, Redirect } from "react-router";
import { HashRouter } from "react-router-dom";
import { ConfirmDialogProvider } from "react-mui-confirm";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles"

import { history } from "helpers";
import { authService } from "services";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";

// pages for this product
import Home from "views/Home/Home.js";
import Tanks from "views/Tanks/Tanks.js";
import Items from "views/Items/Items.js";
import Stick from "views/Stick/Stick.js";
import Permits from "views/Permits/Permits.js";
import WTI from "views/WTI/WTI.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import ResetPassword from "views/ResetPassword/ResetPassword.js";
import VendorHomePage from "views/Vendor/VendorHomePage.js";
import Vendors from "views/Vendor/Vendors.js";
import Gallery from "views/Gallery/Gallery.js";
import Users from "views/Users/Users.js";

const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0976B4"
    }
  },
});

ReactDOM.render(
  <MuiThemeProvider theme={customTheme}>
    <ConfirmDialogProvider>
      <HashRouter history={history}>
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/reset_password" component={ResetPassword} />
          <Route path="/tanks" component={Tanks} />
          <Route path="/home" component={Home} />
          <Route path="/events" component={VendorHomePage} />
          <Route path="/stick" component={Stick} />
          <Route path="/items" component={Items} />
          <Route path="/permits" component={Permits} />
          <Route path="/wti" component={WTI} />
          <Route path="/vendors" component={Vendors} />
          <Route path="/gallery" component={Gallery} />
          <Route path="/users" component={Users} />
          <Route path="/">
            {authService.getUser() ? (
              <Redirect to={authService.getUser().homepage} />
            ) : (
              <LoginPage />
            )}
          </Route>
        </Switch>
      </HashRouter>
    </ConfirmDialogProvider>
  </MuiThemeProvider>,
  document.getElementById("root")
);
