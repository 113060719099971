import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import homePageStyle from "assets/jss/material-kit-pro-react/views/homePageStyle.js";

const HistoryDialogTable = React.forwardRef((props, ref) => {
  const useStyles = makeStyles(homePageStyle);
  const classes = useStyles();
  return (
    <TableContainer component={Paper} ref={ref}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead className={classes.table_header}>
          <TableRow className={classes.table_header_row}>
            {props.headers.map((label) => (
              <TableCell key={label} className={classes.table_header_cell}>
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.table_body}>
          {props.historyDialogRows.map((row, index) => (
            <TableRow key={index} className={classes.table_body_row}>
              {row.data.map((data, index) => (
                <TableCell
                  component={data.component}
                  scope={data.scope}
                  className={data.className.join(" ")}
                  style={{ backgroundColor: data.color }}
                  key={index}
                >
                  {data.value}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});
HistoryDialogTable.displayName = "HistoryDialogTable";
HistoryDialogTable.propTypes = {
  historyDialogRows: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
};

export default HistoryDialogTable;
