import React, { useState } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

import { DropzoneArea } from "material-ui-dropzone";
import LoadingBackdrop from "components/CustomLoadingBackdrop/LoadingBackdrop.js";

import homePageStyle from "assets/jss/material-kit-pro-react/views/homePageStyle.js";
const useStyles = makeStyles(homePageStyle);

function TankImageDialog(props) {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [submits, setSubmits] = useState(0);
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add Tank Image</DialogTitle>
      <div
        className={classes.historyDialogSpecs}
        style={{ marginLeft: "24px" }}
      >
        Facility: {props.row.facility}
        <br />
        Tank Name: {props.row.tank_name}
      </div>
      <div style={{ marginTop: "20px", padding: "20px" }}>
        <form className={classes.root} noValidate autoComplete="off">
          <DropzoneArea
            onChange={(files) => setFiles(files)}
            key={submits}
            dropzoneText="Click on icon below to upload pictures"
          />
          <Button
            variant="contained"
            color="primary"
            style={{ width: "100%", marginTop: "10px" }}
            onClick={() => {
              setSubmits(submits + 1);
              props.onSubmit(props.row, files);
            }}
          >
            Add Image(s)
          </Button>
        </form>
      </div>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Close
        </Button>
      </DialogActions>
      <LoadingBackdrop open={props.backdropOpen} />
    </Dialog>
  );
}

TankImageDialog.displayName = "TankImageDialog";
TankImageDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  backdropOpen: PropTypes.bool.isRequired,
};

export default TankImageDialog;
