import React, { useState } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";

import StyledDataGrid from "components/StyledDataGrid/StyledDataGrid.js";
import AssignVendorDialog from "components/AssignVendorDialog/AssignVendorDialog.js";
import AssignedVendorsDialog from "components/AssignedVendorsDialog/AssignedVendorsDialog.js";

import homePageStyle from "assets/jss/material-kit-pro-react/views/homePageStyle.js";

import { authService } from "../../services";
const useStyles = makeStyles(homePageStyle);

function VendorFacilityReport(props) {
  const classes = useStyles();
  const columns = [
    {
      field: "facility",
      headerName: "Facility",
      flex: 75,
    },
    {
      field: "city",
      headerName: "City",
      flex: 100,
    },
    {
      field: "state",
      headerName: "State",
      flex: 75,
    },
    {
      field: "assign",
      headerName: "Assign Vendor",
      flex: 75,
      minWidth: 75,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const assignButton = (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setSelectedRow(params.row);
              setAssignVendorOpen(true);
            }}
          >
            Assign Vendor
          </Button>
        );
        return assignButton;
      },
    },
    {
      field: "view",
      headerName: "View Vendors",
      flex: 75,
      minWidth: 75,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const viewButton = (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setSelectedRow(params.row);
              setAssignedVendorsOpen(true);
            }}
          >
            View Vendors
          </Button>
        );
        return viewButton;
      },
    },
  ];

  const [facilities, setFacilities] = useState([]);
  const [module, setModule] = useState(0);
  const [assignVendorOpen, setAssignVendorOpen] = useState(false);
  const [assignedVendorsOpen, setAssignedVendorsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const loadData = () => {
    if (!module) return;

    let ses = authService.getSes();
    const jwt = authService.getJWT();
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };

    let url;
    switch (module) {
      case 1: // stick
        url = `${process.env.REACT_APP_API_URI}/parrtrax/facilities?company=${ses.selected_company}`;
        return;
      case 2: // tanks
        url = `${process.env.REACT_APP_API_URI}/tank/facilities?company=${ses.selected_company}`;
        break;
      case 3: // items
        url = `${process.env.REACT_APP_API_URI}/parrtrax/facilities?company=${ses.selected_company}`;
        break;
      case 4: // permits
        url = `${process.env.REACT_APP_API_URI}/permit/facilities?company=${ses.selected_company}`;
        break;
      case 5: // events
        url = `${process.env.REACT_APP_API_URI}/parrtrax/facilities?company=${ses.selected_company}`;
        break;
      default:
        return;
    }

    fetch(url, { headers })
      .then((resp) => {
        return resp.json();
      })
      .then((json) => {
        setFacilities(json);
      });
  };

  const handleModuleChange = (event) => {
    setModule(event.target.value);
  };

  React.useEffect(() => {
    authService.accessCheck(loadData, []);
  }, [module]);

  React.useEffect(() => {
    setFacilities([]);
    setModule(0);
  }, [props.company]);

  return (
    <div style={{ textAlign: "center" }}>
      <Grid container justify="space-around">
        <FormControl
          className={classes.formControl}
          style={{ minWidth: "30%", marginBottom: 10 }}
        >
          <InputLabel id="module-label">Choose Tracking Type</InputLabel>
          <Select
            labelId="module-label"
            id="module-select"
            onChange={handleModuleChange}
            value={module}
          >
            {props.modules.map((module) => (
              <MenuItem key={module.id} value={module.id}>
                {module.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <div style={{ height: 600, width: "100%" }}>
        <StyledDataGrid
          rows={facilities}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100]}
        />
        <AssignVendorDialog
          module={module}
          open={assignVendorOpen}
          onClose={() => {
            setAssignVendorOpen(false);
          }}
          row={selectedRow}
          onSubmit={loadData}
        />
        <AssignedVendorsDialog
          open={assignedVendorsOpen}
          onClose={() => {
            setAssignedVendorsOpen(false);
          }}
          row={selectedRow}
        />
      </div>
    </div>
  );
}

VendorFacilityReport.displayName = "VendorFacilityReport";
VendorFacilityReport.propTypes = {
  company: PropTypes.string.isRequired,
  modules: PropTypes.array.isRequired,
};

export default VendorFacilityReport;
