import React, { useState } from "react";
import PropTypes from "prop-types";

import { format, sub } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import StyledDataGrid from "components/StyledDataGrid/StyledDataGrid.js";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { history } from "../../helpers";
import { authService } from "../../services";

import LoadingBackdrop from "components/CustomLoadingBackdrop/LoadingBackdrop.js";

import homePageStyle from "assets/jss/material-kit-pro-react/views/homePageStyle.js";
const useStyles = makeStyles(homePageStyle);

function TankHistoryDialog(props) {
  const classes = useStyles();

  const columns = [
    { field: "date", headerName: "Date", flex: 75 },
    { field: "facility", headerName: "Facility", flex: 75 },
    {
      field: "oil_level",
      headerName: "Volume [gal]",
      flex: 100,
      align: "center",
    },
    {
      field: "tank_reading",
      headerName: "Percent Full",
      flex: 100,
      align: "center",
      valueFormatter: (params) => `${params.value} %`,
    },
    { field: "type", headerName: "Type", flex: 100, align: "center" },
    {
      field: "tank_capacity",
      headerName: "Capacity [gal]",
      flex: 100,
      align: "center",
    },
  ];

  const [startDate, setStartDate] = useState(sub(new Date(), { days: 30 }));
  const [endDate, setEndDate] = useState(new Date());
  const [historyRows, setHistoryRows] = useState([]);
  const [backdropOpen, setBackdropOpen] = useState(false);

  React.useEffect(() => {
    setBackdropOpen(true);
    const user = authService.getUser();
    let ses = authService.getSes();

    const jwt = authService.getJWT();
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    if (props.row.facility && startDate && endDate) {
      let startStr, endStr;
      try {
        startStr = format(new Date(startDate), "yyyy-MM-dd");
      } catch {}

      try {
        endStr = format(new Date(endDate), "yyyy-MM-dd");
      } catch {}

      fetch(
        `${process.env.REACT_APP_API_URI}/tank/history?cat_4=${props.row.facility}&start=${startStr}&end=${endStr}&company=${ses.selected_company}`,
        { headers }
      )
        .then((resp) => {
          if (resp.status === 403) {
            setBackdropOpen(false);
            history.push(user.homepage);
          }
          return resp.json();
        })
        .then((json) => {
          console.log(json);
          setBackdropOpen(false);
          setHistoryRows(json);
        });
    }
  }, [props.row, startDate, endDate]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
        History
      </DialogTitle>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="space-around">
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="yyyy-MM-dd"
            margin="normal"
            id="date-picker-inline"
            label="Start"
            maxDate={endDate}
            value={startDate}
            onChange={(date) => setStartDate(date)}
            autoOk={true}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="yyyy-MM-dd"
            margin="normal"
            id="date-picker-inline"
            label="End"
            minDate={startDate}
            value={endDate}
            onChange={(date) => setEndDate(date)}
            autoOk={true}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </Grid>
      </MuiPickersUtilsProvider>
      <div style={{ height: 600, width: "100%" }}>
        <StyledDataGrid
          rows={historyRows}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
          getCellClassName={(params) => {
            if (params.field === "tank_reading") {
              console.log(params);
              if (params.row.alert === "green") {
                return classes.alertGreen;
              } else if (params.row.alert === "orange") {
                return classes.alertOrange;
              } else if (params.row.alert === "red") {
                return classes.alertRed;
              }
            }
            return "";
          }}
        />
      </div>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Close
        </Button>
      </DialogActions>
      <LoadingBackdrop open={backdropOpen} />
    </Dialog>
  );
}

TankHistoryDialog.displayName = "TankHistoryDialog";
TankHistoryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
};

export default TankHistoryDialog;
