/* eslint-disable */
import React, { useState } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link, useLocation } from "react-router-dom";

// @material-ui/core components
import { makeStyles, styled } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';

// @material-ui/icons
import AccountCircle from "@material-ui/icons/AccountCircle";
import Phone from "@material-ui/icons/Phone";
import Opacity from "@material-ui/icons/Opacity";
import Gradient from "@material-ui/icons/Gradient";
import NewReleases from "@material-ui/icons/NewReleases";
import InsertChart from "@material-ui/icons/InsertChart";
import Straighten from "@material-ui/icons/Straighten";
import Schedule from "@material-ui/icons/Schedule";
import TrendingUp from "@material-ui/icons/TrendingUp";
import Create from "@material-ui/icons/Create";
import Person from "@material-ui/icons/Person";
import Group from "@material-ui/icons/Group";
import LocalGasStation from "@material-ui/icons/LocalGasStation";
import Close from "@material-ui/icons/Close";
import Home from "@material-ui/icons/Home";
import Assessment from "@material-ui/icons/Assessment";
import Apartment from "@material-ui/icons/Apartment";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import { history } from "../../helpers";
import { authService } from "../../services";

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";
import style from "assets/jss/material-kit-pro-react/modalStyle.js";

const useStyles = makeStyles(styles);
const useModalStyles = makeStyles(style);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CustomListItem = styled(ListItem)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
}));

export default function HeaderLinks(props) {
  const location = useLocation();
  const user = authService.getUser();
  const ses = authService.getSes();
  const selectedCompany = ses && ses.selected_company ? ses.selected_company : "";
  const [company, setCompany] = useState(selectedCompany);
  const [companyPermissions, setCompanyPermissions] = useState([]);
  const [passwdModalOpen, setPasswdModalOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [changePasswordSubmitEnabled, setChangePasswordSubmitEnabled] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [changePasswordMsg, setChangePasswordMsg] = useState("");
  const [changePasswordMsgColor, setChangePasswordMsgColor] = useState("red");

  const handlePasswordModalClose = () => {
    setPasswdModalOpen(false);
  };

  const handleChangePasswordSubmit = () => {
    setPasswdModalOpen(false);
    setBackdropOpen(true);
    let statusCode = 200;
    const user = authService.getUser();
    const jwt = authService.getJWT();
    const headers = {
      "Authorization": `Bearer ${jwt}`,
      "Content-Type": 'application/json',
    }
    const body = JSON.stringify({"current_password": password, "new_password": newPassword});
    fetch(`${process.env.REACT_APP_API_URI}/change_password`, { headers, method: "PATCH", body, }).then(resp => {
      statusCode = resp.status;
      return resp.json();
    }).then(json => {
      setPasswdModalOpen(true);
      setBackdropOpen(false);
      if(statusCode === 200) {
        setPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setTimeout(() => {
          authService.logout();
          window.location.reload(true);
        }, 2000);
        setChangePasswordMsgColor("blue");
      } else {
        setChangePasswordMsgColor("red");
      }
      setChangePasswordMsg(json.msg);
    });
  };

  const handlePasswordChange = (value) => {
    setPassword(value);

    const emptyNewPassword = newPassword.length === 0;
    const tooShort = newPassword.length < 8;
    const mismatch = newPassword !== confirmPassword;
    const disableSubmit = tooShort || mismatch || emptyNewPassword || !value;

    setChangePasswordSubmitEnabled(!disableSubmit);

    if(emptyNewPassword) {
      return;
    }

    if(tooShort && mismatch) {
      setNewPasswordError("Password too short. Must be at least 8 characters");
      setConfirmPasswordError("Password Mismatch");
    } else if(tooShort) {
      setNewPasswordError("Password too short. Must be at least 8 characters");
      setConfirmPasswordError("");
    } else if(mismatch) {
      setNewPasswordError("");
      setConfirmPasswordError("Password Mismatch");
    } else {
      setNewPasswordError("");
      setConfirmPasswordError("");
    }
  };

  const handleNewPasswordChange = (value) => {
    setNewPassword(value);

    const emptyNewPassword = value.length === 0;
    const tooShort = value.length < 8;
    const mismatch = value !== confirmPassword;
    const disableSubmit = tooShort || mismatch || emptyNewPassword || !password;

    setChangePasswordSubmitEnabled(!disableSubmit);

    if(emptyNewPassword) {
      return;
    }

    if(tooShort && mismatch) {
      setNewPasswordError("Password too short. Must be at least 8 characters");
      setConfirmPasswordError("Password Mismatch");
    } else if(tooShort) {
      setNewPasswordError("Password too short. Must be at least 8 characters");
      setConfirmPasswordError("");
    } else if(mismatch) {
      setNewPasswordError("");
      setConfirmPasswordError("Password Mismatch");
    } else {
      setNewPasswordError("");
      setConfirmPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (value) => {
    setConfirmPassword(value);

    const emptyNewPassword = newPassword.length === 0;
    const tooShort = newPassword.length < 8;
    const mismatch = value !== newPassword;
    const disableSubmit = tooShort || mismatch || emptyNewPassword || !password;

    setChangePasswordSubmitEnabled(!disableSubmit);

    if(emptyNewPassword) {
      return;
    }

    if(tooShort && mismatch) {
      setNewPasswordError("Password too short. Must be at least 8 characters");
      setConfirmPasswordError("Password Mismatch");
    } else if(tooShort) {
      setNewPasswordError("Password too short. Must be at least 8 characters");
      setConfirmPasswordError("");
    } else if(mismatch) {
      setNewPasswordError("");
      setConfirmPasswordError("Password Mismatch");
    } else {
      setNewPasswordError("");
      setConfirmPasswordError("");
    }
  };

  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScroll = (e, target) => {
    if (window.location.pathname === "/sections") {
      var isMobile = navigator.userAgent.match(
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
      );
      if (isMobile) {
        // if we are on mobile device the scroll into view will be managed by the browser
      } else {
        e.preventDefault();
        var targetScroll = document.getElementById(target);
        scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
      }
    }
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function() {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };
  var onClickSections = {};

  const handleCompanySelect = (e) => {
    if(user.companies.includes(e)) {
      setCompany(e);
      authService.setSes("selected_company", e);
    }
    setCompanyPermissions(user.company_permissions[e] || []);
  }

  const handleProfileSelect = (e) => {
    switch(e) {
      case "Logout":
        authService.logout();
        window.location.reload(true);
        break;
      case "Change Password":
        setPasswdModalOpen(true);
        break;
    }
  }

  React.useEffect(() => {
    setCompanyPermissions(user.company_permissions[company] || []);
  }, [company]);

  const classes = useStyles();
  const icons = {
    1: <Assessment className={classes.buttonIcon} />,
    2: <Opacity className={classes.buttonIcon} />,
    3: <Gradient className={classes.buttonIcon} />,
    4: <NewReleases className={classes.buttonIcon} />,
    5: <Create className={classes.buttonIcon} />,
    6: <TrendingUp className={classes.buttonIcon} />,
  };
  const getIcon = (icon) => {
    return icons[icon];
  };

  const { dropdownHoverColor } = props;
  const modalClasses = useModalStyles();
  return (
    <div>
      <List className={classes.list + " " + classes.mlAuto}>
        <ListItem className={classes.listItem}>
          { ses && ses.companies_menu && user && user.companies &&
              <CustomDropdown
                buttonText={company}
                buttonProps={{
                  color: "success",
                }}
                dropdownList={user.companies}
                buttonIcon={LocalGasStation}
                onClick={ (e) => { handleCompanySelect(e); props.handleCompanySelect(e); } }
              />
          }
          { ses && !ses.companies_menu &&
            <h4 style={{fontWeight: "bold", marginBottom: ".8em", marginLeft: "12px"}}>{company}&nbsp;&nbsp;&nbsp;&nbsp;</h4>
          }
        </ListItem>
        { user &&
          <CustomListItem
            className={classes.listItem}
            selected={location.pathname === "/home"}
            style={{
              '& .MuiSelected': {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
              },
            }}
          >
            <Button className={classes.navLink} color="transparent" onClick={() => { history.push('/home'); }}>
              <Home className={classes.buttonIcon} />
              Home
            </Button>
          </CustomListItem>
        }
        { companyPermissions.map((menuItem, index) => (
          user &&
          <CustomListItem className={classes.listItem} selected={location.pathname === menuItem.route} key={index}>
            <Button className={classes.navLink} color="transparent" onClick={() => { history.push(menuItem.route); }}>
              {getIcon(menuItem.id)}
              {menuItem.label}
            </Button>
          </CustomListItem>
        ))}
        { user &&
          <CustomListItem className={classes.listItem} selected={location.pathname === "/vendors"}>
            <Button className={classes.navLink} color="transparent" onClick={() => { history.push('/vendors'); }}>
              <Apartment className={classes.buttonIcon} />
              Vendors
            </Button>
          </CustomListItem>
        }
        { user &&
          <CustomListItem className={classes.listItem} selected={location.pathname === "/wti"}>
            <Button className={classes.navLink} color="transparent" onClick={() => { history.push('/wti'); }}>
              <TrendingUp className={classes.buttonIcon} />
              WTI
            </Button>
          </CustomListItem>
        }
        { user &&
          <ListItem className={classes.listItem}>
            <CustomDropdown
              buttonText={user.name}
              buttonProps={{
                color: "success",
              }}
              dropdownList={["Change Password", "Logout"]}
              buttonIcon={Person}
              onClick={ (e) => { handleProfileSelect(e); } }
            />
          </ListItem>
        }
      </List>
      <Dialog
        classes={{
          root: modalClasses.modalRoot,
          paper: modalClasses.modal,
        }}
        open={passwdModalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handlePasswordModalClose}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="large-modal-slide-title"
          disableTypography
          className={modalClasses.modalHeader}
        >
          <Button
            simple={true}
            className={modalClasses.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={handlePasswordModalClose}
          >
            {" "}
            <Close className={modalClasses.modalClose} />
          </Button>
          Change Password
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={modalClasses.modalBody}
        >
          <span style={{color: changePasswordMsgColor}}>{changePasswordMsg}</span>
          <TextField
            margin="dense"
            id="current-password"
            name="current-password"
            label="Current Password"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => handlePasswordChange(e.target.value)}
          />
          <span style={{color: "red"}}>{passwordError}</span>
          <TextField
            margin="dense"
            id="new-password"
            name="new-password"
            label="New Password"
            type="password"
            fullWidth
            value={newPassword}
            onChange={(e) => handleNewPasswordChange(e.target.value)}
          />
          <span style={{color: "red"}}>{newPasswordError}</span>
          <TextField
            margin="dense"
            id="confirm-password"
            name="confirm-password"
            label="Confirm Password"
            type="password"
            fullWidth
            value={confirmPassword}
            onChange={(e) => handleConfirmPasswordChange(e.target.value)}
          />
          <span style={{color: "red"}}>{confirmPasswordError}</span>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePasswordModalClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleChangePasswordSubmit} color="primary" disabled={!changePasswordSubmitEnabled}>
            Change Password
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop open={backdropOpen} style={{ zIndex: "999" }}>
        <CircularProgress color="inherit"/>
      </Backdrop>
    </div>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ])
};
