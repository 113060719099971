/*eslint-disable*/
import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from '@material-ui/core/IconButton';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
// @material-ui/icons
import Group from "@material-ui/icons/Group";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
// @material-ui/lab components
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAlert from '@material-ui/lab/Alert';

import CustomTabs from "components/CustomTabs/CustomTabs.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import homePageStyle from "assets/jss/material-kit-pro-react/views/homePageStyle.js";
import style from "assets/jss/material-kit-pro-react/modalStyle.js";

import { history } from "../../helpers";
import { authService } from "../../services";

const useStyles = makeStyles(homePageStyle);
const useModalStyles = makeStyles(style);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const spacer = {
  marginTop: "120px",
  marginBottom: "50px",
  color: "#000000",
  textAlign: "center"
};

const darkText = {
  color: "#000000"
};

const boldText = {
  fontWeight: "bold",
  fontSize: "1.1em"
};

const allUsergroups = [
  'VENDOR',
  'OFFICE',
  'SU',
];

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Users() {
  const [users, setUsers] = useState([]);
  const [submits, setSubmits] = useState(0);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [usergroups, setUsergroups] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState({});

  const handleUsergroupChange = (event) => {
    setUsergroups(event.target.value);
  };

  const handleApproveModal = () => {
    setApproveModalOpen(false);
    setBackdropOpen(true);

    const jwt = authService.getJWT();
    const headers = {
      "Authorization": `Bearer ${jwt}`,
      "Content-Type": 'application/json',
    }

    let body;
    if(selectedUser.approved === 1) {
      body = JSON.stringify({ approved: 0 })
    } else {
      const ug = usergroups.join("^");
      body = JSON.stringify({ approved: 1, user_group: ug })
    }

    fetch(`${process.env.REACT_APP_API_URI}/user/${selectedUser.id}`, { headers, method: 'PATCH', body, }).then(resp => {
      return resp.json();
    }).then(json => {
      setUsergroups([]);
      loadData();
      setBackdropOpen(false);
    });
  };

  const handleApproveModalClose = () => {
    setApproveModalOpen(false);
  };

  const handleCompanySelect = () => {
    const user = authService.getUser();
    history.push(user.homepage);
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessOpen(false);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };

  const loadData = () => {
    const jwt = authService.getJWT();
    const headers = {
      "Authorization": `Bearer ${jwt}`,
    }
    fetch(`${process.env.REACT_APP_API_URI}/all_users`, { headers }).then(resp => {
      return resp.json();
    }).then(json => {
      setUsers(json);
    });
  };

  const checkAccess = () => {
    authService.isTokenValid().then((json) => {
      if ("msg" in json) {
        authService.logout();
        window.location.reload(true);
      }
    });
  };

  React.useEffect(() => {
    authService.accessCheck(loadData, []);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, [ ]);

  const classes = useStyles();
  const modalClasses = useModalStyles();

  return (
    <div>
      <Header
        brand="ParrTRAX"
        links={<HeaderLinks dropdownHoverColor="success" handleCompanySelect={handleCompanySelect} />}
        fixed
        color="primary"
        className={classes.title}
      />

      <div style={spacer} justify="center"></div>
      <div className={classes.container}>
        <CustomTabs
          headerColor="primary"
          tabs={[
            {
              tabName: "Users",
              tabIcon: Group,
              tabContent: (
                <div className={classes.textCenter}>
                  <div>
                    <h3 style={{ fontWeight: "bold" }}>
                      All Users
                    </h3>
                    <TableContainer component={Paper}>
                      <Table className={classes.table} aria-label="simple table">
                        <TableHead className={classes.table_header}>
                          <TableRow className={classes.table_header_row}>
                            <TableCell className={classes.table_header_cell}>Email</TableCell>
                            <TableCell className={classes.table_header_cell}>Username</TableCell>
                            <TableCell className={classes.table_header_cell}>Phone</TableCell>
                            <TableCell className={classes.table_header_cell}>User Groups</TableCell>
                            <TableCell className={classes.table_header_cell}>Homepage</TableCell>
                            <TableCell className={classes.table_header_cell}>Vendor Company</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody className={classes.table_body}>
                          {users.map((row) => (
                            <TableRow key={row.id} className={classes.table_body_row}>
                              <TableCell className={classes.table_body_cell}>{row.email}</TableCell>
                              <TableCell className={classes.table_body_cell}>{row.username}</TableCell>
                              <TableCell className={classes.table_body_cell}>{row.phone}</TableCell>
                              <TableCell className={classes.table_body_cell}>{row.usergroups.join(", ")}</TableCell>
                              <TableCell className={classes.table_body_cell}>{row.homepage}</TableCell>
                              <TableCell className={classes.table_body_cell}>{row.vendor_company_id}</TableCell>
                              <TableCell className={classes.table_body_cell} style={{ padding: "0", textAlign: "center" }}>
                                <IconButton aria-label="expand row" size="small" onClick={() => { setSelectedUser(row); setApproveModalOpen(true); }} >
                                  { row.approved === 1 ? <Close /> : <Check /> }
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Backdrop open={backdropOpen} style={{ zIndex: "999" }}>
                      <CircularProgress color="inherit"/>
                    </Backdrop>
                    <Dialog
                      classes={{
                        root: modalClasses.modalRoot,
                        paper: modalClasses.modal,
                      }}
                      open={approveModalOpen}
                      TransitionComponent={Transition}
                      keepMounted
                      onClose={handleApproveModalClose}
                      aria-labelledby="classic-modal-slide-title"
                      aria-describedby="classic-modal-slide-description"
                    >
                      <DialogTitle
                        id="large-modal-slide-title"
                        disableTypography
                        className={modalClasses.modalHeader}
                      >
                        <Button
                          simple="true"
                          className={modalClasses.modalCloseButton}
                          key="close"
                          aria-label="Close"
                          onClick={handleApproveModalClose}
                        >
                          {" "}
                          <Close className={modalClasses.modalClose} />
                        </Button>
                      </DialogTitle>
                      <DialogContent
                        id="classic-modal-slide-description"
                        className={modalClasses.modalBody}
                      >
                        <DialogContentText>
                          { selectedUser.approved === 1 ? "This will disable the account for" : "Please select the usergroups for" } <span style={boldText}>{selectedUser.email}</span>
                        </DialogContentText>
                        { selectedUser.approved === 0 &&
                        <form className={classes.root} noValidate autoComplete="off">
                          <Grid container spacing={3} direction="column" >
                            <Grid container item spacing={0} justify="center">
                              <Grid item lg={12} md={12} s={12} xs={12}>
                                <FormControl style={{ width: "100%" }} error={false}>
                                  <InputLabel id="usergroup-label">Usergroups</InputLabel>
                                  <Select
                                    labelId="usergroup-label"
                                    id="usergroup-select"
                                    style={{ textAlign: "left" }}
                                    key={submits}
                                    multiple
                                    value={usergroups}
                                    onChange={handleUsergroupChange}
                                    input={<Input />}
                                    MenuProps={MenuProps}
                                  >
                                    {allUsergroups.map(usergroup => (
                                        <MenuItem key={usergroup || ""} value={usergroup || ""}>{usergroup || ""}</MenuItem>
                                    ))}
                                  </Select>
                                  <FormHelperText>Required</FormHelperText>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Grid>
                        </form>
                      }
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => {setApproveModalOpen(false);}} color="primary">
                          Cancel
                        </Button>
                        <Button onClick={handleApproveModal} color="primary">
                          { selectedUser.approved === 1 ? "Disable" : "Approve" }
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </div>
              )
            },
          ]}
        />
      </div>

      <div className={classes.textCenter} style={darkText}>
        <h3>Need Help?</h3>
        Call Us <span style={boldText}>931-363-5379</span>
      </div>


      <Footer
        content={
          <div>
            <div className={classes.left}>
              &copy; {1900 + new Date().getYear()} , Parr Inc.
            </div>
            <div className={classes.right}>
              Version: {process.env.REACT_APP_VERSION}
            </div>
          </div>
        }
      />
    </div>
  );
}
