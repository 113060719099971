/*eslint-disable*/
import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import { useConfirmDialog } from "react-mui-confirm";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

// custom components
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import LoadingBackdrop from "components/CustomLoadingBackdrop/LoadingBackdrop.js";

// Icons
import Apartment from "@material-ui/icons/Apartment";
import Store from "@material-ui/icons/Store";
import Add from "@material-ui/icons/Add";

import HeaderLinks from "components/Header/HeaderLinks.js";
import StyledDataGrid from "components/StyledDataGrid/StyledDataGrid.js";
import VendorFacilityDialog from "components/VendorFacilityDialog/VendorFacilityDialog.js";
import VendorViewFacilityDialog from "components/VendorViewFacilityDialog/VendorViewFacilityDialog.js";
import VendorFacilityReport from "components/VendorFacilityReport/VendorFacilityReport.js";
import AddVendorDialog from "components/AddVendorDialog/AddVendorDialog.js";
import homePageStyle from "assets/jss/material-kit-pro-react/views/homePageStyle.js";

import { history } from "../../helpers";
import { authService } from "../../services";

const useStyles = makeStyles(homePageStyle);

const spacer = {
  marginTop: "120px",
  marginBottom: "50px",
  color: "#000000",
  textAlign: "center"
}

const darkText = {
  color: "#000000"
}

const boldText = {
  fontWeight: "bold",
  fontSize: "1.1em"
}

export default function Vendors() {
  const confirm = useConfirmDialog();
  const [company, setCompany] = useState("");
  const [vendorRows, setVendorRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [modules, setModules] = useState([]);

  const [addVendorOpen, setAddVendorOpen] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [editVendor, setEditVendor] = useState(false);
  const [editInfo, setEditInfo] = useState({});

  const handleCompanySelect = (e) => {
    setCompany(e);
    const user = authService.getUser();
    setModules(user.company_permissions[e]);
  };

  const handleEdit = (info) => {
    setEditInfo(info);
    setEditVendor(true);
    setAddVendorOpen(true);
  };

  const removeVendor = (info) => {
    confirm({
      title: "Are you sure you wish to remove this vendor?",
      description: "All facilities will also be removed",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      onConfirm: () => {
        return;
        setBackdropOpen(true);
        const user = authService.getUser();
        const jwt = authService.getJWT();
        const headers = {
          "Authorization": `Bearer ${jwt}`,
        }
        fetch(`${process.env.REACT_APP_API_URI}/vendor/vendors?company_id=${authService.getSes().selected_company}&vendor_company_id=${info.company_id}`,
          { headers, method: "DELETE" }).then(resp => {
          return resp.json();
        }).then(json => {
          console.log(json);
        });
      },
    });
  };

  const loadData = () => {
    setEditVendor(false);
    setAddVendorOpen(false);
    const user = authService.getUser();
    let ses = authService.getSes();
    setCompany(ses.selected_company);
    setModules(user.company_permissions[ses.selected_company]);

    const jwt = authService.getJWT();
    const headers = {
      "Authorization": `Bearer ${jwt}`,
    }
    fetch(`${process.env.REACT_APP_API_URI}/vendor/vendors?company_id=${ses.selected_company}`, { headers }).then(resp => {
      return resp.json();
    }).then(json => {
      setVendorRows(json);
    });
  };

  React.useEffect(() => {
    authService.accessCheck(loadData, []);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, [ company ]);

  const classes = useStyles();

  const columns = [
    {
      field: "company",
      headerName: "Company",
      flex: 1,
      minWidth: 175,
    },
    {
      field: "coverage",
      headerName: "States Serviced",
      flex: 1,
      minWidth: 175,
    },
    {
      field: "services",
      headerName: "Services Offered",
      flex: 1,
      minWidth: 175,
    },
    {
      field: "notes",
      headerName: "Notes",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "add_facility",
      headerName: "Facility",
      flex: 0.5,
      minWidth: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setSelectedRow(params.row);
              setAddDialogOpen(true);
            }}
          >
            Add
          </Button>
        )
      }
    },
    {
      field: "view_facility",
      headerName: "Facilities",
      flex: 0.5,
      minWidth: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setSelectedRow(params.row);
              setViewDialogOpen(true);
            }}
          >
            View/Edit
          </Button>
        )
      }
    },
    {
      field: "edit_vendor",
      headerName: "Vendor",
      flex: 0.5,
      minWidth: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleEdit(params.row)}
          >
            Edit
          </Button>
        )
      }
    },
    {
      field: "remove_vendor",
      headerName: "Vendor",
      flex: 0.5,
      minWidth: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => removeVendor(params.row)}
          >
            Remove
          </Button>
        )
      }
    },
  ];

  return (
    <div>
      <Header
        brand="ParrTRAX"
        links={<HeaderLinks dropdownHoverColor="success" handleCompanySelect={handleCompanySelect} />}
        fixed
        color="primary"
        className={classes.title}
      />

      <div style={spacer} justify="center"></div>
      <div className={classes.container}>
        <CustomTabs
          headerColor="primary"
          tabs={[
            {
              tabName: "Vendors",
              tabIcon: Apartment,
              tabContent: (
                <div className={classes.textLeft}>
                  <h3 style={{ fontWeight: "bold", marginTop: "0px", marginBottom: "5px" }}>
                    All Vendors
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        float: "right",
                      }}
                      onClick={() => {setEditVendor(false); setAddVendorOpen(true)}}
                    >
                      Add Vendor
                    </Button>
                  </h3>
                  <div style={{ height: 600, width: "100%" }}>
                    <StyledDataGrid
                      rows={vendorRows}
                      columns={columns}
                      autoHeight={false}
                    />
                    <VendorFacilityDialog row={selectedRow} open={addDialogOpen} onClose={() => setAddDialogOpen(false)} onSubmit={loadData} />
                    <VendorViewFacilityDialog row={selectedRow} open={viewDialogOpen} onClose={() => setViewDialogOpen(false)} onSubmit={loadData} />
                  </div>
                </div>
              ),
            },
            {
              tabName: "Facilities",
              tabIcon: Store,
              tabContent: (
                <div className={classes.textLeft} >
                  <h3 style={{ fontWeight: "bold", position: "absolute" }}>Designate Vendors</h3>
                  <VendorFacilityReport company={company} modules={modules}/>
                </div>
              ),
            },
          ]}
        />
        <AddVendorDialog open={addVendorOpen} onClose={() => {setAddVendorOpen(false)}} onSubmit={loadData} edit={editVendor} row={editInfo} />
        <LoadingBackdrop open={backdropOpen} />
      </div>

      <div className={classes.textCenter} style={darkText}>
        <h3>Need Help?</h3>
        Call Us <span style={boldText}>931-363-5379</span>
      </div>


      <Footer
        content={
          <div>
            <div className={classes.left}>
              &copy; {1900 + new Date().getYear()} , Parr Inc.
            </div>
            <div className={classes.right}>
              Version: {process.env.REACT_APP_VERSION}
            </div>
          </div>
        }
      />
    </div>
  );
}
