import React, { useState } from "react";
import PropTypes from "prop-types";

import { DropzoneArea } from "material-ui-dropzone";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";

import Autocomplete from "@material-ui/lab/Autocomplete";

import LoadingBackdrop from "components/CustomLoadingBackdrop/LoadingBackdrop.js";

import { history } from "../../helpers";
import { authService } from "../../services";

import homePageStyle from "assets/jss/material-kit-pro-react/views/homePageStyle.js";
const useStyles = makeStyles(homePageStyle);

function TankCommissionForm(props) {
  const classes = useStyles();
  const [submits, setSubmits] = useState(0);
  const [facility, setFacility] = useState(0);
  const [tankType, setTankType] = useState(0);
  const [tankTypes, setTankTypes] = useState([]);
  const [diameter, setDiameter] = useState(0);
  const [diameterLabel, setDiameterLabel] = useState("");
  const [height, setHeight] = useState(0);
  const [heightLabel, setHeightLabel] = useState("");
  const [width, setWidth] = useState(0);
  const [widthLabel, setWidthLabel] = useState("");
  const [length, setLength] = useState(0);
  const [lengthLabel, setLengthLabel] = useState("");
  const [contentType, setContentType] = useState(0);
  const [contentTypes, setContentTypes] = useState([]);
  const [fillHeight, setFillHeight] = useState(0);
  const [tankImage, setTankImage] = useState("");
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [backdropOpen, setBackdropOpen] = useState(false);

  const defaultErrors = {
    facility: false,
    tankType: false,
    diameter: false,
    width: false,
    height: false,
    length: false,
    contentType: false,
    fillHeight: false,
  };
  const [errors, setErrors] = useState(defaultErrors);

  const defaultVisibility = {
    diameter: false,
    height: false,
    width: false,
    length: false,
  };
  const [visibility, setVisibility] = useState(defaultVisibility);

  const handleFacilityChange = (event) => {
    const content = event.target.textContent;
    const fac = parseInt(content.split(":")[0]);
    setFacility(fac);
  };

  const handleContentTypeChange = (event) => {
    const content = event.target.textContent;
    let contentInfo;
    contentTypes.forEach((contentType) => {
      if (contentType.content === content) {
        contentInfo = contentType;
      }
    });
    setContentType(contentInfo.content_type);
  };

  const handleTankTypeChange = (event) => {
    setVisibility(defaultVisibility);
    const content = event.target.textContent;
    let tankInfo;
    tankTypes.forEach((tank) => {
      if (tank.tank_geometry === content) {
        tankInfo = tank;
      }
    });
    setTankType(tankInfo.tank_type);

    setTankImage(`https://www.parr2.com${tankInfo.pic_name_path}`);
    setDiameterLabel(tankInfo.diameter_inch);
    setWidthLabel(tankInfo.width_inch);
    setHeightLabel(tankInfo.height_inch);
    setLengthLabel(tankInfo.length_inch);

    const visibility = {
      diameter: tankInfo.diameter_inch ? true : false,
      height: tankInfo.height_inch ? true : false,
      width: tankInfo.width_inch ? true : false,
      length: tankInfo.length_inch ? true : false,
    };
    setVisibility(visibility);
  };

  const submit = () => {
    setBackdropOpen(true);
    setErrors(defaultErrors);

    let newErrors = defaultErrors;
    newErrors["facility"] = !facility;
    newErrors["tankType"] = !tankType;
    newErrors["contentType"] = !contentType;
    newErrors["fillHeight"] = !fillHeight;
    newErrors["diameter"] = visibility["diameter"] && !diameter;
    newErrors["length"] = visibility["length"] && !length;
    newErrors["width"] = visibility["width"] && !width;
    newErrors["height"] = visibility["height"] && !height;
    setErrors(newErrors);

    for (const prop in newErrors) {
      if (newErrors[prop]) {
        setBackdropOpen(false);
        return;
      }
    }

    const formData = new FormData();
    formData.append("company", props.company);
    formData.append("cat_4", facility);
    formData.append("tank_type", tankType);
    formData.append("content_type", contentType);
    formData.append("diameter", diameter);
    formData.append("length", length);
    formData.append("width", width);
    formData.append("height", height);
    formData.append("description", description);
    formData.append("fill_height", fillHeight);
    let i = 0;
    files.forEach((file) => {
      formData.append(`myFile${i}`, file);
      i++;
    });

    const user = authService.getUser();
    const jwt = authService.getJWT();
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    fetch(`${process.env.REACT_APP_API_URI}/tank/tanks`, {
      headers,
      method: "POST",
      body: formData,
    })
      .then((resp) => {
        if (resp.status === 403) {
          history.push(user.homepage);
        } else if (resp.status !== 200) {
          alert("Failed to add tank");
          setBackdropOpen(false);
        } else {
          return resp.json();
        }
      })
      .then((json) => {
        setBackdropOpen(false);
        if (json && json.error) {
          alert(`Failed to add tank - ${json.error}`);
        } else if (json) {
          alert("Successfully added tank");
          setDiameter(0);
          setLength(0);
          setWidth(0);
          setHeight(0);
          setDescription("");
          setVisibility(defaultVisibility);
          setTankImage("");
          setSubmits(submits + 1);
          props.refresh();
        }
      });
  };

  React.useEffect(() => {
    const user = authService.getUser();
    const jwt = authService.getJWT();
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    fetch(
      `${process.env.REACT_APP_API_URI}/tank/tank_types?company=${props.company}`,
      { headers }
    )
      .then((resp) => {
        if (resp.status === 403) {
          history.push(user.homepage);
        } else {
          return resp.json();
        }
      })
      .then((json) => {
        setTankTypes(json);
      });

    fetch(
      `${process.env.REACT_APP_API_URI}/tank/content_types?company=${props.company}`,
      { headers }
    )
      .then((resp) => {
        if (resp.status === 403) {
          history.push(user.homepage);
        } else {
          return resp.json();
        }
      })
      .then((json) => {
        setContentTypes(json);
      });
  }, [props.company]);

  return (
    <div className={classes.textCenter}>
      <div>
        <h3 style={{ fontWeight: "bold" }}>Add Tank</h3>
        <form className={classes.root} noValidate autoComplete="off">
          <Grid container spacing={3} direction="column">
            <Grid
              container
              item
              spacing={0}
              justify="center"
              style={{ display: tankImage ? "" : "none" }}
            >
              <Grid item lg={6} md={6} s={12} xs={12}>
                {tankImage && (
                  <CardMedia
                    style={{
                      height: "300px",
                      width: "400px",
                      margin: "0 auto",
                    }}
                    image={tankImage}
                  />
                )}
              </Grid>
            </Grid>
            <Grid container item spacing={0} justify="center">
              <Grid item lg={6} md={6} s={12} xs={12}>
                <FormControl style={{ width: "100%" }}>
                  <Autocomplete
                    options={props.facilities}
                    getOptionLabel={(option) =>
                      option === 0 ? "" : option.label
                    }
                    id="facility"
                    onChange={handleFacilityChange}
                    key={submits}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Facility"
                        margin="normal"
                        helperText="Type Facility ID, City, or State - Required"
                        error={errors["facility"]}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item spacing={0} justify="center">
              <Grid item lg={6} md={6} s={12} xs={12}>
                <FormControl style={{ width: "100%" }}>
                  <Autocomplete
                    options={tankTypes}
                    getOptionLabel={(option) =>
                      option === 0 ? "" : option.tank_geometry
                    }
                    id="tank-type"
                    onChange={handleTankTypeChange}
                    key={submits}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tank Type"
                        margin="normal"
                        helperText="Required"
                        error={errors["tankType"]}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              item
              spacing={0}
              justify="center"
              style={{ display: visibility["diameter"] ? "" : "none" }}
            >
              <Grid item lg={6} md={6} s={12} xs={12}>
                <FormControl style={{ width: "100%" }}>
                  <TextField
                    key={submits}
                    margin="dense"
                    id="diameter"
                    name="diameter"
                    label={diameterLabel}
                    type="number"
                    error={errors["diameter"]}
                    helperText="Required."
                    fullWidth
                    value={diameter || ""}
                    onChange={(reading) => setDiameter(reading.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              item
              spacing={0}
              justify="center"
              style={{ display: visibility["height"] ? "" : "none" }}
            >
              <Grid item lg={6} md={6} s={12} xs={12}>
                <FormControl style={{ width: "100%" }}>
                  <TextField
                    key={submits}
                    margin="dense"
                    id="height"
                    name="height"
                    label={heightLabel}
                    type="number"
                    error={errors["height"]}
                    helperText="Required."
                    fullWidth
                    value={height || ""}
                    onChange={(reading) => setHeight(reading.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              item
              spacing={0}
              justify="center"
              style={{ display: visibility["width"] ? "" : "none" }}
            >
              <Grid item lg={6} md={6} s={12} xs={12}>
                <FormControl style={{ width: "100%" }}>
                  <TextField
                    key={submits}
                    margin="dense"
                    id="width"
                    name="width"
                    label={widthLabel}
                    type="number"
                    error={errors["width"]}
                    helperText="Required."
                    fullWidth
                    value={width || ""}
                    onChange={(reading) => setWidth(reading.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              item
              spacing={0}
              justify="center"
              style={{ display: visibility["length"] ? "" : "none" }}
            >
              <Grid item lg={6} md={6} s={12} xs={12}>
                <FormControl style={{ width: "100%" }}>
                  <TextField
                    key={submits}
                    margin="dense"
                    id="length"
                    name="length"
                    label={lengthLabel}
                    type="number"
                    error={errors["length"]}
                    helperText="Required."
                    fullWidth
                    value={length || ""}
                    onChange={(reading) => setLength(reading.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item spacing={0} justify="center">
              <Grid item lg={6} md={6} s={12} xs={12}>
                <FormControl style={{ width: "100%" }}>
                  <TextField
                    key={submits}
                    margin="dense"
                    id="fill-height"
                    name="fill-height"
                    label="Fill Height [inch]"
                    type="number"
                    error={errors["fillHeight"]}
                    helperText="Required."
                    fullWidth
                    value={fillHeight || ""}
                    onChange={(reading) => setFillHeight(reading.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item spacing={0} justify="center">
              <Grid item lg={6} md={6} s={12} xs={12}>
                <FormControl style={{ width: "100%" }}>
                  <Autocomplete
                    options={contentTypes}
                    getOptionLabel={(option) =>
                      option === 0 ? "" : option.content
                    }
                    id="contents-type"
                    onChange={handleContentTypeChange}
                    key={submits}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Content Type"
                        margin="normal"
                        helperText="Required"
                        error={errors["contentType"]}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item spacing={0} justify="center">
              <Grid item lg={6} md={6} s={12} xs={12}>
                <FormControl style={{ width: "100%" }}>
                  <TextField
                    key={submits}
                    margin="dense"
                    id="description"
                    name="description"
                    label="Description"
                    type="text"
                    multiline
                    rows={3}
                    helperText="Optional."
                    fullWidth
                    value={description || ""}
                    onChange={(reading) => setDescription(reading.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item spacing={0} justify="center">
              <Grid item lg={6} md={6} s={12} xs={12}>
                <DropzoneArea
                  onChange={(files) => {
                    setFiles(files);
                  }}
                  key={submits}
                  dropzoneText="Click on icon below to upload tank pictures."
                />
              </Grid>
            </Grid>
            <Grid container item spacing={0} justify="center">
              <Grid item lg={6} md={6} s={12} xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: "100%" }}
                  onClick={submit}
                >
                  Add Tank
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <LoadingBackdrop open={backdropOpen} />
    </div>
  );
}

TankCommissionForm.displayName = "TankCommissionForm";
TankCommissionForm.propTypes = {
  facilities: PropTypes.array.isRequired,
  company: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default TankCommissionForm;
