const markerPopupRow = {
  color: "#777777",
  font: "13px Roboto, Arial, sans-serif",
};

const markerPopupCell = {
  textAlign: "left",
  paddingRight: "10px",
  verticalAlign: "top",
};

export { markerPopupRow, markerPopupCell };
