import React, { useState } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import Autocomplete from "@material-ui/lab/Autocomplete";

import LoadingBackdrop from "components/CustomLoadingBackdrop/LoadingBackdrop.js";

import homePageStyle from "assets/jss/material-kit-pro-react/views/homePageStyle.js";
import { authService } from "../../services";
import { history } from "../../helpers";

const useStyles = makeStyles(homePageStyle);

function VendorFacilityDialog(props) {
  const classes = useStyles();
  const [submits, setSubmits] = useState(0);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [allStates, setAllStates] = useState([]);
  const [vendorServices, setVendorServices] = useState([]);
  const [existingFacilities, setExistingFacilities] = useState([]);

  const defaultErrors = {
    address: false,
    city: false,
    state: false,
    zip: false,
    services: false,
    coverage: false,
    contact: false,
    phone: false,
    email: false,
  };
  const [errors, setErrors] = useState(defaultErrors);

  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [services, setServices] = useState([]);
  const [coverage, setCoverage] = useState([]);
  const [contact, setContact] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");

  const clearForm = () => {
    setAddress("");
    setCity("");
    setState("");
    setZip("");
    setContact("");
    setContactPhone("");
    setContactEmail("");
    setServices([]);
    setCoverage([]);
    setExistingFacilities([]);

    setSubmits(submits + 1);
  };

  const stateChanged = (value) => {
    setState(value);
    const jwt = authService.getJWT();
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    fetch(
      `${process.env.REACT_APP_API_URI}/vendor/facilities?company_id=${
        authService.getSes().selected_company
      }&vendor_company_id=${props.row.company_id}&state=${value}`,
      { headers }
    )
      .then((resp) => {
        return resp.json();
      })
      .then((json) => {
        setExistingFacilities(json);
      });
  };

  const close = () => {
    clearForm();
    props.onClose();
  };

  const submit = () => {
    setBackdropOpen(true);
    setErrors(defaultErrors);
    const newErrors = {
      address: address === "",
      city: city === "",
      state: state === "",
      zip: zip === "",
      services: services.length === 0,
      coverage: coverage.length === 0,
      contact: contact.length === 0,
      phone: contactPhone.length === 0,
      email: contactEmail.length === 0,
    };
    setErrors(newErrors);

    for (const prop in newErrors) {
      if (newErrors[prop]) {
        setBackdropOpen(false);
        return;
      }
    }

    const data = {
      company_id: authService.getSes().selected_company,
      vendor_company_id: props.row.company_id,
      address,
      city,
      state,
      zip,
      services,
      coverage,
      contact,
      phone: contactPhone,
      email: contactEmail,
    };

    const user = authService.getUser();
    const jwt = authService.getJWT();
    const headers = {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    };
    fetch(`${process.env.REACT_APP_API_URI}/vendor/facilities`, {
      headers,
      method: "POST",
      body: JSON.stringify(data),
    }).then((resp) => {
      setBackdropOpen(false);
      if (resp.status === 403) {
        history.push(user.homepage);
        resp.json = [];
      } else if (resp.status !== 200) {
        alert("Error adding facility");
      } else {
        clearForm();
        props.onSubmit();
      }
    });
  };

  React.useEffect(() => {
    const jwt = authService.getJWT();
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    fetch(`${process.env.REACT_APP_API_URI}/states`, { headers })
      .then((resp) => {
        return resp.json();
      })
      .then((json) => {
        setAllStates(json);
      });

    let ses = authService.getSes();
    const user = authService.getUser();
    fetch(
      `${process.env.REACT_APP_API_URI}/vendor/all_service_types?company_id=${ses.selected_company}`,
      { headers }
    )
      .then((resp) => {
        if (resp.status === 403) {
          history.push(user.homepage);
          resp.json = [];
        }
        return resp.json();
      })
      .then((json) => {
        setVendorServices(json);
      });
  }, []);

  return (
    <Dialog
      open={props.open}
      onClose={close}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">Add Vendor Facility</DialogTitle>
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div style={{ display: existingFacilities.length > 0 ? "" : "none" }}>
          <h5 style={{ fontWeight: "bold", marginBottom: "0px" }}>
            Existing Facilities in {state}
          </h5>
          <List dense={true} style={{ paddingTop: "0px" }}>
            {existingFacilities.map((facility) => (
              <ListItem key={facility.cat_4}>
                <ListItemText
                  primary={`${facility.company}-${facility.cat_4}: ${facility.city}, ${facility.state}`}
                />
              </ListItem>
            ))}
          </List>
        </div>
        <form className={classes.root} noValidate>
          <Grid container spacing={3} direction="column">
            <Grid container item spacing={0} justify="center">
              <Grid item lg={12} md={12} s={12} xs={12}>
                <FormControl
                  style={{ width: "100%" }}
                  error={errors["state"]}
                  autoComplete="new-state"
                >
                  <InputLabel id="state-label">
                    State - Shows existing facilities
                  </InputLabel>
                  <Select
                    labelId="state-label"
                    id="state-select"
                    onChange={(event) => stateChanged(event.target.value)}
                    value={state}
                  >
                    {allStates.map((state) => (
                      <MenuItem key={state.abbrev} value={state.abbrev}>
                        {state.abbrev}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Required.</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item lg={12} md={12} s={12} xs={12}>
                <FormControl style={{ width: "100%" }}>
                  <TextField
                    key={submits}
                    margin="dense"
                    id="address"
                    name="address"
                    label="Address"
                    type="text"
                    error={errors["address"]}
                    helperText="Required."
                    fullWidth
                    value={address || ""}
                    onChange={(reading) => setAddress(reading.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                lg={8}
                md={8}
                s={8}
                xs={8}
                style={{ paddingRight: "10px" }}
              >
                <FormControl style={{ width: "100%" }} autoComplete="new-city">
                  <TextField
                    key={submits}
                    margin="dense"
                    id="city"
                    name="city"
                    label="City"
                    type="text"
                    error={errors["city"]}
                    helperText="Required."
                    fullWidth
                    value={city || ""}
                    inputProps={{
                      autoComplete: "new-city",
                      form: { autoComplete: "new-city" },
                    }}
                    onChange={(reading) => setCity(reading.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={4} md={4} s={4} xs={4}>
                <FormControl autoComplete="new-zip">
                  <TextField
                    key={submits}
                    margin="dense"
                    id="zip"
                    name="zip"
                    label="Zip"
                    type="text"
                    error={errors["zip"]}
                    helperText="Required."
                    fullWidth
                    value={zip || ""}
                    inputProps={{
                      autoComplete: "new-zip",
                      form: { autoComplete: "new-zip" },
                    }}
                    onChange={(reading) => setZip(reading.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={12} md={12} s={12} xs={12}>
                <FormControl
                  style={{ width: "100%" }}
                  autoComplete="new-services"
                >
                  <Autocomplete
                    multiple
                    id="Services"
                    options={vendorServices}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => setServices(value)}
                    key={submits}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Service(s) - Select all that apply"
                        error={errors["services"]}
                        helperText="Required."
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={12} md={12} s={12} xs={12}>
                <FormControl
                  style={{ width: "100%" }}
                  autoComplete="new-coverage"
                >
                  <Autocomplete
                    multiple
                    id="Coverage"
                    options={allStates}
                    getOptionLabel={(option) => option.abbrev}
                    onChange={(event, value) => setCoverage(value)}
                    key={submits}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="States Serviced - Select all that apply"
                        error={errors["coverage"]}
                        helperText="Required."
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={12} md={12} s={12} xs={12}>
                <FormControl
                  style={{ width: "100%" }}
                  autoComplete="new-contact"
                >
                  <TextField
                    key={submits}
                    margin="dense"
                    id="contact"
                    name="contact"
                    label="Contact"
                    type="text"
                    error={errors["contact"]}
                    helperText="Required."
                    fullWidth
                    value={contact || ""}
                    onChange={(reading) => setContact(reading.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                s={6}
                xs={6}
                style={{ paddingRight: "10px" }}
              >
                <FormControl style={{ width: "100%" }} autoComplete="new-phone">
                  <TextField
                    key={submits}
                    margin="dense"
                    id="new-phone"
                    name="new-phone"
                    label="Phone"
                    type="text"
                    error={errors["phone"]}
                    helperText="Required."
                    fullWidth
                    value={contactPhone || ""}
                    inputProps={{ autoComplete: "new-phone" }}
                    onChange={(reading) =>
                      setContactPhone(reading.target.value)
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} s={6} xs={6}>
                <FormControl style={{ width: "100%" }} autoComplete="new-email">
                  <TextField
                    key={submits}
                    margin="dense"
                    id="new-email"
                    name="new-email"
                    label="Email"
                    type="text"
                    error={errors["email"]}
                    helperText="Required."
                    fullWidth
                    value={contactEmail || ""}
                    inputProps={{ autoComplete: "new-email" }}
                    onChange={(reading) =>
                      setContactEmail(reading.target.value)
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            style={{ width: "100%", marginTop: "10px" }}
            onClick={submit}
          >
            Add Facility
          </Button>
        </form>
      </div>
      <DialogActions>
        <Button onClick={close} color="primary">
          Close
        </Button>
      </DialogActions>
      <LoadingBackdrop open={backdropOpen} />
    </Dialog>
  );
}

VendorFacilityDialog.displayName = "VendorFacilityDialog";
VendorFacilityDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
};

export default VendorFacilityDialog;
