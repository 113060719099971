/*eslint-disable*/
import { format, sub } from "date-fns";
import React, { useState } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import { InfoBox } from "react-google-maps/lib/components/addons/InfoBox";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import Phone from "@material-ui/icons/Phone";
import Dashboard from "@material-ui/icons/Dashboard";
import InsertChart from "@material-ui/icons/InsertChart";
import Straighten from "@material-ui/icons/Straighten";
import Schedule from "@material-ui/icons/Schedule";
import Map from "@material-ui/icons/Map";
import LocalGasStation from "@material-ui/icons/LocalGasStation";
import Opacity from "@material-ui/icons/Opacity";
import Check from "@material-ui/icons/Check";
import Warning from "@material-ui/icons/Warning";
import CloseIcon from "@material-ui/icons/Close";
import StopRounded from "@material-ui/icons/StopRounded";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import NavPills from "components/NavPills/NavPills.js";

import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Clearfix from "components/Clearfix/Clearfix.js";

import HeaderLinks from "components/Header/HeaderLinks.js";
import homePageStyle from "assets/jss/material-kit-pro-react/views/homePageStyle.js";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { history } from "../../helpers";
import { authService } from "../../services";

const useStyles = makeStyles(homePageStyle);

const spacer = {
  marginTop: "120px",
  marginBottom: "50px",
  color: "#000000",
  textAlign: "center"
}

const darkText = {
  color: "#000000"
}

const boldText = {
  fontWeight: "bold",
  fontSize: "1.1em"
}

export default function Items() {
  const [idxRows, setIdxRows] = useState([]);
  const [company, setCompany] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(sub(new Date(), { days: 7 }));
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  const handleFacilityChange = (event) => {
    setSelectedFacility(event.target.value);
  };

  const handleCompanySelect = (e) => {
    setCompany(e);
    const user = authService.getUser();
    const perms = user.company_permissions[e].filter((item) => {
      return item.route === "/items";
    });
    if(perms.length === 0) {
      history.push(user.homepage);
    }
  };

  const loadData = () => {
    const user = authService.getUser();
    let ses = authService.getSes();
    setCompany(ses.selected_company);

    const jwt = authService.getJWT();
    const headers = {
      "Authorization": `Bearer ${jwt}`,
    }

    if(selectedStartDate && selectedEndDate) {
      let startStr, endStr;
      try {
        startStr = format(new Date(selectedStartDate), "yyyy-MM-dd");
      } catch {}

      try {
        endStr = format(new Date(selectedEndDate), "yyyy-MM-dd");
      } catch {}

      fetch(`${process.env.REACT_APP_API_URI}/thirdparty/index_price?index=WTI%20Crude&start=${startStr}&end=${endStr}`, { headers }).then(resp => {
        if(resp.status == 403) {
          alert('Access Denied');
          history.push(user.homepage);
        }
        return resp.json();
      }).then(json => {
        setIdxRows(json);
      });
    }
  };

  React.useEffect(() => {
    authService.accessCheck(loadData, []);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, [ selectedStartDate, selectedEndDate, company ]);

  const classes = useStyles();

  return (
    <div>
      <Header
        brand="ParrTRAX"
        links={<HeaderLinks dropdownHoverColor="success" handleCompanySelect={handleCompanySelect} />}
        fixed
        color="primary"
        className={classes.title}
      />

      <div style={spacer} justify="center"></div>
      <Card style={{ width: "95%", margin: "2em auto" }}>
        <CardHeader style={{ width: "98%", marginLeft: "1%", backgroundColor: "#2d7e59", color: "#ffffff", fontSize: "1.4em", fontWeight: "normal", textAlign: "center" }}>
          <div>Item Tracking</div>
        </CardHeader>
        <CardBody>
          Item Content Placeholder
        </CardBody>
      </Card>

      <div className={classes.textCenter} style={darkText}>
        <h3>Need Help?</h3>
        Call Us <span style={boldText}>931-363-5379</span>
      </div>


      <Footer
        content={
          <div>
            <div className={classes.left}>
              &copy; {1900 + new Date().getYear()} , Parr Inc.
            </div>
            <div className={classes.right}>
              Version: {process.env.REACT_APP_VERSION}
            </div>
          </div>
        }
      />
    </div>
  );
}
