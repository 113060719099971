import React, { useState } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

import Autocomplete from "@material-ui/lab/Autocomplete";
import LoadingBackdrop from "components/CustomLoadingBackdrop/LoadingBackdrop.js";

import { authService } from "../../services";
import { history } from "../../helpers";
import homePageStyle from "assets/jss/material-kit-pro-react/views/homePageStyle.js";
const useStyles = makeStyles(homePageStyle);

function AddVendorDialog(props) {
  const classes = useStyles();
  const [submits, setSubmits] = useState(0);
  const [allStates, setAllStates] = useState([]);
  const [vendorServices, setVendorServices] = useState([]);

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [services, setServices] = useState([]);
  const [coverage, setCoverage] = useState([]);
  const [vendorCompanyId, setVendorCompanyId] = useState("");

  const defaultErrors = {
    name: false,
    address: false,
    city: false,
    state: false,
    zip: false,
    email: false,
    phone: false,
    services: false,
    coverage: false,
  };
  const [errors, setErrors] = useState(defaultErrors);
  const [backdropOpen, setBackdropOpen] = useState(false);

  React.useEffect(() => {
    const jwt = authService.getJWT();
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    fetch(`${process.env.REACT_APP_API_URI}/states`, { headers })
      .then((resp) => {
        return resp.json();
      })
      .then((json) => {
        setAllStates(json);
      });

    let ses = authService.getSes();
    const user = authService.getUser();
    fetch(
      `${process.env.REACT_APP_API_URI}/vendor/all_service_types?company_id=${ses.selected_company}`,
      { headers }
    )
      .then((resp) => {
        if (resp.status === 403) {
          history.push(user.homepage);
          resp.json = [];
        }
        return resp.json();
      })
      .then((json) => {
        setVendorServices(json);
      });

    if(props.edit) {
      fetch(
        `${process.env.REACT_APP_API_URI}/vendor/vendors?company_id=${ses.selected_company}&vendor_company_id=${props.row.company_id}`,
        { headers }
      )
        .then((resp) => {
          if (resp.status === 403) {
            history.push(user.homepage);
            resp.json = [];
          }
          return resp.json();
        })
        .then((json) => {
          setName(json[0].company);
          setAddress(json[0].address);
          setCity(json[0].city);
          setState(json[0].state);
          setZip(json[0].zip);
          setEmail(json[0].email);
          setPhone(json[0].phone);
          setVendorCompanyId(json[0].company_id);

          let serv = [];
          json[0].service_ids.forEach((id) => {
            vendorServices.forEach((service) => {
              if(service.id === id) {
                serv.push(service);
              }
            });
          });
          setServices(serv);

          let cov = [];
          json[0].coverage.split(",").forEach((abbrev) => {
            cov.push({abbrev});
          });
          setCoverage(cov);
        });
    }
  }, [props.edit]);

  const submit = () => {
    setBackdropOpen(true);
    setErrors(defaultErrors);

    let newErrors = defaultErrors;
    newErrors = {
      name: !name,
      address: !address,
      city: !city,
      state: !state,
      zip: !zip,
      email: !email,
      phone: !phone,
      services: services.length === 0,
      coverage: coverage.length === 0,
    };
    setErrors(newErrors);

    for (const prop in newErrors) {
      if (newErrors[prop]) {
        setBackdropOpen(false);
        return;
      }
    }

    const data = {
      company_id: authService.getSes().selected_company,
      name,
      address,
      city,
      state,
      zip,
      email,
      phone,
      services,
      coverage,
      edit: props.edit,
    };
    if(props.edit) {
      data["vendor_company_id"] = vendorCompanyId;
    }

    const editStr = props.edit ? "edit": "add";
    const user = authService.getUser();
    const jwt = authService.getJWT();
    const headers = {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    };
    fetch(`${process.env.REACT_APP_API_URI}/vendor/vendors`, {
      headers,
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((resp) => {
        if (resp.status === 403) {
          history.push(user.homepage);
        } else if (resp.status !== 200) {
          alert(`Failed to ${editStr} vendor`);
          setBackdropOpen(false);
        } else {
          return resp.json();
        }
      })
      .then((json) => {
        setBackdropOpen(false);
        if (json && json.error) {
          alert(`Failed to ${editStr} vendor - ${json.error}`);
        } else if (json) {
          alert(`Successfully ${editStr}ed vendor`);
          setSubmits(submits + 1);
          setName("");
          setAddress("");
          setCity("");
          setState("");
          setZip("");
          setEmail("");
          setPhone("");
          setServices([]);
          setCoverage([]);
          props.onSubmit();
        }
      });
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">{ props.edit ? "Edit Vendor" : "Add Vendor"}</DialogTitle>
      <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <form className={classes.root} noValidate autoComplete="off">
          <Grid container spacing={3} direction="column">
            <Grid container item spacing={0} justify="center">
              <Grid item lg={12} md={12} s={12} xs={12}>
                <FormControl style={{ width: "100%" }}>
                  <TextField
                    key={submits}
                    margin="dense"
                    id="name"
                    name="name"
                    label="Company Name"
                    type="text"
                    error={errors["name"]}
                    helperText="Required."
                    fullWidth
                    value={name}
                    onChange={(input) => setName(input.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item spacing={0} justify="center">
              <Grid item lg={12} md={12} s={12} xs={12}>
                <FormControl style={{ width: "100%" }}>
                  <TextField
                    key={submits}
                    margin="dense"
                    id="address"
                    name="address"
                    label="Address"
                    type="text"
                    error={errors["address"]}
                    helperText="Required."
                    fullWidth
                    value={address}
                    onChange={(input) => setAddress(input.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item spacing={0} justify="center">
              <Grid
                item
                lg={5}
                md={5}
                s={12}
                xs={12}
                style={{ paddingRight: "10px" }}
              >
                <FormControl style={{ width: "100%" }} autoComplete="new-city">
                  <TextField
                    key={submits}
                    margin="dense"
                    id="city"
                    name="city"
                    label="City"
                    type="text"
                    error={errors["city"]}
                    helperText="Required."
                    fullWidth
                    value={city}
                    inputProps={{
                      autoComplete: "new-city",
                      form: { autoComplete: "new-city" },
                    }}
                    onChange={(reading) => setCity(reading.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                lg={3}
                md={3}
                s={12}
                xs={12}
                style={{ marginTop: "5px" }}
              >
                <FormControl
                  style={{ width: "100%" }}
                  error={errors["state"]}
                  autoComplete="new-state"
                >
                  <InputLabel id="state-label">State</InputLabel>
                  <Select
                    labelId="state-label"
                    id="state-select"
                    onChange={(event) => setState(event.target.value)}
                    value={state}
                  >
                    {allStates.map((state) => (
                      <MenuItem key={state.abbrev} value={state.abbrev}>
                        {state.abbrev}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Required.</FormHelperText>
                </FormControl>
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                s={12}
                xs={12}
                style={{ paddingLeft: "10px" }}
              >
                <FormControl autoComplete="new-zip">
                  <TextField
                    key={submits}
                    margin="dense"
                    id="zip"
                    name="zip"
                    label="Zip"
                    type="text"
                    error={errors["zip"]}
                    helperText="Required."
                    fullWidth
                    value={zip}
                    inputProps={{
                      autoComplete: "new-zip",
                      form: { autoComplete: "new-zip" },
                    }}
                    onChange={(reading) => setZip(reading.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item spacing={0} justify="center">
              <Grid
                item
                lg={6}
                md={6}
                s={12}
                xs={12}
                style={{ paddingRight: "10px" }}
              >
                <FormControl style={{ width: "100%" }} autoComplete="new-phone">
                  <TextField
                    key={submits}
                    margin="dense"
                    id="phone"
                    name="phone"
                    label="Contact Phone"
                    type="text"
                    error={errors["phone"]}
                    helperText="Required."
                    fullWidth
                    value={phone}
                    inputProps={{
                      autoComplete: "new-phone",
                      form: { autoComplete: "new-phone" },
                    }}
                    onChange={(reading) => setPhone(reading.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                s={12}
                xs={12}
                style={{ paddingRight: "10px" }}
              >
                <FormControl style={{ width: "100%" }} autoComplete="new-email">
                  <TextField
                    key={submits}
                    margin="dense"
                    id="email"
                    name="email"
                    label="Contact Email"
                    type="text"
                    error={errors["email"]}
                    helperText="Required."
                    fullWidth
                    value={email}
                    inputProps={{
                      autoComplete: "new-email",
                      form: { autoComplete: "new-email" },
                    }}
                    onChange={(reading) => setEmail(reading.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item spacing={0} justify="center">
              <Grid item lg={12} md={12} s={12} xs={12}>
                <FormControl
                  style={{ width: "100%" }}
                  autoComplete="new-services"
                >
                  <Autocomplete
                    multiple
                    id="Services"
                    options={vendorServices}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => setServices(value)}
                    key={submits}
                    value={services}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Service(s) - Select all that apply"
                        error={errors["services"]}
                        helperText="Required."
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item spacing={0} justify="center">
              <Grid item lg={12} md={12} s={12} xs={12}>
                <FormControl
                  style={{ width: "100%" }}
                  autoComplete="new-coverage"
                >
                  <Autocomplete
                    multiple
                    id="Coverage"
                    options={allStates}
                    getOptionLabel={(option) => option.abbrev}
                    onChange={(event, value) => setCoverage(value)}
                    key={submits}
                    value={coverage}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="States Serviced - Select all that apply"
                        error={errors["coverage"]}
                        helperText="Required."
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item spacing={0} justify="center">
              <Grid item lg={12} md={12} s={12} xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: "100%" }}
                  onClick={submit}
                >
                  { props.edit ? "Edit Vendor" : "Add Vendor" }
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Close
        </Button>
      </DialogActions>
      <LoadingBackdrop open={backdropOpen} />
    </Dialog>
  );
}

AddVendorDialog.displayName = "AddVendorDialog";
AddVendorDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  row: PropTypes.object,
};

export default AddVendorDialog;
