import React, { useState } from "react";
import PropTypes from "prop-types";

import _ from "underscore";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

import Autocomplete from "@material-ui/lab/Autocomplete";
import LoadingBackdrop from "components/CustomLoadingBackdrop/LoadingBackdrop.js";

import { authService } from "../../services";
import { history } from "../../helpers";
import homePageStyle from "assets/jss/material-kit-pro-react/views/homePageStyle.js";
const useStyles = makeStyles(homePageStyle);

function AssignVendorDialog(props) {
  const classes = useStyles();
  const [submits, setSubmits] = useState(0);
  const [vendorServices, setVendorServices] = useState([]);
  const [allVendors, setAllVendors] = useState([]);
  const [filteredVendors, setFilteredVendors] = useState([]);

  const defaultErrors = {
    services: false,
    vendor: false,
    leadtime: false,
    frequency: false,
  };
  const [errors, setErrors] = useState(defaultErrors);
  const [backdropOpen, setBackdropOpen] = useState(false);

  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState({});
  const [leadtime, setLeadtime] = useState(0);
  const [frequency, setFrequency] = useState(0);

  React.useEffect(() => {
    const jwt = authService.getJWT();
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };

    let ses = authService.getSes();
    const user = authService.getUser();
    fetch(
      `${process.env.REACT_APP_API_URI}/vendor/vendor_facilities?company_id=${ses.selected_company}`,
      { headers }
    )
      .then((resp) => {
        if (resp.status === 403) {
          history.push(user.homepage);
          resp.json = [];
        }
        return resp.json();
      })
      .then((json) => {
        setAllVendors(json);
      });

    fetch(
      `${process.env.REACT_APP_API_URI}/vendor/all_service_types?company_id=${ses.selected_company}`,
      { headers }
    )
      .then((resp) => {
        if (resp.status === 403) {
          history.push(user.homepage);
          resp.json = [];
        }
        return resp.json();
      })
      .then((json) => {
        setVendorServices(json);
      });
  }, []);

  const handleServiceSelect = (value) => {
    setSelectedServices(value);
    setFilteredVendors([]);
    let newVendors = [];
    value.forEach((serviceType) => {
      let a = allVendors.filter((vendor) =>
        vendor.service_ids.includes(serviceType.id)
      );
      newVendors = newVendors.length === 0 ? a : newVendors;
      newVendors = _.intersection(newVendors, a);
    });
    setFilteredVendors(newVendors);
  };

  const submit = () => {
    setBackdropOpen(true);
    setErrors(defaultErrors);

    let newErrors = defaultErrors;
    newErrors = {
      services: selectedServices.length === 0,
      vendor: Object.keys(selectedVendor).length === 0,
      leadtime: leadtime === 0,
      frequency: frequency === 0,
    };
    setErrors(newErrors);

    for (const prop in newErrors) {
      if (newErrors[prop]) {
        setBackdropOpen(false);
        return;
      }
    }

    const data = {
      services: selectedServices,
      vendor: selectedVendor,
      facility: {
        company_id: authService.getSes().selected_company,
        cat_4: props.row.facility,
      },
      frequency,
      leadtime,
    };
    console.log(data);

    const user = authService.getUser();
    const jwt = authService.getJWT();
    const headers = {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    };
    fetch(`${process.env.REACT_APP_API_URI}/vendor/assign_vendor`, {
      headers,
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((resp) => {
        if (resp.status === 403) {
          history.push(user.homepage);
        } else if (resp.status !== 200) {
          alert("Failed to assign vendor");
          setBackdropOpen(false);
        } else {
          return resp.json();
        }
      })
      .then((json) => {
        setBackdropOpen(false);
        if (json && json.error) {
          alert(`Failed to assign vendor - ${json.error}`);
        } else if (json) {
          alert("Successfully assigned vendor");
          setSubmits(submits + 1);
          setSelectedServices([]);
          setSelectedVendor({});
          setLeadtime(0);
          setFrequency(0);
          props.onSubmit();
        }
      });
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">Assign Vendor</DialogTitle>
      <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <form className={classes.root} noValidate autoComplete="off">
          <Grid container spacing={3} direction="column">
            <Grid container item spacing={0} justify="center">
              <Grid item lg={12} md={12} s={12} xs={12}>
                <FormControl
                  style={{ width: "100%" }}
                  autoComplete="new-services"
                >
                  <Autocomplete
                    multiple
                    id="Services"
                    options={vendorServices.filter(
                      (i) => i.module === props.module
                    )}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => handleServiceSelect(value)}
                    key={submits}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Service(s) - Select all that apply"
                        error={errors["services"]}
                        helperText="Required."
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item spacing={0} justify="center">
              <Grid item lg={12} md={12} s={12} xs={12}>
                <FormControl
                  style={{ width: "100%" }}
                  error={errors["vendor"]}
                  autoComplete="new-vendor"
                >
                  <InputLabel id="vendor-label">Vendor</InputLabel>
                  <Select
                    labelId="vendor-label"
                    id="vendor-select"
                    onChange={(event) => setSelectedVendor(event.target.value)}
                    value={selectedVendor}
                  >
                    {filteredVendors.map((vendor) => (
                      <MenuItem
                        key={`${vendor.company_id}-${vendor.cat_4}`}
                        value={`${vendor.company_id}-${vendor.cat_4}`}
                      >
                        {`${vendor.company} - ${vendor.alt_cat_4}: ${vendor.city}, ${vendor.state}`}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Required.</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item spacing={0} justify="center">
              <Grid item lg={12} md={12} s={12} xs={12}>
                <FormControl style={{ width: "100%" }}>
                  <TextField
                    key={submits}
                    margin="dense"
                    id="leadtime"
                    name="leadtime"
                    label="Service Leadtime (Days)"
                    type="number"
                    error={errors["leadtime"]}
                    helperText="Required."
                    fullWidth
                    value={leadtime || ""}
                    onChange={(input) => setLeadtime(input.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item spacing={0} justify="center">
              <Grid item lg={12} md={12} s={12} xs={12}>
                <FormControl style={{ width: "100%" }}>
                  <TextField
                    key={submits}
                    margin="dense"
                    id="frequency"
                    name="frequency"
                    label="Service Frequency (Days)"
                    type="number"
                    error={errors["frequency"]}
                    helperText="Required."
                    fullWidth
                    value={frequency || ""}
                    onChange={(input) => setFrequency(input.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item spacing={0} justify="center">
              <Grid item lg={12} md={12} s={12} xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: "100%" }}
                  onClick={submit}
                >
                  Assign Vendor
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Close
        </Button>
      </DialogActions>
      <LoadingBackdrop open={backdropOpen} />
    </Dialog>
  );
}

AssignVendorDialog.displayName = "AssignVendorDialog";
AssignVendorDialog.propTypes = {
  module: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
};

export default AssignVendorDialog;
