import React, { useState } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import StyledDataGrid from "components/StyledDataGrid/StyledDataGrid.js";
import PermitsHistoryDialog from "components/PermitsHistoryDialog/PermitsHistoryDialog.js";

import homePageStyle from "assets/jss/material-kit-pro-react/views/homePageStyle.js";

function PermitsStatusReport(props) {
  const useStyles = makeStyles(homePageStyle);
  const classes = useStyles();

  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [historyRow, setHistoryRow] = useState({});

  const columns = [
    {
      field: "facility",
      headerName: "Facility",
      flex: 75,
      minWidth: 110,
    },
    {
      field: "city",
      headerName: "City",
      flex: 100,
      minWidth: 150,
    },
    {
      field: "state",
      headerName: "State",
      flex: 75,
      minWidth: 100,
    },
    {
      field: "permit_name",
      headerName: "Permit Name",
      flex: 125,
      minWidth: 200,
    },
    {
      field: "history",
      headerName: "History",
      flex: 75,
      minWidth: 120,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const historyButton = (
          <Button
            variant="contained"
            style={{ backgroundColor: "#bfb4a0" }}
            size="small"
            onClick={() => {
              setHistoryRow(params.row);
              setHistoryDialogOpen(true);
            }}
          >
            History
          </Button>
        );
        return historyButton;
      },
    },
    {
      field: "expiration",
      headerName: "Expiration",
      flex: 125,
      minWidth: 200,
    },
    {
      field: "event_",
      headerName: "Add",
      flex: 50,
      minWidth: 75,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      headerClassName: classes.hideRightSeparator,
      renderCell: (params) => {
        const eventButton = (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              const row = params.row;
              props.handleEventDialogOpen(row);
            }}
          >
            Event
          </Button>
        );
        return eventButton;
      },
    },
    {
      field: "contact",
      headerName: " ",
      flex: 50,
      minWidth: 75,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      headerClassName: classes.hideRightSeparator,
      renderCell: (params) => {
        const contactButton = (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => props.handleAddContactDialogOpen(params.row)}
          >
            Contact
          </Button>
        );
        return contactButton;
      },
    },
  ];

  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ height: 600, width: "100%" }}>
        <StyledDataGrid
          rows={props.rows}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
          getCellClassName={(params) => {
            if (params.field === "expiration") {
              const expired = params.row.expired;
              if (expired) {
                return classes.alertOrange;
              } else {
                return classes.alertGreen;
              }
            }
            return "";
          }}
        />
      </div>
      <PermitsHistoryDialog
        open={historyDialogOpen}
        row={historyRow}
        onClose={() => setHistoryDialogOpen(false)}
      />
    </div>
  );
}

PermitsStatusReport.displayName = "PermitsStatusReport";
PermitsStatusReport.propTypes = {
  rows: PropTypes.array.isRequired,
  handleEventDialogOpen: PropTypes.func.isRequired,
  handleAddContactDialogOpen: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default PermitsStatusReport;
