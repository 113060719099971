import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

const LoadingBackdrop = React.forwardRef((props, ref) => {
  return (
    <Backdrop open={props.open} ref={ref} style={{ zIndex: "999" }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
});
LoadingBackdrop.displayName = "LoadingBackdrop";
LoadingBackdrop.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default LoadingBackdrop;
