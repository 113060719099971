import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import StyledDataGrid from "components/StyledDataGrid/StyledDataGrid.js";
import homePageStyle from "assets/jss/material-kit-pro-react/views/homePageStyle.js";
import { history } from "../../helpers";
import { authService } from "../../services";

function PermitLateReport() {
  const useStyles = makeStyles(homePageStyle);
  const classes = useStyles();

  const [parameters, setParameters] = useState([]);
  const [expired, setExpired] = useState([]);
  const [allExpired, setAllExpired] = useState([]);

  const columns = [
    {
      field: "facility",
      headerName: "Facility",
      flex: 75,
      minWidth: 80,
    },
    {
      field: "city",
      headerName: "City",
      flex: 75,
      minWidth: 110,
    },
    {
      field: "state",
      headerName: "State",
      flex: 75,
      minWidth: 75,
    },
    {
      field: "label",
      headerName: "Parameter",
      flex: 100,
      minWidth: 150,
    },
    {
      field: "expiration",
      headerName: "Expiration",
      flex: 125,
      minWidth: 120,
    },
    {
      field: "remaining_days",
      headerName: "Days Remaining",
      flex: 100,
      minWidth: 100,
      valueFormatter: (params) => {
        if (params.value < 0) {
          return `${Math.abs(params.value)} days overdue`;
        } else {
          return `${params.value} days remaining`;
        }
      },
    },
    {
      field: "freq_days",
      headerName: "Frequency/days",
      flex: 125,
      minWidth: 110,
    },
    {
      field: "freq_alert_days",
      headerName: "Alert/days",
      flex: 125,
      minWidth: 110,
    },
  ];

  React.useEffect(() => {
    const user = authService.getUser();
    const ses = authService.getSes();
    const jwt = authService.getJWT();
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    fetch(
      `${process.env.REACT_APP_API_URI}/permit/parameters?company=${ses.selected_company}`,
      { headers }
    )
      .then((resp) => {
        if (resp.status === 403) {
          alert("Access Denied");
          history.push(user.homepage);
        }
        return resp.json();
      })
      .then((json) => {
        const paras = ["All"];
        json.forEach((para) => {
          if (!paras.includes(para.label) && para.expiration !== null) {
            paras.push(para.label);
          }
        });
        setParameters(paras);
        setExpired(json.filter((row) => row.expired));
        setAllExpired(json.filter((row) => row.expired));
      });
  }, []);

  const parametersChanged = (value) => {
    if (value.includes("All")) {
      setExpired(allExpired);
    } else {
      setExpired(allExpired.filter((row) => value.includes(row.label)));
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <Grid container justify="space-around">
        <FormControl
          className={classes.formControl}
          style={{ minWidth: 200, margin: 10 }}
        >
          <Autocomplete
            multiple
            id="Parameters"
            options={parameters}
            getOptionLabel={(option) => option}
            onChange={(event, value) => parametersChanged(value)}
            defaultValue={["All"]}
            renderInput={(params) => (
              <TextField {...params} variant="standard" label="Parameters(s)" />
            )}
          />
        </FormControl>
      </Grid>
      <div style={{ height: 600, width: "100%" }}>
        <StyledDataGrid
          rows={expired}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
          getCellClassName={(params) => {
            if (params.field === "remaining_days") {
              return params.row.remaining_days < 0 ? classes.alertRed : "";
            }
            return "";
          }}
        />
      </div>
    </div>
  );
}

PermitLateReport.displayName = "PermitLateReport";
export default PermitLateReport;
