/*eslint-disable*/
import React, { useState, Component } from "react";
import { matchPath, useHistory } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Face from "@material-ui/icons/Face";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";

import image from "assets/img/bg13.jpg";
import { history } from "../../helpers";
import { authService } from "../../services";

import parrTraxLogo from "assets/img/parrtrax_logo_white_sm.png";
import { cardTitle } from "assets/jss/material-kit-pro-react.js";

const useStyles = makeStyles(loginPageStyle);

export default function ResetPasswordPage() {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordResetError, setPasswordResetError] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  let history = useHistory();

  const validateForm = () => {
    return password.length >= 8 && password == passwordConfirm;
  }

  const handleCancel = (event) => {
    window.location.reload(true);
  }

  const handleSubmit = (event) => {
    setPasswordResetError(false);
    setErrorMessage("");
    setSuccessMessage("");
    event.preventDefault();

    const { id } = matchPath(history.location.search, { path: "?id=:id" }).params;
    authService.resetPassword(id, password).then(json => {
      if('error' in json) {
        setPasswordResetError(true);
        setErrorMessage(json.error);
      } else {
        setSuccessMessage(json.msg)
        window.setTimeout(() => {
          history.push('/login')
        }, 5000);
      }
    });
  }

  const classes = useStyles();
  return (
    <div>
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <div className={classes.parrTraxLogo} justify="center">
                <img src={parrTraxLogo} justify="center" />
              </div>
              <Card>
                <form className={classes.form} onSubmit={handleSubmit}>
                  <CardBody signup>
                    <h2 className={classes.login_title}>Reset Password</h2>
                    <p>Password should be at least 8 characters long.</p>
                    <span style={{color: "#007cff", fontSize: "1.2em", fontWeight: "bold"}}>{successMessage}</span>
                    <CustomInput
                      id="password"
                      error={passwordError}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        placeholder: "New Password",
                        type: "password",
                        value: password,
                        onChange: (e) => setPassword(e.target.value),
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon className={classes.inputIconsColor}>
                              lock_utline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off"
                      }}
                    />
                    <CustomInput
                      id="passwordConfirm"
                      error={passwordError}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        placeholder: "Confirm New Password",
                        type: "password",
                        value: passwordConfirm,
                        onChange: (e) => setPasswordConfirm(e.target.value),
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon className={classes.inputIconsColor}>
                              lock_utline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off"
                      }}
                    />
                    <span style={{color: "red"}}>{errorMessage}</span>
                  </CardBody>
                  <div className={[classes.textCenter, classes.login_buttons]} style={{textAlign: "center"}}>
                  <Button color="primary" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button color="primary" type="submit" disabled={!validateForm()}>
                    Reset Password
                  </Button>
                  </div>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
